import { Radio, Button, Divider } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { entityClass } from "../../../../constants";
import { setSelection } from "../../../../store/actions";

const ChooseEntity = ({ activeSelection, entity_ids }) => {
  const [value, setValue] = useState();

  const { entitiesByIds } = useSelector(({ analysis }) => analysis);

  const dispatch = useDispatch();

  const handleProceed = () => {
    const {
      selectedTextEntity,
      selectedSentenceEntity,
      startedNewRelationship,
      ...selection
    } = activeSelection;

    const isSentenceEntity =
      entitiesByIds[value].class === entityClass.SENTENCE;

    if (startedNewRelationship) {
      dispatch(setSelection()); // Remove current selection popup
      const domId = isSentenceEntity
        ? selectedSentenceEntity.commonId
        : `entity-${value}`;
      const entityNode = document.getElementById(domId);
      if (entityNode) {
        entityNode.dataset.single = true; // Treat this node only in the click handler
        entityNode.click();
      }
      return;
    }

    if (isSentenceEntity) {
      selection["selectedSentenceEntity"] = {
        ...selectedSentenceEntity,
        id: value,
        ids: [value],
      };
    } else {
      selection["selectedTextEntity"] = selectedTextEntity;
    }

    dispatch(setSelection(selection));
  };

  return (
    <div className="flex-column" style={{ width: "250px" }}>
      <h3 className="text-center">Multiple entities detected</h3>
      <Divider className="my-5" />
      <p>Choose one to proceed</p>
      <Radio.Group onChange={(e) => setValue(e.target.value)} value={value}>
        {entity_ids.map((id) => {
          const entity = entitiesByIds[id];
          return (
            <div key={id} className="align-center mb-5">
              <Radio value={id} />
              <div className={`entity-tag ${entity.type}`}>
                <span className="entity-text">
                  <strong>{entity.text}</strong>
                </span>
                <span className="entity-type">{entity.type}</span>
              </div>
            </div>
          );
        })}
      </Radio.Group>
      <Divider className="my-10" />
      <Button onClick={handleProceed} disabled={!value}>
        Proceed
      </Button>
    </div>
  );
};

export default ChooseEntity;
