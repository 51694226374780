import { Button } from "antd";
import {
  SyncOutlined,
  FileTextOutlined,
  CloseOutlined,
  FileExclamationOutlined,
} from "@ant-design/icons";
import { documentStatus } from "../../../constants";
import ActionTip from "../../common/widgets/ActionTip";
import useAxios from "../../../hooks/useAxios";
import WarningDialog from "../../common/widgets/WarningDialog";
import { handleDeleteDocument } from "../../../utils/helpers";
import { useRemoveDocument } from "../../../hooks/useDocumentsUpdate";
import DisplayTip from "../../common/widgets/DisplayTip";
import { useMessageBoxError } from "../../../hooks/useMessageBox";

const QueuedDocument = ({ document, index }) => {
  const { document_id, status, status_description, title } = document;

  const [{ result, error }, updateRequest] = useAxios(null, "PUT");

  useMessageBoxError(error);
  useRemoveDocument(result, document_id, documentStatus.QUEUE);

  const failed = status === documentStatus.NONE;

  return (
    <div className={`queued-document ${failed ? "failed-item" : ""}`}>
      <div className="queued-document-title">
        {failed ? (
          <>
            <FileExclamationOutlined className="f-black" />
            <span className="queued-document-title-text">
              <b>[Failed]</b>{" "}
              <DisplayTip title={status_description}>{title}</DisplayTip>
            </span>
          </>
        ) : (
          <>
            <FileTextOutlined />
            <span className="queued-document-title-text">
              <b>[{status}]</b>{" "}
              <DisplayTip title={status_description}>{title}</DisplayTip>
            </span>
          </>
        )}
      </div>
      <div className="align-center">
        {!failed && <SyncOutlined spin />}
        {failed && (
          <span className="align-center">
            <WarningDialog
              title="Are you sure you want to remove this document?"
              onConfirm={() => handleDeleteDocument(document_id, updateRequest)}
              okText="Yes"
              cancelText="No"
            >
              <ActionTip title="Remove from queue">
                <Button type="text" size="small" className="pr-0">
                  <CloseOutlined />
                </Button>
              </ActionTip>
            </WarningDialog>
          </span>
        )}
      </div>
    </div>
  );
};

export default QueuedDocument;
