import { useEffect, useState } from "react";
import { Button, Menu } from "antd";
import {
  VerticalRightOutlined,
  ProfileOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "../../../assets/styles/metadata.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrganisationMetadataKeys,
  getOrganisationMetadataProfiles,
} from "../../../store/actions/metadata";
import { keyColumns, profileColumns } from "./columns";
import MetadataTable from "./MetadataTable";
import { useMetadataState } from "../../../hooks/useApiState";
import NewMetadataForm from "./NewMetadataForm";
import { showSlideInView } from "../../../store/actions";
import { METADATA_KEYS, METADATA_PROFILES } from "../../../constants";

const Metadata = () => {
  const [activeTab, setActiveTab] = useState(METADATA_KEYS);

  const { metadata } = useSelector((state) => state);

  useMetadataState();

  const dispatch = useDispatch();
  useEffect(() => dispatch(getOrganisationMetadataKeys()), []);
  useEffect(() => dispatch(getOrganisationMetadataProfiles()), []);

  const showMetadataForm = () => {
    dispatch(
      showSlideInView(
        NewMetadataForm,
        { formType: activeTab },
        { title: "Add New Metadata " + activeTab, width: 500 }
      )
    );
  };

  const columns = activeTab === METADATA_KEYS ? keyColumns() : profileColumns();

  return (
    <>
      <div id="metadata-page">
        <div className="content-section">
          <div className="metadata-sidebar app-card b-white">
            <div className="app-card-header"></div>
            <Menu
              onClick={(e) => setActiveTab(e.key)}
              selectedKeys={[activeTab]}
            >
              <Menu.Item key={METADATA_KEYS}>
                <span className="justify-between align-center">
                  {METADATA_KEYS} <VerticalRightOutlined />
                </span>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item key={METADATA_PROFILES}>
                <span className="justify-between align-center">
                  {METADATA_PROFILES} <ProfileOutlined />
                </span>
              </Menu.Item>
            </Menu>
          </div>
          <div className="metadata-content app-card">
            <div className="app-card-header">
              <h3>Metadata {activeTab}</h3>
            </div>
            <div className="app-card-body">
              <div className="section-card py-15">
                <h3 className="section-card-header justify-end py-10">
                  <Button
                    type="primary"
                    shape="round"
                    onClick={showMetadataForm}
                  >
                    <PlusOutlined /> Add{" "}
                    {activeTab === METADATA_KEYS ? "Keys" : "Profile"}
                  </Button>
                </h3>
                <div className="section-card-body">
                  <MetadataTable
                    data={
                      activeTab === METADATA_KEYS
                        ? metadata.keys
                        : metadata.profiles
                    }
                    columns={columns}
                    activeTab={activeTab}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Metadata;
