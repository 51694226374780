import { useCallback, useEffect } from "react";
import { notification } from "antd";
import errorMessages from "../utils/messages/errorMessages";
import messageBox from "../utils/services/MessageBox";

/**
 * A hook for displaying message box.
 * Alternative to src/utils/MessageBox.
 * Recommended for use when app context is required
 * Must add 'contextHolder' to before calling display functions
 *
 * @function
 * @param {object} options - Global default configuation
 * @returns {array}
 */
const useMessageBox = (options = {}) => {
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    notification.config({
      placement: "bottomLeft",
      message: "",
      duration: 5,
      ...options,
    });
  }, []);

  /**
   * show - Display default message box view
   *
   * @param {string | ReactNode} content The mesage for display
   * @param {string | ReactNode} title The title of the display
   * @param {object} options Other options for configuration. See https://ant.design/components/notification#api
   *
   */

  const show = useCallback((content = "", title = "", options = {}) => {
    notification.destroy();
    api.open({
      className: "message-box-show",
      message: title,
      description: content,
      ...options,
    });
  }, []);

  /**
   * showError - Displays message box error view
   *
   * @param {string | ReactNode} content The mesage for display
   * @param {string | ReactNode} title The title of the display
   * @param {object} options Other options for configuration. See https://ant.design/components/notification#api
   *
   */
  const showError = useCallback((content = "", title = "", options = {}) => {
    notification.destroy();
    api.open({
      className: "message-box-show-error",
      message: title,
      description: content,
      ...options,
    });
  }, []);

  const messageBox = { show, showError };

  return [messageBox, contextHolder];
};

export default useMessageBox;

/**
 * useMessageBoxError - show message box error view
 *
 */
export const useMessageBoxError = (
  error,
  preferedMsg = null,
  errorTitle = "Error occured"
) => {
  useEffect(() => {
    if (!error) return;
    messageBox.showError(
      preferedMsg || error.message || errorMessages.oops,
      errorTitle
    );
  }, [error]);
};

/**
 * useMessageBoxSuccess - show MessageBox success view
 *
 */
export const useMessageBoxSuccess = (
  result,
  preferedMsg = null,
  successTitle = "Success!"
) => {
  useEffect(() => {
    const { success, data } = result;
    if (!success && !data) return;
    const msg = !data.message ? data.message : result.message;

    messageBox.show(preferedMsg || msg, successTitle);
  }, [result]);
};
