import { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import _ from "lodash";
import { useAuthContext } from "../../../../contexts/AuthContext";
import useAxios from "../../../../hooks/useAxios";
import CopyButton from "../../../common/widgets/CopyButton";
import MaskButton from "../../../common/widgets/MaskButton";
import WarningDialog from "../../../common/widgets/WarningDialog";
import api from "../../../../constants/api";
import { useReload } from "../../../../hooks/useReload";
import { maskText } from "../../../../utils/helpers";
import Auth from "../../../../utils/services/Auth";
import { useSelector } from "react-redux";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useMessageBoxError } from "../../../../hooks/useMessageBox";

const UNIFIED_URL = window._env_.UNIFIED_SERVER_URL;

const EditPane = () => {
  const { auth } = useAuthContext();
  const userDetails = useSelector(({ user }) => user.details);

  const [userInfo, setUserInfo] = useState({});
  const [originalInfo, setOriginalInfo] = useState(null);
  const [apiKeyMask, setApiKeyMask] = useState(true);

  const [{ loading: updating, result: usif, statusCode }, submitUserInfo] =
    useAxios(null, "POST");

  const [{ result: apiRes, error: apiKeyErr }, generateAPIKeyRequest] =
    useAxios(null, api.methods.POST);

  const [{ result: keyRes }, getApiKeyRequest] = useAxios();

  useEffect(() => getApiKeyRequest(UNIFIED_URL + api.API_KEY), []);

  useEffect(() => {
    const { email, firstName, lastName, title } = userDetails;
    setUserInfo({ email, firstName, lastName, title });
  }, [userDetails]);

  useEffect(() => {
    if (statusCode >= 200 && statusCode <= 204) {
      (async () => {
        await Auth.authenticate();
      })();
    }
  }, [statusCode, usif]);

  useReload(apiRes, () => getApiKeyRequest(UNIFIED_URL + api.API_KEY));
  useMessageBoxError(apiKeyErr);

  const handleInputChange = (e) => {
    const value = e.target.value;
    let copied = null;
    if (!originalInfo) copied = _.cloneDeep(userInfo);
    setUserInfo((info) => ({
      ...info,
      [e.target.id]: value,
    }));
    if (copied) setOriginalInfo(copied);
  };

  const handleUndoChange = () => {
    setUserInfo(originalInfo);
    setOriginalInfo(null);
  };

  const handleSubmitInfo = () => {
    const payload = {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      attributes: {
        user_title: userInfo.title,
      },
    };
    submitUserInfo(auth.createAccountUrl(), payload);
  };

  const keyData = keyRes.data || {};
  const maskOptions = { showPrefix: 2, showSuffix: 3 };
  const maskedApiKey = maskText(keyData.api_key, maskOptions);

  return (
    <>
      <OverlayScrollbarsComponent
        options={{ scrollbars: { autoHide: "l", autoHideDelay: 200 } }}
        className="user-form-pane app-card"
      >
        <div className="app-card-header justify-between align-center">
          <h3>MY PROFILE</h3>
          <div className="align-center"></div>
        </div>
        <div className="app-card-body flex-column mt-20">
          <div className="section-card mb-30">
            <div className="section-card-body justify-center px-30 py-30">
              <div className="form-holder">
                <Form layout="vertical">
                  <Form.Item label="API Key" className="mb-0">
                    <Input
                      id="apiKey"
                      value={apiKeyMask ? maskedApiKey : keyData.api_key}
                      className="app-input"
                      placeholder="API Key"
                      disabled
                      addonBefore={
                        <MaskButton
                          mask={apiKeyMask}
                          clickHandler={setApiKeyMask}
                        />
                      }
                      addonAfter={<CopyButton text={keyData.api_key} />}
                    />
                    <div>
                      <WarningDialog
                        title={
                          <>
                            A new API key will be generated while <br />
                            the current one will become invalid. <br />
                            <br />
                            Is this what you want?
                          </>
                        }
                        onConfirm={() => {
                          generateAPIKeyRequest(UNIFIED_URL + api.API_KEY);
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">Generate a new key</Button>
                      </WarningDialog>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
          <div className="section-card">
            <div className="section-card-body justify-center px-30 py-30">
              <div className="form-holder">
                <Form layout="vertical">
                  <Form.Item label="Email">
                    <Input
                      value={userInfo.email}
                      className="app-input"
                      placeholder="Your email"
                      disabled
                    />
                  </Form.Item>
                  <Form.Item label="First name">
                    <Input
                      id="firstName"
                      value={userInfo.firstName}
                      className="app-input"
                      placeholder="Your given name"
                      onChange={handleInputChange}
                    />
                  </Form.Item>
                  <Form.Item label="Last name">
                    <Input
                      id="lastName"
                      value={userInfo.lastName}
                      className="app-input"
                      placeholder="Your family name"
                      onChange={handleInputChange}
                    />
                  </Form.Item>
                  <Form.Item label="Title">
                    <Input
                      id="title"
                      value={userInfo.title}
                      className="app-input"
                      placeholder="Your title e.g 'Analyst'"
                      onChange={handleInputChange}
                    />
                  </Form.Item>
                  <Form.Item className="mb-0">
                    <Button
                      type="primary"
                      disabled={!originalInfo}
                      onClick={handleSubmitInfo}
                      loading={updating}
                    >
                      Save
                    </Button>{" "}
                    <Button disabled={!originalInfo} onClick={handleUndoChange}>
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </>
  );
};

export default EditPane;
