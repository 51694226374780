import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Radio } from "antd";
import {
  createCollectionRequest,
  getSingleCollectionRequest,
  updateCollectionRequest,
} from "../../../store/actions/collections";
import { useReload } from "../../../hooks/useReload";
import messageBox from "../../../utils/services/MessageBox";

const CollectionForm = ({ collectionId }) => {
  const [collection, setCollection] = useState({});

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { getSingleCollection: g } = useSelector(
    ({ collections }) => collections
  );

  useReload(g.result, () => {
    form.setFieldsValue(g.result?.data);
    setCollection(g.result?.data);
  });

  useEffect(() => {
    form.resetFields();
    if (!collectionId) return;
    dispatch(getSingleCollectionRequest(collectionId));
  }, []);

  const handleSubmit = (fieldValues) => {
    const payload = {
      ...fieldValues,
      taxii_access: !!collection.taxii_access,
      automatically_add_entities: !!collection.automatically_add_entities,
    };
    if (collectionId)
      return dispatch(updateCollectionRequest(collectionId, payload));
    dispatch(createCollectionRequest(payload));
  };

  const handleKeysValidation = () => {
    messageBox.showError("Please fill all required fields");
  };

  const handleChange = (e) => {
    setCollection((c) => ({
      ...c,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <Form
      form={form}
      name="collection_form"
      onFinish={handleSubmit}
      onFinishFailed={handleKeysValidation}
      scrollToFirstError={true}
      layout="vertical"
    >
      <Form.Item label="ID">
        <Input
          value={collectionId}
          placeholder="Collection ID"
          className="mb-10"
          required
          readOnly
        />
      </Form.Item>
      <Form.Item name="title" label="Title">
        <Input
          id="title"
          placeholder="Collection name"
          className="mb-10"
          required
        />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input.TextArea
          id="description"
          placeholder="Collection description"
          className="mb-10"
          required
        />
      </Form.Item>
      <Form.Item name="taxii_access">
        <div className="form-label align-center">
          TAXII Access:
          <Radio.Group
            className="ml-5"
            name="taxii_access"
            value={collection.taxii_access}
            onChange={handleChange}
          >
            <Radio value={true}>Enable</Radio>
            <Radio value={false}>Disable</Radio>
          </Radio.Group>
        </div>
      </Form.Item>
      <Form.Item name="automatically_add_entities">
        <div className="form-label align-center">
          Automatically Add Entities:
          <Radio.Group
            className="ml-5"
            name="automatically_add_entities"
            value={collection.automatically_add_entities}
            onChange={handleChange}
          >
            <Radio value={true}>Enable</Radio>
            <Radio value={false}>Disable</Radio>
          </Radio.Group>
        </div>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {collectionId ? "Update" : "Add"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CollectionForm;
