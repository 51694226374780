import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

const UserAvatar = ({ name, style = {}, isFullName = false, ...restProps }) => {
  let text = name;
  if (!isFullName) text = getFirstLetters(name);

  return (
    <>
      <Avatar size="small" icon={<UserOutlined />} style={style} {...restProps}>
        {text}
      </Avatar>
    </>
  );
};

export default UserAvatar;

const getFirstLetters = (text) => {
  if (!text) return "";
  const words = `${text}`.toUpperCase().split(" ");
  if (words.length === 1) return words[0][0];
  return `${words[0][0]}${words[words.length - 1][0]}`;
};
