import { all, put } from "redux-saga/effects";
import { entityWatcher } from "./watchers";

/**
 * @returns {null} null
 */
function* setupSaga() {
  yield put({ type: "SETUP_SAGA", payload: "SAGA SETUP" });
}

/**
 * export all the watchers to the sagaMiddleware
 * @returns {null} null
 */
export default function* rootSaga() {
  yield all([setupSaga(), entityWatcher()]);
}
