import { useEffect } from "react";

/**
 * useScripts - Add scripts to document body
 *
 */
const useScripts = (scriptsAttributes = []) => {
  return useEffect(() => {
    const scripts = [];
    for (let attr of scriptsAttributes) {
      const { id, src, enabled } = attr;

      if (enabled) {
        const script = document.createElement("script");

        script.id = id;
        script.src = src;
        script.async = true;
        script.defer = true;

        scripts.push(script);

        document.body.appendChild(script);
      }
    }

    return () => {
      for (let script of scripts) {
        document.body.removeChild(script);
      }
    };
  }, []);
};

export default useScripts;
