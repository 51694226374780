import "../../../assets/styles/loader.scss";

const GridLoader = () => {
  return (
    <div className="grid-loader">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default GridLoader;
