import { Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { documentStatus } from "../../../constants";
import api from "../../../constants/api";

export const updateStatusHelper =
  (updateStatus, reloadReports) => (action, title, nextStatus) => {
    const payload = { action, set_status: nextStatus, file_name: title };

    if (nextStatus === documentStatus.COMPLETED) {
      return Modal.confirm({
        icon: <InfoCircleOutlined />,
        title: `Once marked as completed you cannot change this report status`,
        content: <em>Do you really want to mark this report as completed?</em>,
        okText: "Yes, mark as completed",
        cancelText: "No",
        onOk: () =>
          updateStatus(
            api.REPORT_ANALYSIS.replace(":reportId", title),
            payload
          ),
        onCancel: () => reloadReports(api.REPORTS),
      });
    }

    updateStatus(api.REPORT_ANALYSIS.replace(":reportId", title), payload);
  };

export const updateDocument = (payload, sendUpdate) => {
  const { status, title } = payload;

  if (status && status === documentStatus.COMPLETED) {
    return Modal.confirm({
      icon: <InfoCircleOutlined />,
      title: `Once marked as completed you cannot change this report status`,
      content: <em>Do you really want to mark this report as completed?</em>,
      okText: "Yes, mark as completed",
      cancelText: "No",
      onOk: () => sendUpdate(api.Doc, payload),
      onCancel: () => {},
    });
  }

  sendUpdate(api.REPORT_ANALYSIS.replace(":reportId", title), payload);
};

export const uniqArr = (mergedArr) => {
  const map = new Map();
  for (let index = 0; index < mergedArr.length; index++) {
    map.set(mergedArr[index].document_id, mergedArr[index]);
  }
  return Array.from(map.values());
};
