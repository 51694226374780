import { Dropdown, Menu } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import ActionTip from "../../common/widgets/ActionTip";
import {
  deleteRuleRequest,
  disableRuleRequest,
  enableRuleRequest,
} from "../../../store/actions/manualRules";
import { showRuleForm } from "./helpers";
import { useDispatch } from "react-redux";
import { showRuleFeedbackForm } from "./helpers";

const DELETE_RULE = "DELETE_RULE";
const EDIT_RULE = "EDIT_RULE";
const SUBMIT_FEEDBACK = "SUBMIT_FEEDBACK";
const RULE_STATUS = "RULE_STATUS";

const MoreActions = ({ ruleId, isDisabled }) => {
  let statusText = "Disable";
  let statusAction = disableRuleRequest;

  if (isDisabled) {
    statusText = "Enable";
    statusAction = enableRuleRequest;
  }

  const dispatch = useDispatch();
  const menu = (
    <Menu key={ruleId}>
      <Menu.Item key={EDIT_RULE} onClick={() => showRuleForm(ruleId, dispatch)}>
        Edit
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key={RULE_STATUS}
        onClick={() => dispatch(statusAction(ruleId))}
      >
        {statusText}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key={DELETE_RULE}
        onClick={() => dispatch(deleteRuleRequest(ruleId))}
      >
        Delete
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key={SUBMIT_FEEDBACK}
        onClick={() => showRuleFeedbackForm(ruleId, dispatch)}
      >
        Submit feedback
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown
        key={ruleId}
        onClick={(e) => e.stopPropagation()}
        overlay={menu}
        trigger={["click"]}
        destroyPopupOnHide={true}
      >
        <ActionTip title="More actions">
          <EllipsisOutlined
            style={{ fontSize: "1.5rem" }}
            onClick={(e) => e.stopPropagation()}
          />
        </ActionTip>
      </Dropdown>
    </>
  );
};

export default MoreActions;
