import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, PageHeader } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import routes from "../../../constants/routes";
import DownloadStixWidget from "../analysis/AnalysisPageHeader/DownloadStixWidget";
import MetadataWidget from "../analysis/AnalysisPageHeader/MetadataWidget";
import TLPWidget from "../analysis/AnalysisPageHeader/TLPWidget";
import ActionTip from "../../common/widgets/ActionTip";
import { documentStatus } from "../../../constants";
import NavButton from "../../common/widgets/NavButton";

const { COMPLETED } = documentStatus;

/**
 * Page header view for stix graph page
 *
 * @function
 * @returns React JSX
 */
const GraphPageHeader = () => {
  const history = useHistory();
  const {
    analysis: { document },
  } = useSelector((state) => state);
  const { title, status, source, sourceType, hasStixBundle } = document;

  let sourceUrl = source;
  if (!sourceUrl || `${sourceType}`.toLowerCase() !== "url") {
    sourceUrl = routes.DOCUMENT_SOURCE.replace(":documentId", document.id);
  }

  const analysisUrl = routes.DOCUMENT_ANALYSIS.replace(
    ":documentId",
    document.id
  );

  return (
    <div id="stix-graph-header" className="stix-graph-header">
      <PageHeader
        className="site-page-header"
        onBack={() => history.push(routes.DOCUMENTS)}
        title={<span className="analysis-header-title-text">{title}</span>}
        tags={
          document.id
            ? [
                <NavButton
                  key="analysis-button"
                  url={analysisUrl}
                  text="Analysis"
                  className="mr-5"
                  buttonProps={{
                    shape: undefined,
                    className: "title-btn mb-2",
                  }}
                />,
                <MetadataWidget key="sg-metadata-widget" docId={document.id} />,
                <span key="control-widdget" className="mr-5"></span>,
                <TLPWidget key="sg-tlp-widget" docId={document.id} />,
              ]
            : []
        }
        extra={[
          <a
            key="document-source"
            href={sourceUrl}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              if (!document.id) e.preventDefault();
            }}
          >
            <ActionTip title="View source">
              <Button
                shape="round"
                icon={<FileTextOutlined />}
                size="small"
                disabled={!document.id}
              >
                Source
              </Button>
            </ActionTip>
          </a>,
          <DownloadStixWidget
            key="download-stix-widget"
            docId={document.id}
            isCompleted={status === COMPLETED}
            hasStixBundle={hasStixBundle}
          />,
        ]}
      />
    </div>
  );
};

export default GraphPageHeader;
