import { Popconfirm, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const WarningDialog = ({ children, ...props }) => {
  return (
    <Popconfirm okText="Yes" cancelText="No" {...props}>
      {children}
    </Popconfirm>
  );
};

export default WarningDialog;

export const showModalConfirm = (options = {}) => {
  Modal.confirm({
    title: "Do you really want to perform this action?",
    icon: <ExclamationCircleOutlined />,
    content: "",
    okText: "Yes",
    cancelText: "No",
    onOk() {
      console.log("OK");
    },
    onCancel() {
      console.log("Cancel");
    },
    ...options,
  });
};
