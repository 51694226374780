import { useMemo } from "react";
import { useAuthContext } from "../contexts/AuthContext";
import axiosInstance from "../utils/helpers/axiosInstance";
import useRequest from "./useRequest";

/**
 * A hook for making api request with axios
 *
 * @function
 * @param {*} defaultValue - A default value for data.
 * @param {string} method - The http method. Defaults to GET
 * @returns {array}
 */
const useAxios = (defaultValue, method = "GET", options = {}) => {
  const { contentType, handleProgress, ...requestOptions } = options;
  const { auth } = useAuthContext();

  const httpMethod = () => {
    const request = axiosInstance({
      token: auth.token,
      contentType,
      handleProgress,
    });

    return {
      GET: request.get,
      POST: request.post,
      PUT: request.put,
      PATCH: request.patch,
      DELETE: request.delete,
    }[method.toUpperCase()];
  };

  const requestFunc = useMemo(httpMethod, [method]);

  return useRequest(defaultValue, requestFunc, requestOptions);
};

export default useAxios;
