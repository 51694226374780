import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { createPromise } from "redux-promise-middleware";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import combinedReducer from "./reducers";
import rootSaga from "./sagas";
import {
  apiStateMiddleware,
  errorMiddleware,
} from "../utils/helpers/reduxStore";
import { promiseTypeSuffixes } from "../constants";

const promiseMiddleware = createPromise({ promiseTypeSuffixes });
const sagaMiddleware = createSagaMiddleware();
const middleware = [
  thunk,
  sagaMiddleware,
  promiseMiddleware,
  errorMiddleware,
  apiStateMiddleware,
];

if (process.env.NODE_ENV === "development") {
  middleware.push(createLogger({ collapsed: true, duration: true }));
}

const composeEnhancers = composeWithDevTools({
  name: "Elemendar - Analyst In The Loop",
});

const initialState = {};

const store = createStore(
  combinedReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

sagaMiddleware.run(rootSaga);

export default store;
