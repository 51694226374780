import {
  entityRole,
  HIDE_ALL_RELATIONSHIPS,
  SHOW_ALL_RELATIONSHIPS,
} from "../../constants";
import {
  SET_RELATIONSHIPS,
  RELATIONSHIP_NODES,
  RELATIONSHIP_VISIBILITY,
  NEW_RELATIONSHIP_STARTED,
  NEW_RELATIONSHIP_RESET,
  NEW_RELATIONSHIP_ROLE,
  RELATIONSHIP_PROPS,
  RESET_RELATIONSHIP_STATE,
  TRACK_HIDDEN_RELATIONSHIPS,
  SHOULD_DRAW_LINE,
  SHOULD_NOT_DRAW_LINE,
} from "../actions";

const initialState = {
  relations: [],
  props: {},
  entityRelations: {},
  visibility: HIDE_ALL_RELATIONSHIPS,
  nodes: [],
  newRelation: {
    role: entityRole.SOURCE,
    started: false,
    detectMultiple: false,
  },
  noDraw: false,
  hidden: {},
};

const relationshipReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_RELATIONSHIPS:
      return {
        ...state,
        ...action.payload,
      };

    case RELATIONSHIP_NODES:
      return {
        ...state,
        nodes: action.payload,
      };

    case RELATIONSHIP_VISIBILITY:
      return {
        ...state,
        ...action.payload,
      };

    case NEW_RELATIONSHIP_STARTED:
      return {
        ...state,
        newRelation: {
          ...state.newRelation,
          started: true,
          ...action.payload,
        },
      };

    case NEW_RELATIONSHIP_RESET:
      return {
        ...state,
        newRelation: initialState.newRelation,
      };

    case NEW_RELATIONSHIP_ROLE:
      return {
        ...state,
        newRelation: { ...state.newRelation, role: action.payload },
      };

    case RELATIONSHIP_PROPS:
      return {
        ...state,
        props: action.payload,
      };
    case SHOW_ALL_RELATIONSHIPS:
      return {
        ...state,
        ...action.payload,
      };

    case HIDE_ALL_RELATIONSHIPS:
      return {
        ...state,
        ...action.payload,
      };

    case TRACK_HIDDEN_RELATIONSHIPS:
      return {
        ...state,
        hidden: action.payload,
      };

    case SHOULD_DRAW_LINE:
      return {
        ...state,
        noDraw: false,
      };

    case SHOULD_NOT_DRAW_LINE:
      return {
        ...state,
        noDraw: true,
      };

    case RESET_RELATIONSHIP_STATE:
      return initialState;

    default:
      return state;
  }
};

export default relationshipReducer;
