import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Timeline } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import WarningDialog from "../../../common/widgets/WarningDialog";
import { removeRelationshipPopupView } from "../../../../utils/messages/popup";
import AddRelationshipMenu from "./AddRelationshipMenu";
import infoMessages from "../../../../utils/messages/infoMessages";
import ActionTip from "../../../common/widgets/ActionTip";
import { entityClass } from "../../../../constants";

const {
  existing_relationship,
  existing_relationships,
  add_relationship,
  count_selected,
} = infoMessages;

const relatedEntities = { ids: [] };

/**
 * Displays options for updating an entity relationships
 *
 * @param {object} selectedEntity - The selected entity data
 * @param {array} relationIndices - A list of index use for pointing the entity relationships.
 * @returns React JSX
 */
const RelationshipMenu = ({ selectedEntity }) => {
  const [selected, setSelected] = useState(new Set());

  // Select data from app store
  const { analysis, relationship } = useSelector((state) => state);
  const { document, entitiesByIds } = analysis;
  const relationshipIds = relationship.entityRelations[selectedEntity.id] || [];

  const dispatch = useDispatch();

  // Help to retrieve another entity in relationship with selected entity
  const getRelatedEntity = ({ source_id, target_id }) => {
    const id = selectedEntity.id === source_id ? target_id : source_id;
    return entitiesByIds[id];
  };

  // Calculate the total by filtering relationships based on your criteria
  const total = relationshipIds.reduce((count, id) => {
    const prop = relationship.props[id] || {};
    const relatedEntity = getRelatedEntity(prop);
    const entityText = relatedEntity ? relatedEntity.text : undefined;

    if (entityText !== undefined) {
      return count + 1;
    }
    return count;
  }, 0);
  const allSelected = selected.size === total;
  const handleSelect = (id) => {
    const s = new Set(selected);
    s.has(id) ? s.delete(id) : s.add(id);
    setSelected(s);
  };

  let selectedText = selectedEntity.label;
  if (selectedEntity.class === entityClass.SENTENCE) {
    selectedText = analysis.blocks[selectedEntity.block_idx].text;
  }

  relatedEntities.ids = [selectedEntity.id];

  return (
    <div className="entity-relationships">
      <div className={`related-entity ${selectedEntity.type} mb-10`}>
        <span className="entity-text">
          <strong>{selectedText}</strong>
        </span>
        <span className="entity-type">{selectedEntity.type}</span>
      </div>
      <div className="section-card mb-30">
        <div className="section-card-header">{add_relationship}</div>
        <div className="section-card-body">
          <AddRelationshipMenu firstEntity={selectedEntity} />
        </div>
      </div>
      <div className="section-card">
        <div className="section-card-header">
          {total > 1 ? existing_relationships : existing_relationship} ({total})
        </div>
        <div className="section-card-body" style={{ minHeight: "100px" }}>
          {total >= 1 && (
            <div className="justify-between mb-20">
              <Checkbox
                indeterminate={allSelected}
                checked={allSelected}
                onChange={() => {
                  allSelected
                    ? setSelected(new Set())
                    : setSelected(new Set(relationshipIds));
                }}
              >
                {count_selected.replace(":count:", selected.size)}
              </Checkbox>
              <span>
                <WarningDialog
                  {...removeRelationshipPopupView(
                    document.id,
                    selected,
                    relatedEntities,
                    dispatch
                  )}
                >
                  <Button size="small" disabled={!selected.size}>
                    <ActionTip title={infoMessages.remove}>
                      <DeleteOutlined />
                    </ActionTip>
                  </Button>
                </WarningDialog>
              </span>
            </div>
          )}
          {total >= 1 && (
            <div className="related-entity-section">
              <Timeline>
                {relationshipIds.map((id, key) => {
                  const prop = relationship.props[id] || {};
                  const relatedEntity = getRelatedEntity(prop);
                  if (!relatedEntity) {
                    return null;
                  }
                  const hidden = relationship.hidden[id];
                  let entityText = relatedEntity.text;
                  if (selected.has(id))
                    relatedEntities.ids.push(relatedEntity.id);
                  return (
                    <Timeline.Item
                      className="w-100p"
                      key={key}
                      dot={
                        <Checkbox
                          checked={selected.has(id)}
                          onChange={() => handleSelect(id)}
                        />
                      }
                    >
                      <div className="justify-between align-center w-100p">
                        <div className={`related-entity ${relatedEntity.type}`}>
                          <span className="entity-text related">
                            {entityText}
                          </span>
                          <span className="entity-type">
                            {relatedEntity.type}
                          </span>
                        </div>
                      </div>
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RelationshipMenu;
