import { useEffect } from "react";
import { useAuthContext } from "../../../contexts/AuthContext";
import Overlay from "../widgets/Overlay";
import { TRY_AGAIN } from "../../../constants";
import GridLoader from "../widgets/GridLoader";

const TIMEOUT = 10000;

/**
 * AuthLoader - full page loader for authentication process.
 *
 * @function
 * @param {string} redirectPath - The redirect path for try again or after authentication succeeds. Usually the current page before authentication.
 * @returns React JSX
 */
const AuthLoader = ({ redirectPath }) => {
  const { setAuth } = useAuthContext();

  useEffect(() => {
    const timer = setTimeout(() => {
      setAuth({
        title: "Authentication timeout",
        subTitle:
          "We couldn't establish a connection with the authentication server.",
        action: TRY_AGAIN,
        meta: { redirectPath },
      });
    }, TIMEOUT);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Overlay
      centerContent={true}
      content={
        <div style={{ textAlign: "center" }}>
          <GridLoader />
          <h1 style={{ color: "#ffffff" }}>Authenticating ...</h1>
        </div>
      }
    />
  );
};

export default AuthLoader;
