import { Button, Select, Form } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import ActionTip from "../../../common/widgets/ActionTip";
import { TLP } from "../../../../constants";
import { tlpStyle } from "./handlers";

const { Option } = Select;

export const documentTLPcoloumns = (
  documentTlpLevel,
  setGroupLevel,
  removeGroup
) => [
  {
    title: "Entity Group",
    dataIndex: "group",
    render: (key) => {
      return (
        <div>
          <span>{key}</span>
        </div>
      );
    },
  },
  {
    title: "Level",
    dataIndex: "tlp",
    key: "group",
    render: (tlp, record, rowIndex) => {
      return (
        <Form.Item name={record.group} initialValue={tlp} noStyle>
          <Select
            className="full-width"
            placeholder="Select TLP level"
            onChange={setGroupLevel(rowIndex)}
          >
            {Object.values(TLP).map((tlpLevel) => {
              if (tlpLevel === documentTlpLevel) return null;
              return (
                <Option key={tlpLevel} value={tlpLevel}>
                  TLP:<span style={tlpStyle(tlpLevel)}>{tlpLevel}</span>
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      );
    },
  },
  {
    dataIndex: "",
    padding: 0,
    className: "p-0",
    render: (_, record) => (
      <Button
        type="text"
        className="p-0"
        size="small"
        danger
        onClick={() => removeGroup(record)}
      >
        <ActionTip title="Remove group">
          <DeleteOutlined />
        </ActionTip>
      </Button>
    ),
  },
];
