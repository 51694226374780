import { Checkbox } from "antd";
import SelectInput from "../../../components/common/forms/SelectInput";
import stixConfig from "../../../configs/stix";
import { storageIds } from "../../../constants";
import { NEW_RELATIONSHIP_RESET } from "../../../store/actions";
import { storage } from "../../services/StaticStorage";
import infoMessages from "../infoMessages";

export const getNewRelationshipConfirmOptions = (dispatch) => {
  return {
    title: infoMessages.entity_relationship_in_progress,
    content: (
      <>
        <p>Select a valid entity (annotated text) to complete relationship.</p>{" "}
        <p>Would you like to complete the new relationship?</p>
      </>
    ),
    okText: infoMessages.continue,
    cancelText: infoMessages.abort,
    onOk: () => {},
    onCancel: () => {
      dispatch({ type: NEW_RELATIONSHIP_RESET });
    },
  };
};

export const getStixFirstEntityValidationConfig = (message) => {
  const { version, url } = stixConfig["stix2_1"];

  return {
    title: infoMessages.not_supported,
    content: (
      <>
        {message}
        <p>
          See summary of{" "}
          <a
            style={{ textDecoration: "underline" }}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            STIX {version} supported relationships
          </a>
        </p>
      </>
    ),
  };
};

export const getStixValidationMessageConfig = (
  message,
  swapOptions,
  dispatch
) => {
  const { version, url } = stixConfig["stix2_1"];
  let shouldSwap = false;

  return {
    title: infoMessages.not_supported,
    content: (
      <>
        {message}
        <p>
          See summary of{" "}
          <a
            style={{ textDecoration: "underline" }}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            STIX {version} supported relationships
          </a>
        </p>
        {swapOptions && (
          <p>
            <Checkbox
              onChange={(e) => {
                shouldSwap = e.target.checked;
              }}
            >
              Change to{" "}
              <strong>
                <em>{swapOptions.role}</em>
              </strong>{" "}
              entity
            </Checkbox>
          </p>
        )}
      </>
    ),
    cancelText: infoMessages.abort,
    onOk: () => (swapOptions ? swapOptions.sendRequest(shouldSwap) : false),
    onCancel: () => {
      dispatch({ type: NEW_RELATIONSHIP_RESET });
    },
  };
};

export const relationshipTypesModalContent = (
  relationshipTypes,
  dispatch,
  sendRequest
) => {
  storage.save(storageIds.RELATIONSHIP_TYPE, relationshipTypes[0]);

  return {
    title: infoMessages.select_relationship_type,
    content: (
      <>
        <div className="section-card">
          <SelectInput
            allowClear={false}
            defaultValue={relationshipTypes[0]}
            style={{ width: "100%" }}
            options={relationshipTypes.map((type) => ({
              value: type,
              label: type,
            }))}
            onChange={(type) =>
              storage.save(storageIds.RELATIONSHIP_TYPE, type)
            }
          />
        </div>
      </>
    ),
    cancelText: infoMessages.abort,
    onOk: sendRequest,
    onCancel: () => {
      dispatch({ type: NEW_RELATIONSHIP_RESET });
    },
  };
};
