import { tracking } from "../constants/envVars";
import { checkToolEnabled } from "../utils/helpers/tracker";

const scriptsConfigs = [
  {
    id: "hs-script-loader",
    src: "//js-na1.hs-scripts.com/6747765.js",
    enabled: checkToolEnabled(tracking.HUBSPOT),
  },
];

export default scriptsConfigs;
