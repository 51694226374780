import EntityTypeOptions from "../EntityTypeOptions";

/**
 * Change type of a group of entities
 *
 * @returns React JSX
 */
const ChangeGroupType = ({ entityIds = [] }) => {
  const count = entityIds.length;
  const [w1, w2] = count > 1 ? ["entities", "are"] : ["entity", "is"];

  return (
    <div>
      <div className="section-card">
        <div className="section-card-body">
          <h3 className="mb-20">
            There {w2} {count} {w1} in the selected group.
          </h3>
          <p>
            <em>
              Review the checked list below to select only those that will
              change.
            </em>
          </p>
        </div>
      </div>
      <EntityTypeOptions entityIds={entityIds} />
    </div>
  );
};

export default ChangeGroupType;
