import SelectInput from "../../../common/forms/SelectInput";
import countries from "../../../../data/countries";

/**
 * Country search dropdown
 *
 * @returns React JSX
 */
const SelectCountry = ({
  setSelectedCountryCode,
  autoFocus = true,
  defaultOpen = true,
  onClick = undefined,
}) => {
  return (
    <>
      <span style={{ display: "inline-block", width: "200px", padding: "5px" }}>
        <span style={{ with: "100%", padding: "3px 0" }}>
          <SelectInput
            style={{ width: "100%" }}
            autoFocus={autoFocus}
            defaultOpen={defaultOpen}
            placeholder="Search country"
            notFoundContent="Not Found"
            onClick={onClick}
            onChange={(countryCode) => setSelectedCountryCode(countryCode)}
            options={Object.entries(countries).map(
              ([countryCode, countryName]) => ({
                value: countryCode,
                label: `[${countryCode}] ${countryName}`,
              })
            )}
          />
        </span>
      </span>
    </>
  );
};

export default SelectCountry;
