import { Link } from "react-router-dom";
import { Layout } from "antd";
import logo from "../../../assets/images/elemendar-logo.png";
import readLogo from "../../../assets/images/read-logo-transparent.png";
import routes from "../../../constants/routes";
import HeaderOptions from "./HeaderOptions";
import ContinuousLoader from "../loaders/ContinuousLoader";

const Header = ({ loader }) => {
  return (
    <Layout.Header className="app-header">
      <div className="app-title">
        <div className="app-elemendar-logo">
          <img src={logo} alt="ELEMENDAR" width="70px" />
        </div>
        <div className="app-read-logo">
          <Link to={routes.HOME}>
            <img src={readLogo} alt="READ" width="110px" />
          </Link>
        </div>
        <div id="top-relationship-anchor"></div>
      </div>
      <HeaderOptions />
      <ContinuousLoader
        className="header-loader"
        loading={loader.loading}
        height="5px"
      />
    </Layout.Header>
  );
};

export default Header;
