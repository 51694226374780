import { Button, Tag } from "antd";
import MoreActions from "./MoreActions";
import { entitySvgs as svgs } from "../../../data/entitiesSvg";
import { entityProperties } from "../../../data/entities";
import { RULE_TYPES } from "../../../constants";

export const manualRuleColumns = () => [
  {
    title: "Value",
    dataIndex: "entity_instance",
    ellipsis: true,
    sorter: true,
  },
  {
    title: "Entity type",
    dataIndex: "entity_type",
    sorter: true,
    render: (t) => (
      <Tag style={{ borderRadius: "10px" }}>
        <img
          src={svgs[t]}
          width={12}
          height={12}
          alt="entity type"
          style={{ marginBottom: "3px" }}
        />{" "}
        {entityProperties[t].label}
      </Tag>
    ),
  },
  {
    title: "Rule",
    dataIndex: "rule",
    render: (rule) => (
      <Tag style={{ borderRadius: "10px" }}>{RULE_TYPES[rule].label}</Tag>
    ),
  },
  {
    title: "Creator",
    dataIndex: "creator_name",
    responsive: ["md"],
    sorter: true,
  },
  {
    title: "Created",
    dataIndex: "created_at",
    render: (d) => d.split(" ")[0],
    responsive: ["md"],
    sorter: true,
  },
  {
    title: "Status",
    dataIndex: "disabled",
    width: 170,
    render: (disabled, record) => {
      const status = disabled ? "Disabled" : "Enabled";
      const c = disabled ? "#D3455B" : "#1AAE9F";
      return (
        <div className="no-wrap">
          <Tag color={c}>{status}</Tag>{" "}
          <Button type="text" size="small">
            <MoreActions ruleId={record.id} isDisabled={disabled} />
          </Button>
        </div>
      );
    },
  },
];
