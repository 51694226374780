import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import routes from "../../../constants/routes";
import { useAuthContext } from "../../../contexts/AuthContext";
import Overlay from "../../common/widgets/Overlay";

const Login = () => {
  const { auth } = useAuthContext();
  const location = useLocation();
  const history = useHistory();

  const { state = {} } = location;
  const { redirectPath: p } = state;
  const urlPath = p && p !== routes.LOGIN ? p : routes.HOME;

  useEffect(() => {
    try {
      if (auth) {
        auth.authenticated ? history.push(urlPath) : auth.logout();
      } else {
        history.push(urlPath);
      }
    } catch (error) {}
  }, []);

  return (
    <Overlay
      centerContent={true}
      content={<h2 style={{ color: "#ffffff" }}>Loading...</h2>}
    />
  );
};

export default Login;
