import { Button } from "antd";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { analysisModes, documentStatus } from "../../../constants";
import { setAnalysisMode } from "../../../store/actions";
import ConfidenceScoreFilter from "./ConfidenceScoreFilter";
import { useState } from "react";
import DocumentPerformanceOverview from "./DocumentPerformanceOverview";

const { ANNOTATION, GRAPH } = analysisModes;
const { COMPLETED } = documentStatus;

/**
 * AnalysisPanel
 *
 * @returns React JSX
 */
const AnalysisPanel = ({ children }) => {
  const {
    analysis: { analysisMode, document },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [overview, setOverview] = useState(false);

  const isCompleted = document.status === COMPLETED;

  const handleModeChange = (mode) => {
    dispatch(setAnalysisMode(mode));
  };

  return (
    <div className="analysis-panel">
      {!!overview && (
        <DocumentPerformanceOverview
          documentId={document.id}
          hideOverview={() => setOverview(false)}
          isCompleted={isCompleted}
        />
      )}
      {!overview && (
        <>
          <div className="section-card" style={{ zIndex: 1 }}>
            <div className="section-card-body justify-between">
              <div>
                <Button style={{ padding: "0 8px", alignItems: "center" }}>
                  Analysis mode - {analysisMode}{" "}
                  <CaretUpOutlined
                    style={{
                      fontSize: "12px",
                      position: "relative",
                      top: "-5px",
                      left: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (analysisMode === GRAPH) {
                        handleModeChange(ANNOTATION);
                      }
                    }}
                  />
                  <CaretDownOutlined
                    style={{
                      fontSize: "12px",
                      position: "relative",
                      top: "6px",
                      right: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (analysisMode === ANNOTATION) {
                        handleModeChange(GRAPH);
                      }
                    }}
                  />
                </Button>
                {analysisMode === ANNOTATION && <ConfidenceScoreFilter />}
              </div>
              {!!document.hasExtractionTime && (
                <div>
                  <Button onClick={() => setOverview(true)}>
                    <BarChartOutlined /> Performance
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="full-height">{children}</div>
        </>
      )}
    </div>
  );
};

export default AnalysisPanel;
