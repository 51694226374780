import { Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const defaultMessage = {
  icon: <InfoCircleOutlined />,
  // title: `Once marked as completed you cannot change this report status`,
  content: <em>Do you want to continue with this action?</em>,
  okText: "Yes",
  cancelText: "No",
};

const handleDefault = () => {};

export const confirmAction = (warningMessage = {}, handleYes, handleNo) => {
  defaultMessage["onOk"] = handleYes || handleDefault;
  defaultMessage["onCancel"] = handleNo || handleDefault;

  let msg = warningMessage;

  if (typeof msg === "string") msg = { title: msg };

  const warningMsg = { ...defaultMessage, ...msg };

  Modal.confirm(warningMsg);
};
