const USER = "/user";
export const DOCUMENTS = "/documents";
export const ORGANISATION = "/organisation";
export const ERROR = "/error";
const COLLECTIONS = `${ORGANISATION}/collections`;
const DASHBOARD = "dashboard";

const routes = {
  FALL_BACK: "/",
  HOME: "/",
  LOGIN: `${USER}/login`,
  SIGNUP: `${USER}/signup`,
  PROFILE: `${USER}/profile`,
  DOCUMENTS,
  DOCUMENT_ANALYSIS: `${DOCUMENTS}/:documentId/analysis`,
  DOCUMENT_SOURCE: `${DOCUMENTS}/:documentId/source`,
  STIX_GRAPH: `${DOCUMENTS}/:documentId/graph`,
  ORGANISATION,
  SEARCH: `${DOCUMENTS}/search`,
  METADATA: `${ORGANISATION}/metadata`,
  COLLECTIONS,
  COLLECTION_GRAPH: `${COLLECTIONS}/:collectionId/graph`,
  RULES_MANAGER: `${ORGANISATION}/rules`,
  PERFORMANCE_DASHBOARD: `/${DASHBOARD}/extraction_performance`,
  ERROR,
  NOT_FOUND: `${ERROR}/not-found`,
  NOT_AUTHENTICATED: `${ERROR}/unauthenticated`,
  FORBIDDEN: `${ERROR}/forbidden`,
  INTERNAL_ERROR: `${ERROR}/internal-error`,
};

export const pageNames = {
  [routes.HOME]: "Home Page",
  [routes.LOGIN]: "Login Page",
  [routes.SIGNUP]: "Signup Page",
  [routes.PROFILE]: "Profile Page",
  [routes.DOCUMENTS]: "Documents Page",
  [routes.DOCUMENT_ANALYSIS]: "Analysis Page",
  [routes.DOCUMENT_SOURCE]: "Document Source Page",
  [routes.STIX_GRAPH]: "STIX Graph Page",
  [routes.SEARCH]: "Document Search Page",
  [routes.ORGANISATION]: "Organisation Page",
  [routes.METADATA]: "Metadata Page",
  [routes.COLLECTIONS]: "Collections Page",
  [routes.COLLECTION_GRAPH]: "Collection Graph Page",
  [routes.RULES_MANAGER]: "Rules Manager Page",
  [routes.PERFORMANCE_DASHBOARD]: "Performance Dashboard Page",
  [routes.ERROR]: "Error Page",
  [routes.NOT_FOUND]: "Not Found Page",
  [routes.NOT_AUTHENTICATED]: "Unauthenticated Page",
  [routes.FORBIDDEN]: "Forbidden Page",
  [routes.INTERNAL_ERROR]: "Internal Error Page",
  [routes.FALL_BACK]: "Fall Back Page",
};

export const errorCodeToPage = {
  401: routes.NOT_AUTHENTICATED,
  403: routes.FORBIDDEN,
  404: routes.NOT_FOUND,
  500: routes.INTERNAL_ERROR,
};

export default routes;
