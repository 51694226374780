import { Button, Checkbox, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import api from "../../../../constants/api";
import useAxios from "../../../../hooks/useAxios";
import { bulkUpdateEntitiesConfirmOptions } from "../../../../utils/messages/popup";
import WarningDialog from "../../../common/widgets/WarningDialog";
import { entityStatus } from "../../../../constants";
import { useReloadAnalysis } from "../../../../hooks/useReload";
import { RejectEntityMenuItems } from "../SharedComponents";
import { useDispatch } from "react-redux";
import { handleAcceptRejectEntityMenu } from "../helpers";
import { useMessageBoxError } from "../../../../hooks/useMessageBox";

const ConfirmSelection = ({
  groupStatus,
  selection,
  totalEntities,
  documentId,
  handleSelection,
  initialLoading,
}) => {
  const [{ result, error }, bulkUpdateRequest] = useAxios(
    null,
    api.methods.PUT
  );
  const dispatch = useDispatch();
  useReloadAnalysis(result);
  useMessageBoxError(error);

  const checkedEntities = selection.length;

  const handleReject = handleAcceptRejectEntityMenu(
    selection[0],
    documentId,
    dispatch
  );

  return (
    <>
      <div className="justify-between">
        <Checkbox
          indeterminate={checkedEntities === totalEntities}
          checked={checkedEntities === totalEntities}
          onChange={handleSelection([groupStatus])}
          disabled={initialLoading}
        >
          {checkedEntities} selected entit{checkedEntities > 1 ? "ies" : "y"}
        </Checkbox>
        <span>
          <WarningDialog
            {...bulkUpdateEntitiesConfirmOptions(
              selection,
              documentId,
              entityStatus.ACCEPTED,
              bulkUpdateRequest
            )}
          >
            <Button
              size="small"
              className="btn-accept outline"
              disabled={!checkedEntities || initialLoading}
            >
              Accept
            </Button>
          </WarningDialog>{" "}
          {checkedEntities !== 1 && (
            <WarningDialog
              {...bulkUpdateEntitiesConfirmOptions(
                selection,
                documentId,
                entityStatus.REJECTED,
                bulkUpdateRequest
              )}
            >
              <Button
                size="small"
                className="btn-reject outline"
                disabled={!checkedEntities}
              >
                Reject
              </Button>
            </WarningDialog>
          )}
          {checkedEntities == 1 && (
            <Dropdown
              trigger={["click"]}
              overlay={<RejectEntityMenuItems onClick={handleReject} />}
            >
              <Button size="small" className="btn-reject outline">
                Reject
                <DownOutlined />
              </Button>
            </Dropdown>
          )}
        </span>
      </div>
    </>
  );
};

export default ConfirmSelection;
