import { SET_USER_DETAILS, MERGE_USER_DETAILS } from "../actions";

const initialState = {
  details: {},
};

const userReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_USER_DETAILS:
      return {
        ...state,
        details: action.payload,
      };

    case MERGE_USER_DETAILS:
      return {
        ...state,
        details: { ...state.details, ...action.payload },
      };

    default:
      return state;
  }
};

export default userReducer;
