import { Row, Col, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import TotalStatWidget from "../performanceDashboard/TotalStatWidget";
import ReportCard from "../performanceDashboard/ReportCard";
import { useEffect } from "react";
import {
  getDocumentPerformanceOverviewRequest,
  resetDashboardState,
  setDocumentPerformanceOverview,
} from "../../../store/actions/dashboard";
import { useReload, useReloadAnalysis } from "../../../hooks/useReload";
import {
  HMSToString,
  handleDocumentStatusUpdate,
  secondsToHMS,
} from "../../../utils/helpers";
import useAxios from "../../../hooks/useAxios";
import { documentStatus } from "../../../constants";
import api from "../../../constants/api";
import DisplayTip from "../../common/widgets/DisplayTip";
import { useMessageBoxError } from "../../../hooks/useMessageBox";

const WORDS_PER_PAGE = 300;
const { PUT } = api.methods;
const { COMPLETED } = documentStatus;

/**
 * DocumentPerformanceOverview
 *
 * @returns React JSX
 */
const DocumentPerformanceOverview = ({
  documentId,
  hideOverview,
  isCompleted,
}) => {
  const dispatch = useDispatch();

  const { document_overview: d, getDocumentOverview: gdo } = useSelector(
    ({ dashboard }) => dashboard
  );

  const [{ result, error }, updateRequest] = useAxios(null, PUT);

  useReloadAnalysis(result);
  useMessageBoxError(error);

  useReload(gdo.result, () =>
    dispatch(setDocumentPerformanceOverview(gdo.result.data))
  );

  useEffect(() => {
    dispatch(getDocumentPerformanceOverviewRequest(documentId));
    return () => dispatch(resetDashboardState());
  }, []);

  const {
    total_relationships = 0,
    total_entities = 0,
    words_count = 0,
    manual_time_spent = 0,
    duration = 0,
  } = d.summary;

  const extractionSeconds = duration?.toFixed(1);
  const manualTime = HMSToString(secondsToHMS(manual_time_spent));

  const pagesInfo = (
    <DisplayTip
      overlayClassName={undefined}
      color={undefined}
      placement="rightTop"
      title={`Estimated ${WORDS_PER_PAGE} words per page`}
    >
      <span style={{ borderBottom: "1px dashed #afafaf" }}>
        {Math.ceil(words_count / WORDS_PER_PAGE)} pages
      </span>
    </DisplayTip>
  );

  return (
    <div
      id="report-performance-overview"
      className="section-card"
      style={{ zIndex: 1 }}
    >
      <div className="section-card-body justify-between">
        <h3>Extraction overview</h3>
        <Button onClick={hideOverview}>
          <CloseOutlined /> Close
        </Button>
      </div>
      <div className="section-card-body full-height">
        <p>
          READ identified <strong>{total_entities} entities</strong> and{" "}
          <strong>{total_relationships} relationships</strong> accross{" "}
          <strong>{pagesInfo}</strong> of your report! We estimate these
          annotations would take an analyst around{" "}
          <strong>{manualTime} </strong> to manually annotate, but READ did it
          in <strong>{extractionSeconds} seconds</strong>.
        </p>
        <section className="total-stats-section">
          <TotalStatWidget
            title="EXTRACTION TIME"
            count={extractionSeconds}
            altText={"seconds"}
            icon={null}
          />
          <TotalStatWidget
            title="ENTITIES EXTRACTED"
            count={d.summary?.total_entities}
            icon={null}
          />
          <TotalStatWidget
            title="RELATIONSHIP IDENTIFIED"
            count={d.summary?.total_relationships}
            icon={null}
          />
        </section>
        <section className="report-section">
          <Row gutter={24}>
            <Col span={12}>
              <ReportCard
                title="CONFIDENCE REPORT"
                reports={d.confidence_reports}
              />
            </Col>
            <Col span={12}>
              <ReportCard
                title="ANNOTATION REPORT"
                reports={d.annotation_reports}
              >
                {!isCompleted && (
                  <div className="empty-report-info">
                    <p>
                      <em>
                        You need to mark this report as completed before we can
                        show you these metrics
                      </em>
                    </p>
                    <Button
                      type="link"
                      onClick={() =>
                        handleDocumentStatusUpdate(
                          documentId,
                          COMPLETED,
                          updateRequest
                        )
                      }
                    >
                      Mark as complete
                    </Button>
                  </div>
                )}
              </ReportCard>
            </Col>
          </Row>
        </section>
      </div>
    </div>
  );
};

export default DocumentPerformanceOverview;
