import { useState } from "react";
import { Button, Switch } from "antd";
import AccessDetailsForm from "./AccessDetailsForm";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

const DetailsPane = () => {
  const [configure, setConfigure] = useState(false);

  return (
    <OverlayScrollbarsComponent
      options={{ scrollbars: { autoHide: "l", autoHideDelay: 200 } }}
      className="form-pane app-card"
    >
      <div className="app-card-header justify-between align-center">
        <h3>ORGANISATION ACCESS DETAILS</h3>
        <div className="align-center">
          <Button
            type="text"
            size="small"
            onClick={() => {
              setConfigure((enable) => !enable);
            }}
          >
            Configure
          </Button>
          <Switch
            size="small"
            checked={configure}
            onClick={() => {
              setConfigure((enable) => !enable);
            }}
          />
        </div>
      </div>
      <div className="app-card-body justify-center mt-30">
        <AccessDetailsForm editable={configure} />
      </div>
    </OverlayScrollbarsComponent>
  );
};

export default DetailsPane;
