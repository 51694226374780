import {
  generateApiActionTypes,
  initializeState,
} from "../../utils/helpers/reduxStore";
import actions from "../actions/search";

const actionTypes = {
  [actions.GET_SEARCH_REQUEST]: "getSearchRequest",
};

const apiActionTypes = generateApiActionTypes(actionTypes);

const initialState = {
  ...initializeState(actionTypes),
  documents: null,
  entity_search_name: "",
};

const search = (state = initialState, action = {}) => {
  const typeState = apiActionTypes[action.type];

  if (typeState)
    return {
      ...state,
      [typeState]: action.payload,
    };

  switch (action.type) {
    case actions.SET_SEARCH_STATE:
      return {
        ...state,
        ...action.payload,
      };

    case actions.RESET_SEARCH_STATE:
      return initialState;

    default:
      return state;
  }
};

export default search;
