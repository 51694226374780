import CollectionForm from "./CollectionForm";
import { showSlideInView } from "../../../store/actions";
import routes from "../../../constants/routes";
import { deleteCollectionRequest } from "../../../store/actions/collections";
import CollectionDocuments from "./CollectionDocuments";

export const handleEdit = (collectionId, dispatch) => {
  dispatch(
    showSlideInView(
      CollectionForm,
      { collectionId },
      { title: "Collection Details", width: 500 }
    )
  );
};

export const handleDelete = (collectionId, dispatch) => {
  dispatch(deleteCollectionRequest(collectionId));
};

export const handleGraph = (id, history) => {
  history.push(routes.COLLECTION_GRAPH.replace(":collectionId", id));
};

export const showCollectionDocuments = (
  collectionId,
  dispatch,
  reloadCallback
) => {
  dispatch(
    showSlideInView(
      CollectionDocuments,
      { collectionId, reloadCallback },
      { title: "Documents in Collection", width: 500 }
    )
  );
};
