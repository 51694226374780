import { tracking } from "../../constants/envVars";
import { checkToolEnabled } from "../helpers/tracker";

/* Hotjar initialization */
const SHOW_LOG = process.env.NODE_ENV === "development";
const isEnabled = checkToolEnabled(tracking.HOTJAR);
const siteId = window._env_[tracking.HOTJAR_ID];
const HOTJAR_VERSION = 6;
if (isEnabled) {
  // Hotjar installation code
  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: siteId, hjsv: HOTJAR_VERSION, hjdebug: SHOW_LOG };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script");
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
}

/**
 * Hotjar class
 *
 * @class
 *
 */
class Hotjar {
  static identify(id, userDetails) {
    try {
      if (!isEnabled) return;
      window.hj("identify", id, userDetails);
    } catch (error) {
      console.error(error);
    }
  }

  static track(name, props) {
    try {
      if (!isEnabled) return;
      window.hj("event", name);
    } catch (error) {
      console.error(error);
    }
  }
}

export default Hotjar;
