import { Skeleton } from "antd";

const ContentLoader = ({ repeat = 1, key, ...restProps }) => {
  return (
    // <div className="content-loader">
    Array.from(Array(repeat).keys()).map((x) => (
      <Skeleton key={x} active {...restProps} />
    ))
    // </div>
  );
};

export default ContentLoader;
