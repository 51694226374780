import { useHistory } from "react-router-dom";
import { Button } from "antd";

const NavButton = ({
  url = "#",
  text = "Nav",
  icon,
  bgColor,
  border = "none",
  className,
  buttonStyle = {},
  buttonProps,
  linkProps,
}) => {
  const history = useHistory();

  const handleUrl = (url) => (e) => {
    e.preventDefault();
    history.push(url);
  };

  return (
    <a href={url} onClick={handleUrl(url)} className={className} {...linkProps}>
      <Button
        shape="round"
        type="primary"
        size="small"
        style={{ backgroundColor: bgColor, border, ...buttonStyle }}
        icon={icon}
        {...buttonProps}
      >
        {text}
      </Button>
    </a>
  );
};

export default NavButton;
