import {
  generateApiActionTypes,
  initializeState,
} from "../../utils/helpers/reduxStore";
import actions from "../actions/stixGraph";

const actionTypes = {
  [actions.GET_STIX_JSON]: "getStixJson",
  [actions.GET_BASIC_DOCUMENT]: "getBasicDocument",
};

const apiActionTypes = generateApiActionTypes(actionTypes);

const initialState = {
  ...initializeState(actionTypes),
  document: {},
  selectedRelationshipIds: [],
};

const stixGraphReducer = (state = initialState, action = {}) => {
  const typeState = apiActionTypes[action.type];

  if (typeState)
    return {
      ...state,
      [typeState]: action.payload,
    };

  switch (action.type) {
    case actions.SET_DOCUMENT_DETAILS:
      return {
        ...state,
        document: action.payload,
      };
    case actions.SET_SELECTED_RELATIONSHIP_IDS:
      return {
        ...state,
        selectedRelationshipIds: action.payload,
      };

    case actions.RESET:
      return initialState;

    default:
      return state;
  }
};

export default stixGraphReducer;
