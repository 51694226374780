import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useReload } from "../../../hooks/useReload";
import { getCollectionBundleRequest } from "../../../store/actions/collections";
import CollectionGraphHeader from "./CollectionGraphHeader";
import CollectionGraphPane from "./CollectionGraphPane";

const CollectionGraph = () => {
  const [data, setData] = useState({});
  const { collectionId } = useParams();
  const dispatch = useDispatch();
  const { getCollectionBundle: gcb } = useSelector(
    ({ collections }) => collections
  );

  useReload(gcb.result, () => setData(gcb.result.data));

  useEffect(() => {
    dispatch(getCollectionBundleRequest(collectionId));
  }, []);

  return (
    <div className="full-dimension flex-column">
      <CollectionGraphHeader collection={data.collection} />
      <div className="full-dimension">
        <CollectionGraphPane
          bundle={data.bundle}
          collection={data.collection}
        />
      </div>
    </div>
  );
};

export default CollectionGraph;
