import { GroupOutlined } from "@ant-design/icons";
import { documentStatus } from "../../../constants";
import Column from "./Column";

const { COMPLETED, FOR_REVIEW, REVIEWING } = documentStatus;

/**
 * Columns pane - holds group of columns
 *
 * @function
 * @returns React JSX
 */
const ColumnsPane = () => {
  return (
    <div className="columns-pane">
      <h2 style={{ paddingLeft: "10px" }}>
        <GroupOutlined /> <b className="pane-title"> Document columns </b>
      </h2>
      <div className="documents-columns-holder">
        <Column type={FOR_REVIEW} />
        <Column type={REVIEWING} />
        <Column type={COMPLETED} />
      </div>
    </div>
  );
};

export default ColumnsPane;
