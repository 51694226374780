import Keycloak from "keycloak-js";

/**
 * Auth class
 *
 * @class
 *
 * Defines helper static methods for authentication
 */
class Auth {
  /**
   * authenticate - initialize the app's authentication flow
   *
   * @function
   *
   * @returns an instance of Keycloak
   */
  static async authenticate() {
    let keycloak = null;

    // let realm = window._env_.AUTH_REALM;

    // const realmFromSubdomain = `${window._env_.REALM_FROM_SUBDOMAIN}`;
    // if (realmFromSubdomain.toUpperCase() === "TRUE") {
    //   const domains = window.location.host.split(".");
    //   const subdomain = domains[1] ? domains[0] : window._env_.AUTH_REALM;
    //   realm = subdomain;
    // }

    const realm = Auth.getCurrentRealm();

    const connectionMeta = {
      realm,
      clientId: realm,
      // clientId: window._env_.AUTH_CLIENT,
      url: window._env_.AUTH_SERVER_URL,
    };

    try {
      keycloak = Keycloak(connectionMeta);
      await keycloak.init({ onLoad: "login-required" });
    } catch (error) {
      console.log("Authentication failed", error);
    }

    return keycloak;
  }

  /**
   * Gets a login user details from keycloak instance
   *
   * @function
   *
   * @param { instance } keycloackInstance an instance of Keycloak class
   * @returns an instance of Keycloak
   */
  static getUserDetails(keycloackInstance) {
    if (keycloackInstance && keycloackInstance.authenticated) {
      const info = keycloackInstance.tokenParsed;
      return {
        id: info.sub,
        firstName: info.given_name,
        lastName: info.family_name,
        fullName: info.name,
        email: info.email,
        emailVerified: info.email_verified,
        title: info.user_title,
        sessionId: keycloackInstance.sessionId,
        organizationId: keycloackInstance.realm,
        clientId: keycloackInstance.clientId,
      };
    }

    return {};
  }

  /**
   * Gets current realm
   *
   */
  static getCurrentRealm() {
    let realm = window._env_.AUTH_REALM;

    const realmFromSubdomain = `${window._env_.REALM_FROM_SUBDOMAIN}`;
    if (realmFromSubdomain.toUpperCase() === "TRUE") {
      const domains = window.location.host.split(".");
      const subdomain = domains[1] ? domains[0] : window._env_.AUTH_REALM;
      realm = subdomain;
    }

    return realm;
  }
}

export default Auth;
