import { Button, Divider, Tag } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import UserAvatar from "../../../common/widgets/UserAvatar";
import { documentColumns, documentStatus } from "../../../../constants";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import api from "../../../../constants/api";
import useAxios from "../../../../hooks/useAxios";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useAuthContext } from "../../../../contexts/AuthContext";
import { useMessageBoxError } from "../../../../hooks/useMessageBox";

const { COMPLETED, FOR_REVIEW, QUEUE, REVIEWING } = documentStatus;

const SummaryPane = () => {
  const userDetails = useSelector(({ user }) => user.details);

  const { auth } = useAuthContext();
  const [{ result, error }, documentsCountsRequest] = useAxios({});

  useEffect(() => documentsCountsRequest(api.DOCUMENTS_COUNTS_USER), []);
  useMessageBoxError(error);

  const { completed, in_review, for_review, queued } = result.data;

  return (
    <>
      <OverlayScrollbarsComponent
        options={{ scrollbars: { autoHide: "l", autoHideDelay: 200 } }}
        className="user-summary-pane app-card"
      >
        <div className="app-card-body flex-column align-center">
          <div className="section-card py-30">
            <div className="section-card-body flex-column justify-center align-center py-30 bd-0">
              <div className="user-photo">
                <UserAvatar
                  name={`${userDetails.firstName} ${userDetails.lastName}`}
                  size={200}
                  icon={undefined}
                  style={{ fontSize: "96px", background: "#f56a00" }}
                  data-testid="user-initials"
                />
              </div>
              <div className="user-details">
                <div className="user-details-name">{`${userDetails.firstName} ${userDetails.lastName}`}</div>
                <div data-testid="user-details-email">{userDetails.email}</div>
                <div data-testid="user-details-title">{userDetails.title}</div>
                <Button
                  href={`${auth.createAccountUrl().replace("?", "/password?")}`}
                  type="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-testid="user-details-change-password"
                >
                  Change password
                </Button>
              </div>
            </div>
          </div>
          <Divider />
          <div className="section-card py-10 pb-0">
            <div className="section-card-body flex-column justify-center align-center py-30 bd-0">
              <div className="user-report-stats">
                <h3>Summary</h3>
                <ul>
                  <li>
                    Completed{" "}
                    <Tag
                      icon={<FileTextOutlined />}
                      color={documentColumns[COMPLETED].color}
                      data-testid="completed"
                    >
                      {completed}
                    </Tag>
                  </li>
                  <li>
                    In review{" "}
                    <Tag
                      icon={<FileTextOutlined />}
                      color={documentColumns[REVIEWING].color}
                      data-testid="in_review"
                    >
                      {in_review}
                    </Tag>
                  </li>
                  <li>
                    For review{" "}
                    <Tag
                      icon={<FileTextOutlined />}
                      color={documentColumns[FOR_REVIEW].color}
                      data-testid="for_review"
                    >
                      {for_review}
                    </Tag>
                  </li>
                  <li>
                    Queued{" "}
                    <Tag
                      icon={<FileTextOutlined />}
                      color={documentColumns[QUEUE].color}
                      data-testid="queued"
                    >
                      {queued}
                    </Tag>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </>
  );
};

export default SummaryPane;
