import { Divider } from "antd";
import { handleOpenAnalysis } from "./helpers";
import { uploadedDocumentStatus } from "../../../constants";

/**
 * Results - The search results view
 *
 * @function
 *
 * @returns React JSX
 */
export const Results = ({ documents, entityName }) => {
  return (
    <div id="search-results" className="">
      {(documents || []).map((d, i) => {
        return (
          <div
            key={i}
            className="section-card-body px-0 doc-item"
            onClick={handleOpenAnalysis(d)}
          >
            <div className="px-10 flex-1">
              <p>
                Uploaded - {new Date(d.created_at).toLocaleDateString("en-GB")}
              </p>
              <h2>{d.title}</h2>
            </div>
            <Divider className="my-10" />
            <div className="px-10 doc-item-footer">
              <p className="m-0">
                Report contains <strong>{d?.entity_count}</strong>{" "}
                {d?.entity_count > 1 ? "entities" : "entity"} of{" "}
                <strong>{entityName}</strong>
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
