import ReactDOM from "react-dom";

const fullSize = { width: "100%", height: "100%" };
let layout = {
  ...fullSize,
  backgroundColor: "rgba(0, 0, 0, 0.4)",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 9999,
};
const center = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

/**
 * Overlay - Show overlay on the current document page
 *
 * @function
 * @param {string} content - The content that will be display on overlay foreground.
 * @param {string} backgroundColor - The overlay backgroud color. Defaults to a semi transparent background.
 * @param {boolean} centerContent - Determines wether to center foreground content.
 * @returns React JSX
 */
const Overlay = ({ content, backgroundColor, centerContent = false }) => {
  let outerStyle = layout;
  let innerStyle = fullSize;

  if (backgroundColor) outerStyle = { ...outerStyle, backgroundColor };
  if (centerContent) innerStyle = { ...innerStyle, ...center };

  return ReactDOM.createPortal(
    <div style={outerStyle}>
      <div style={innerStyle}>{content}</div>
    </div>,
    document.body
  );
};

export default Overlay;
