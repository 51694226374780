import {
  generateApiActionTypes,
  initializeState,
} from "../../utils/helpers/reduxStore";
import actions from "../actions/manualRules";

const actionTypes = {
  [actions.GET_MANUAL_RULES_REQUEST]: "getRules",
  [actions.GET_SINGLE_MANUAL_RULE_REQUEST]: "getSingleRule",
  [actions.GET_RULES_CREATORS_REQUEST]: "getRulesCreators",
  [actions.CREATE_MANUAL_RULE_REQUEST]: "createRule",
  [actions.UPDATE_MANUAL_RULE_REQUEST]: "updateRule",
  [actions.DELETE_MANUAL_RULE_REQUEST]: "deleteRule",
  [actions.DISABLE_MANUAL_RULE_REQUEST]: "disableRule",
  [actions.ENABLE_MANUAL_RULE_REQUEST]: "enableRule",
  [actions.SUBMIT_MANUAL_RULE_FEEDBACK_REQUEST]: "submitRuleFeedback",
};

const apiActionTypes = generateApiActionTypes(actionTypes);

const initialState = {
  ...initializeState(actionTypes),
  data: { pagination: {}, rules: [] },
};

const manualRules = (state = initialState, action = {}) => {
  const typeState = apiActionTypes[action.type];

  if (typeState)
    return {
      ...state,
      [typeState]: action.payload,
    };

  switch (action.type) {
    case actions.SET_MANUAL_RULES:
      return {
        ...state,
        data: action.payload,
      };

    case actions.RESET_MANUAL_RULES_STATE:
      return initialState;

    default:
      return state;
  }
};

export default manualRules;
