import { Menu } from "antd";
import { useSelector } from "react-redux";
import {
  ACCEPT_ACCROSS_DOCUMENT,
  ACCEPT_ALWAYS,
  entityClass,
  REJECT_ACCROSS_DOCUMENT,
  REJECT_ALWAYS,
} from "../../../constants";
import Annotation from "../../../utils/services/Annotation";

export const AcceptEntityMenuItems = ({ onClick }) => {
  return (
    <Menu onClick={onClick}>
      <Menu.Item key={ACCEPT_ACCROSS_DOCUMENT} className="lh-20">
        <b>Accept</b> <br /> <small>Accept entity for this document</small>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={ACCEPT_ALWAYS} className="lh-20">
        <b>Always accept</b> <br />{" "}
        <small>Create a rule for future documents</small>
      </Menu.Item>
    </Menu>
  );
};

export const RejectEntityMenuItems = ({ onClick }) => {
  return (
    <Menu onClick={onClick}>
      <Menu.Item key={REJECT_ACCROSS_DOCUMENT} className="lh-20">
        <b>Reject</b> <br /> <small>Reject accross this document</small>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={REJECT_ALWAYS} className="lh-20">
        <b>Always reject</b> <br />{" "}
        <small>Create a rule for future documents</small>
      </Menu.Item>
    </Menu>
  );
};

export const SingleEntityAnnotation = ({ entityId }) => {
  const analysis = useSelector(({ analysis }) => analysis);

  const entity = analysis.entitiesByIds[entityId];

  let blockText = analysis.blocks[entity.block_idx].text;
  if (entity.class === entityClass.TABLE) {
    const { table_idx, column_idx, row_idx } = entity;
    blockText = analysis.tables[table_idx][row_idx][column_idx];
  }

  const annotatedText = Annotation.singleNode(blockText, entity);
  return <>{annotatedText}</>;
};
