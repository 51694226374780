import "../../../assets/styles/loader.scss";

const SkeletonLoader = ({ className = "", ...styles }) => {
  return <div style={styles} className={`skeleton-loader ${className}`}></div>;
};

SkeletonLoader.DocumentCard = ({ loading = false, repeat = 1 }) => {
  return loading
    ? Array.from(Array(repeat).keys()).map((x) => (
        <div key={x} className="document-card">
          <div className="document-card-delete justify-end">
            <SkeletonLoader width="10px" height="15px" />
          </div>
          <div className="document-card-title">
            <SkeletonLoader width="100%" height="14px" />
          </div>
          <div className="document-card-actions align-center">
            <SkeletonLoader width="80px" height="14px" />
            <SkeletonLoader width="100px" height="25px" borderRadius="10px" />
          </div>
        </div>
      ))
    : null;
};

SkeletonLoader.QueuedDocument = ({ loading = false, repeat = 1 }) => {
  return loading
    ? Array.from(Array(repeat).keys()).map((x) => (
        <div key={x} className="queued-document bd-0">
          <div style={{ width: "80%" }} className="queued-document-title">
            <SkeletonLoader className="mr-5" width="17px" height="18px" />
            <SkeletonLoader width="100%" height="14px" borderRadius="5px" />
          </div>
          <SkeletonLoader width="14px" height="14px" borderRadius="50%" />
        </div>
      ))
    : null;
};

SkeletonLoader.EntityGroups = ({ loading = false, repeat = 1 }) => {
  return loading
    ? Array.from(Array(repeat).keys()).map((x) => (
        <div key={x} className="section-card mb-5 ">
          <div className="section-card-body justify-between align-items bd-0-x bd-0-top">
            <div className="d-flex align-items" style={{ width: "60%" }}>
              <SkeletonLoader width="12px" height="12px" className="mr-10" />
              <SkeletonLoader width="100%" height="12px" borderRadius="5px" />
            </div>
            <SkeletonLoader className="ml-5" width="18px" height="18px" />
          </div>
        </div>
      ))
    : null;
};

export default SkeletonLoader;
