import { useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useHistory } from "react-router";
import {
  setApiRequestIds,
  setProcessingEntities,
  setVirtualEntities,
} from "../store/actions";
import {
  useReload,
  useReloadAnalysis,
  useRemoveSlideInOnSuccess,
} from "./useReload";
import {
  getOrganisationMetadataKeys,
  getOrganisationMetadataProfiles,
  setMetadataKeys,
  setMetadataProfiles,
} from "../store/actions/metadata";
import {
  getDocumentMetadataKeys,
  setDocumentMetadataKeys,
} from "../store/actions/documentMetadata";
import messageBox from "../utils/services/MessageBox";
import { useMessageBoxError } from "./useMessageBox";
import {
  addEntityMessageBox,
  showAlwaysMsg,
  showEntityCount,
  showMsg,
} from "./helpers";
import successMessages from "../utils/messages/successMessages";
import { rejectEntityAddRuleMessageBox } from "./helpers";
import { changeEntityMessageBox } from "./helpers";

const {
  entity_accepted,
  entity_rejected,
  accepted_entity_count: aecMsg,
  rejected_entity_count: recMsg,
} = successMessages;

export const useClearProcessingEntities = ({ result, error, reqId }, type) => {
  const { processingEntities, virtualEntities, apiRequestIds } = useSelector(
    ({ selection }) => selection
  );
  const dispatch = useDispatch();

  // const { result, reqId, error } = actionState;

  useEffect(() => {
    const { success, data } = result;
    if (!(success && data) && !error) return;
    if (type && type === "error" && !error) return;
    if (!reqId || typeof reqId !== "string") return;
    if (!Object.keys(processingEntities).length) return;

    const entitiesObj = { ...processingEntities };
    const ve = _.cloneDeep(virtualEntities);
    const items = apiRequestIds[reqId] || reqId.split(",");
    items.forEach((id) => {
      if (entitiesObj[id]) delete entitiesObj[id];
      if (id.startsWith("ve.")) {
        const idPrefix = id.split("-")[0];
        if (ve[idPrefix]) delete ve[idPrefix][id];
        if (ve[idPrefix] && !Object.keys(ve[idPrefix]).length)
          delete ve[idPrefix];
      }
    });

    const { [reqId]: __, ...otherRequestIds } = apiRequestIds;

    batch(() => {
      dispatch(setProcessingEntities(entitiesObj));
      dispatch(setVirtualEntities(ve));
      dispatch(setApiRequestIds(otherRequestIds));
    });
  }, [reqId]);
};

const useAnalysisResponse = ({ result, error, reqId }) => {
  useReloadAnalysis(result, reqId);
  useClearProcessingEntities({ result, error, reqId }, "error");
  useMessageBoxError(error);
};

export const useAnalysisApiState = () => {
  /* May further DRY the operations in this hook with redux middleware later */
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    addEntity,
    acceptEntity,
    rejectEntity,
    rejectEntityNoInterest: reni,
    rejectEntityNotCorrect: renc,
    deleteRelationship,
    addRelationship,
    changeEntity,
    changeMultipleEntities,
    acceptEntityAccross: aacross,
    acceptEntityAlways: aalways,
    rejectEntityAccross: racross,
    rejectEntityAlways: ralways,
  } = useSelector(({ analysisApi }) => analysisApi);

  useAnalysisResponse(addEntity);
  useAnalysisResponse(acceptEntity);
  useAnalysisResponse(rejectEntity);
  useAnalysisResponse(reni);
  useAnalysisResponse(renc);
  useAnalysisResponse(deleteRelationship);
  useAnalysisResponse(addRelationship);
  useAnalysisResponse(changeEntity);
  useAnalysisResponse(changeMultipleEntities);
  useAnalysisResponse(aacross);
  useAnalysisResponse(aalways);
  useAnalysisResponse(racross);
  useAnalysisResponse(ralways);

  // console.log("renc.result", renc.result)

  useReload(addEntity.result, () => addEntityMessageBox(dispatch));
  useReload(changeEntity.result, () => changeEntityMessageBox(dispatch));
  useReload(acceptEntity.result, showMsg(entity_accepted));
  useReload(rejectEntity.result, showMsg(entity_rejected));
  useReload(aacross.result, showEntityCount(aecMsg, aacross.result));
  useReload(aalways.result, showAlwaysMsg(aecMsg, aalways.result, history));
  useReload(racross.result, showEntityCount(recMsg, racross.result));
  useReload(ralways.result, showAlwaysMsg(recMsg, ralways.result, history));
  useReload(reni.result, () =>
    rejectEntityAddRuleMessageBox(dispatch, history)
  );
  useReload(renc.result, () =>
    rejectEntityAddRuleMessageBox(dispatch, history, renc.result.ruleId, true)
  );
};

const useMetadataResponse = ({ result, error }) => {
  const dispatch = useDispatch();
  useReload(result, () => {
    dispatch(getOrganisationMetadataKeys());
    dispatch(getOrganisationMetadataProfiles());
  });
  useMessageBoxError(error);
};

export const useMetadataState = () => {
  const {
    getKeys,
    getProfiles,
    addKeys,
    updateKeys,
    deleteKey,
    addProfile,
    updateProfile,
    deleteProfile,
  } = useSelector(({ metadata }) => metadata);

  const dispatch = useDispatch();

  const keysResult = getKeys.result;
  const profilesResult = getProfiles.result;
  useReload(keysResult, () => {
    const keysMap = {};
    keysResult.data.keys.forEach((item) => (keysMap[item["key"]] = item));
    dispatch(setMetadataKeys(keysResult.data, keysMap));
  });
  useReload(profilesResult, () => {
    const profiles = (profilesResult.data.profiles || []).map((p) => ({
      ...p,
      key: p.id,
    }));
    dispatch(setMetadataProfiles(profiles));
  });

  useRemoveSlideInOnSuccess(addKeys.result);
  useRemoveSlideInOnSuccess(addProfile.result);
  useRemoveSlideInOnSuccess(updateProfile.result);

  useMetadataResponse(addKeys);
  useMetadataResponse(updateKeys);
  useMetadataResponse(deleteKey);
  useMetadataResponse(addProfile);
  useMetadataResponse(updateProfile);
  useMetadataResponse(deleteProfile);
};

const useDocumentMetadataResponse = (
  documentId,
  { result, error },
  loadAnaysis = false
) => {
  const dispatch = useDispatch();

  const res = loadAnaysis ? result : null;
  useReloadAnalysis(res);
  useReload(result, () => {
    dispatch(getDocumentMetadataKeys(documentId));
  });
  useMessageBoxError(error);
};

export const useDocumentMetadataState = () => {
  const { analysis, documentMetadata } = useSelector((state) => state);

  const {
    getKeys,
    addKeys,
    updateKeys,
    deleteAllKeys,
    deleteSingleKey,
    setProfile,
    deleteProfile,
  } = documentMetadata;

  const documentId = analysis.document.id;

  const dispatch = useDispatch();

  const keysResult = getKeys.result;
  useReload(keysResult, () =>
    dispatch(setDocumentMetadataKeys(keysResult.data))
  );

  useRemoveSlideInOnSuccess(addKeys.result);
  useRemoveSlideInOnSuccess(updateKeys.result);
  useRemoveSlideInOnSuccess(deleteAllKeys.result);

  useDocumentMetadataResponse(documentId, addKeys, true);
  useDocumentMetadataResponse(documentId, updateKeys, true);
  useDocumentMetadataResponse(documentId, deleteAllKeys);
  useDocumentMetadataResponse(documentId, deleteSingleKey);
  useDocumentMetadataResponse(documentId, setProfile);
  useDocumentMetadataResponse(documentId, deleteProfile);
};

export const useManualRuleState = () => {
  const { createRule: c, submitRuleFeedback: sf } = useSelector(
    ({ manualRules }) => manualRules
  );

  useReload(c.result, () => messageBox.show(c.result.message));
  useReload(sf.result, () => messageBox.show(sf.result.message));

  useMessageBoxError(c.error);
  useMessageBoxError(sf.error);
};
