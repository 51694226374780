import { DEFAULT_PREFIX } from "../../constants";

/**
 * StaticStorage class
 *
 * @class
 *
 * Defines instance methods for retreiving, saving and deleting
 * data in the frontend
 */
class StaticStorage {
  constructor(prefix) {
    this.prefix = (prefix || DEFAULT_PREFIX) + "-";
  }

  /**
   * setPrefix - Update the instance prefix
   *
   * @method
   *
   * @param {string} prefix The prefix to be prepended to data id
   */
  setPrefix(prefix) {
    this.prefix = prefix + "-";
  }

  /**
   * getPrefix - Returns the current prefix
   *
   * @method
   *
   * @returns The current prefix
   */
  getPrefix() {
    return this.prefix;
  }

  /**
   * save - Adds a data item to the storage
   *
   * @method
   *
   * @param {string} id a string to identify the data being saved
   * @param {string | number} item the data to be saved
   *
   */
  save(id, item) {
    if (!item && item !== 0)
      throw new Error("item to save cannot me null, undefined or empty");
    if (!id || typeof id !== "string")
      throw new Error(
        "You must provide the id as first argument of type string"
      );

    try {
      localStorage.setItem(this.prefix + id, JSON.stringify(item));
    } catch (error) {
      throw new Error(error.message);
    }
  }

  /**
   * get - Retrieves a specified data item from the storage
   *
   * @method
   *
   * @param {string} id specifies the data item to be retrieved
   *
   */
  get(id, defaultValue = null) {
    if (!id || typeof id !== "string")
      throw new Error(
        "You must provide the id as first argument of type string"
      );

    try {
      const item = localStorage.getItem(this.prefix + id);
      return !!item ? JSON.parse(item) : defaultValue;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  /**
   * delete - Removes a speficied data item from the stroage
   *
   * @method
   *
   * @param {string} id specifies the data item to be removed
   *
   */
  delete(id) {
    if (!id || typeof id !== "string")
      throw new Error(
        "You must provide the id as first argument of type string"
      );

    try {
      localStorage.removeItem(this.prefix + id);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  /**
   * deleteAll - Empty the storage
   *
   * @method
   *
   *
   */
  deleteAll() {
    try {
      localStorage.clear();
    } catch (error) {
      throw new Error(error.message);
    }
  }
}

// An instance of the storage exported to be reused
export const storage = new StaticStorage(DEFAULT_PREFIX);

export default StaticStorage;
