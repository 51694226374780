import axios from "axios";
import api from "../../constants/api";

const actions = {
  GET_KEYS: "GET_METADATA_KEYS",
  ADD_KEYS: "ADD_METADATA_KEYS",
  UPDATE_KEYS: "UPDATE_METADATA_KEYS",
  DELETE_KEY: "DELETE_METADATA_KEY",
  GET_PROFILES: "GET_METADATA_PROFILES",
  GET_PROFILE_DETAILS: "GET_METADATA_PROFILE_DETAILS",
  ADD_PROFILE: "ADD_METADATA_PROFILE",
  UPDATE_PROFILE: "UPDATE_METADATA_PROFILE",
  DELETE_PROFILE: "DELETE_METADATA_PROFILE",
  DELETE_PROFILE_KEY: "DELETE_METADATA_PROFILE_KEY",
  RESET: "RESET_ANALYIS_API_STATE",
  METADATA_KEYS: "SET_METADATA_KEYS",
  METADATA_PROFILES: "SET_METADATA_PROFILES",
};

export default actions;

/* Action creators */

export const setMetadataKeys = (data = {}, keysMap) => ({
  type: actions.METADATA_KEYS,
  payload: { keys: data.keys, keysMap },
});

export const setMetadataProfiles = (profiles = []) => ({
  type: actions.METADATA_PROFILES,
  payload: profiles,
});

export const getOrganisationMetadataKeys = () => ({
  type: actions.GET_KEYS,
  payload: axios.get(api.METADATA_KEYS),
});

export const addOrganisationMetadataKeys = (data) => ({
  type: actions.ADD_KEYS,
  payload: axios.post(api.METADATA_KEYS, { keys: data }),
});

export const updateOrganisationMetadataKeys = (data) => ({
  type: actions.UPDATE_KEYS,
  payload: axios.put(api.METADATA_KEYS, { keys: data }),
});

export const deleteOrganisationMetadataKey = (key) => ({
  type: actions.DELETE_KEY,
  payload: axios.delete(`${api.METADATA_KEYS}/${key}`),
});

export const getOrganisationMetadataProfiles = () => ({
  type: actions.GET_PROFILES,
  payload: axios.get(api.METADATA_PROFILES),
});

export const getOrganisationMetadataProfileDetails = (profile_id) => ({
  type: actions.GET_PROFILE_DETAILS,
  payload: axios.get(`${api.METADATA_PROFILES}${profile_id}`),
});

export const addOrganisationMetadataProfile = (data) => ({
  type: actions.ADD_PROFILE,
  payload: axios.post(api.METADATA_PROFILES, data),
});

export const updateOrganisationMetadataProfile = (profile_id, data) => ({
  type: actions.UPDATE_PROFILE,
  payload: axios.put(`${api.METADATA_PROFILES}${profile_id}`, data),
});

export const deleteOrganisationMetadataProfile = (profile_id) => ({
  type: actions.DELETE_PROFILE,
  payload: axios.delete(`${api.METADATA_PROFILES}${profile_id}`),
});

export const deleteOrganisationMetadataProfileKey = (profile_id, key) => ({
  type: actions.DELETE_PROFILE_KEY,
  payload: axios.delete(`${api.METADATA_PROFILES}${profile_id}/${key}`),
});
