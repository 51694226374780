import { Layout } from "antd";
import { useSelector } from "react-redux";
import {
  appendHiddenLineStyle,
  positionLines,
  scrollingIntervals,
} from "../../../utils/helpers/analysis/relationship";
import CenterSpinLoader from "../loaders/CenterSpinLoader";
import ModalView from "../widgets/ModalView";
import SlideInView from "../widgets/SlideInView";
import Footer from "./Footer";
import Header from "./Header";

/**
 * AppLayout
 * wraps the layout components
 */
const AppLayout = ({ children }) => {
  const { relationship, loader } = useSelector((state) => state);

  return (
    <Layout className="app-layout">
      <Header loader={loader} />
      <div className="app-body relative-p">
        <Layout.Content
          onScroll={scrollingIntervals(
            appendHiddenLineStyle,
            positionLines(relationship.props)
          )}
          className="app-content"
        >
          {children}
        </Layout.Content>
        <SlideInView />
        <ModalView />
        <Footer />
      </div>
      <CenterSpinLoader loading={loader.loading} />
    </Layout>
  );
};

export default AppLayout;
