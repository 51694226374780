import React from "react";
import { Switch } from "react-router-dom";
// import mixpanel from "mixpanel-browser";
import { pageRoutes, errorRoutes } from "../configs/routes";
import AppRoutesHOC from "../HOCs/AppRoutesHOC";
import "antd/dist/antd.css";
import "../assets/styles/app.scss";
import AppErrorBoundary from "../errorBoundaries/AppErrorBoundary";
import { AuthProvider } from "../contexts/AuthContext";
import useScripts from "../hooks/useScripts";
import scriptConfigs from "../configs/scripts";

const App = () => {
  useScripts(scriptConfigs);

  return (
    <AppErrorBoundary>
      <AuthProvider>
        <div className="app-wrapper">
          <Switch>
            {[...pageRoutes, ...errorRoutes].map((route, index) => {
              if (route.exclude) return null;
              return <AppRoutesHOC key={`app-${index}`} {...route} />;
            })}
          </Switch>
        </div>
      </AuthProvider>
    </AppErrorBoundary>
  );
};

export default App;
