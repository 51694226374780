import { Button, Input, Form, Table, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReload } from "../../../hooks/useReload";
import {
  deleteOrganisationMetadataProfileKey,
  getOrganisationMetadataProfileDetails,
  updateOrganisationMetadataProfile,
} from "../../../store/actions/metadata";
import { profileKeysColumns } from "./columns";

const ProfileDetails = ({ record = {} }) => {
  const [profile, setProfile] = useState(record);

  const [form] = Form.useForm();

  const { metadata } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    setProfile(record);
    form.setFieldsValue(record);
    dispatch(getOrganisationMetadataProfileDetails(record.id));
  }, [record]);

  useReload(metadata.deleteProfileKey.result, () =>
    dispatch(getOrganisationMetadataProfileDetails(profile.id))
  );

  const data = metadata.getProfileDetails.result.data || {};
  useEffect(() => {
    if (!data || data.id !== record.id) return;
    setProfile(data);
  }, [data]);

  const handleSave = ({ name, description, ...profileKeys }) => {
    dispatch(
      updateOrganisationMetadataProfile(profile.id, {
        name,
        description,
        keys: Object.entries(profileKeys).map(([key, default_value]) => ({
          key,
          default_value,
        })),
      })
    );
  };

  const addSelectedKey = (key) => {
    setProfile((p) => {
      return {
        ...p,
        keys: [...p.keys, { ...metadata.keysMap[key], unsaved: true }],
      };
    });
  };

  const removeUnsaved = (key) => {
    setProfile((p) => {
      return {
        ...p,
        keys: p.keys.filter((x) => x.key !== key),
      };
    });
  };

  const clearUnsaved = () => {
    setProfile((p) => {
      return {
        ...p,
        keys: p.keys.filter((key) => !key.unsaved),
      };
    });
  };

  const deleteKey = (key) => {
    dispatch(deleteOrganisationMetadataProfileKey(profile.id, key));
  };

  const profileKeys = profile.keys || [];

  return (
    <div id="profile-details-pane" className="app-card-body flex-column mt-20">
      <Form
        form={form}
        initialValues={profile}
        layout="vertical"
        onFinish={handleSave}
      >
        <Form.Item
          label="Profile name"
          name="name"
          validateTrigger={["onChange", "onBlur"]}
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Profile name is required",
            },
          ]}
        >
          <Input
            id="name"
            name="name"
            className="app-input"
            placeholder="Profile name"
          />
        </Form.Item>
        <Form.Item
          label="Profile description"
          name="description"
          validateTrigger={["onChange", "onBlur"]}
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Profile description is required",
            },
          ]}
        >
          <Input.TextArea
            id="description"
            name="description"
            className="app-input"
            placeholder="Profile description"
          />
        </Form.Item>
        <Table
          dataSource={profileKeys}
          columns={profileKeysColumns(deleteKey)}
          pagination={false}
          loading={metadata.deleteProfileKey.loading}
          footer={() => (
            <Form.Item>
              <Select
                mode="multiple"
                allowClear
                showArrow
                style={{ width: "100%" }}
                placeholder="Add keys"
                onSelect={(key) => addSelectedKey(key)}
                onDeselect={(key) => removeUnsaved(key)}
                onClear={clearUnsaved}
              >
                {metadata.keys.map(({ key }) => {
                  return !profileKeys.find((k) => k.key === key) ? (
                    <Select.Option key={key}>{key}</Select.Option>
                  ) : null;
                })}
              </Select>
            </Form.Item>
          )}
        />
        <Form.Item>
          <Button type="primary" htmlType="submit" className="mr-10">
            Save
          </Button>
          <Button
            onClick={() => {
              form.resetFields();
            }}
          >
            Reset
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ProfileDetails;
