import { useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  setAnalysis,
  setRelationshipProps,
  setRelationships,
  setSelection,
} from "../store/actions";
import { preLoadImages } from "../utils/helpers";
import { mapRelationshipsByIds } from "../utils/helpers/analysis/relationship";

/**
 * useAnalysisData - update analysis and relationship states with data from api
 *
 */
export const useAnalysisData = (data) => {
  const dispatch = useDispatch();
  const { props } = useSelector(({ relationship }) => relationship);

  useEffect(() => {
    if (!data) return;

    (async () => {
      await preLoadImages(data.top_image_urls);

      batch(() => {
        dispatch(setSelection());
        dispatch(setAnalysis(data));
        dispatch(setRelationships(data.relationships, props));
      });
    })();
  }, [data]);
};

/**
 * useRelationshipProps - update properties of relationships
 *
 */
export const useRelationshipProps = () => {
  const dispatch = useDispatch();
  const relationship = useSelector(({ relationship }) => relationship);

  useEffect(() => {
    const relationshipsByIds = mapRelationshipsByIds(relationship.relations);
    dispatch(setRelationshipProps(relationshipsByIds));
  }, [relationship.relations]);
};
