import { useSelector } from "react-redux";
import { blockType, WRAPPER_PARENT } from "../../../constants";
import { getTableCellRef } from "../../../utils/helpers/analysis";
import HTMLConstruct from "./HTMLConstruct";

/**
 * HTMLTableConstruct - Construct a table block and include annotionaton, selections and options
 *
 * @function
 *
 * @param {{object}} block - The block data
 * @returns React JSX
 */
const HTMLTableConstruct = ({ blockGroup }) => {
  const { analysis } = useSelector((state) => state);

  const blockIndex = blockGroup.indices[0];
  const block = analysis.blocks[blockIndex];

  const { table_idx } = block;

  if (!Array.isArray(analysis.tables)) return null;
  if (table_idx >= analysis.tables.length) return null;

  const data = analysis.tables[table_idx];
  return (
    <table>
      <thead>
        <tr>
          {data[0].map((column, idx) => (
            <th key={idx}>
              <CellConstruct
                text={column}
                block={block}
                column_idx={idx}
                row_idx={0}
              />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.slice(1).map((row, i) => {
          return (
            <tr key={i}>
              {row.map((column, j) => {
                return (
                  <td key={j}>
                    <CellConstruct
                      text={column}
                      block={block}
                      column_idx={j}
                      row_idx={i + 1}
                    />
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const CellConstruct = ({ text, block, column_idx, row_idx }) => {
  const { analysis, selection } = useSelector((state) => state);
  const { tableEntitiesGroups } = analysis;
  const { activeSelection } = selection;

  const ref = getTableCellRef(block.table_idx, column_idx, row_idx);
  const blockId = `${blockType.TABLE}-${ref}-${block.index}`;
  const highlight =
    activeSelection && activeSelection.blockId === blockId
      ? activeSelection
      : null;

  const entities = tableEntitiesGroups[ref] || [];

  const cellBlock = {
    ...block,
    ref,
    text,
    sentence_entities_ids: [],
    text_entities_ids: entities,
  };

  return (
    <div id={blockId} data-type={WRAPPER_PARENT}>
      <HTMLConstruct block={cellBlock} activeSelection={highlight} />
    </div>
  );
};

export default HTMLTableConstruct;
