import { Button } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import ActionTip from "./ActionTip";

const MaskButton = ({
  mask = false,
  maskText = "Mask",
  unMaskText = "Unmask",
  clickHandler = () => {},
}) => {
  return mask ? (
    <ActionTip title={unMaskText}>
      <Button size="small" type="text" onClick={() => clickHandler(false)}>
        <EyeOutlined />
      </Button>
    </ActionTip>
  ) : (
    <ActionTip title={maskText}>
      <Button size="small" type="text" onClick={() => clickHandler(true)}>
        <EyeInvisibleOutlined />
      </Button>
    </ActionTip>
  );
};

export default MaskButton;
