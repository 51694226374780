import axios from "axios";
import api from "../../constants/api";

const actions = {
  METADATA_KEYS: "DOCUMENT_METADATA_KEYS",
  GET_KEYS: "GET_DOCUMENT_METADATA_KEYS",
  GET_SINGLE_KEY: "GET_SINGLE_DOCUMENT_METADATA_KEY",
  ADD_KEYS: "ADD_DOCUMENT_METADATA_KEYS",
  UPDATE_KEYS: "UPDATE_DOCUMENT_METADATA_KEYS",
  DELETE_ALL_KEYS: "DELETE_ALL_DOCUMENT_METADATA_KEYS",
  DELETE_SINGLE_KEY: "DELETE_SINGLE_DOCUMENT_METADATA_KEY",
  SET_PROFILE: "SET_DOCUMENT_METADATA_PROFILE",
  DELETE_PROFILE: "DELETE_DOCUMENT_METADATA_PROFILE",
  RESET: "RESET_DOCUMENT_METADATA_STATE",
  RESET_API_STATE: "RESET_DOCUMENT_METADATA_API_STATE",
};

export default actions;

/* Action creators */

export const setDocumentMetadataKeys = (data = {}) => ({
  type: actions.METADATA_KEYS,
  payload: data.metadata || [],
});

export const getDocumentMetadataKeys = (id) => ({
  type: actions.GET_KEYS,
  payload: axios.get(api.DOCUMENT_METADATA_KEYS.replace(":documentId", id)),
});

export const resetDocumentMetadataApi = (id) => ({
  type: actions.RESET_API_STATE,
});

export const addDocumentMetadataKeys = (id, data) => ({
  type: actions.ADD_KEYS,
  payload: axios.post(
    api.DOCUMENT_METADATA_KEYS.replace(":documentId", id),
    data
  ),
});

export const updateDocumentMetadataKeys = (id, data) => ({
  type: actions.UPDATE_KEYS,
  payload: axios.put(
    api.DOCUMENT_METADATA_KEYS.replace(":documentId", id),
    data
  ),
});

export const deleteAllDocumentMetadataKeys = (id) => ({
  type: actions.DELETE_ALL_KEYS,
  payload: axios.delete(api.DOCUMENT_METADATA_KEYS.replace(":documentId", id)),
});

export const deleteSingleDocumentMetadataKey = (id, key) => ({
  type: actions.DELETE_SINGLE_KEY,
  payload: axios.delete(
    `${api.DOCUMENT_METADATA_KEYS.replace(":documentId", id)}/${key}`
  ),
});

export const setDocumentMetadataProfile = (id, profileId) => ({
  type: actions.SET_PROFILE,
  payload: axios.put(
    `${api.DOCUMENT_METADATA_PROFILES.replace(":documentId", id)}/${profileId}`
  ),
});

export const deleteDocumentMetadataProfile = (id) => ({
  type: actions.DELETE_PROFILE,
  payload: axios.delete(
    api.DOCUMENT_METADATA_PROFILES.replace(":documentId", id)
  ),
});
