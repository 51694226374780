import { Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReload } from "../../../hooks/useReload";
import {
  getCollectionDocumentsRequest,
  removeDocumentFromCollectionRequest,
} from "../../../store/actions/collections";
import { collectionDocumentsColumns } from "./columns";

const CollectionDocuments = ({ collectionId, reloadCallback }) => {
  const [documents, setDocuments] = useState([]);
  const dispatch = useDispatch();

  const { getCollectionDocuments: gcd, removeCollectionDocument: rcd } =
    useSelector(({ collections }) => collections);

  useReload(gcd.result, () => setDocuments(gcd.result.data?.documents));
  useReload(rcd.result, () =>
    dispatch(getCollectionDocumentsRequest(collectionId))
  );
  useReload(rcd.result, reloadCallback);

  useEffect(() => {
    dispatch(getCollectionDocumentsRequest(collectionId));
  }, []);

  const removeDocumentFromCollection = (documentId) => {
    dispatch(removeDocumentFromCollectionRequest(documentId, collectionId));
  };

  return (
    <div>
      <Table
        columns={collectionDocumentsColumns(removeDocumentFromCollection)}
        dataSource={documents}
        showHeader={false}
        pagination={false}
      />
    </div>
  );
};

export default CollectionDocuments;
