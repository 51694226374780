import React, { useState } from "react";
import { Table, Form, Button } from "antd";
import EditableCell from "./EditableCell";

const EditableTable = ({
  columns = [],
  data,
  saveEditHandler,
  editErrorHandler,
  editHandler,
  addActionNodes = null,
}) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    const editingFields = {};
    columns.forEach((col) => {
      if (col.editable) editingFields[col.key] = record[col.key];
    });
    form.setFieldsValue(editingFields);
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (record) => {
    try {
      const fields = await form.validateFields();
      setEditingKey("");
      if (saveEditHandler) saveEditHandler(fields, record);
    } catch (errInfo) {
      if (editErrorHandler) editErrorHandler(errInfo);
    }
  };

  const actionColumn = {
    title: "Action",
    dataIndex: "action",
    align: "center",
    render: (_, record) => {
      const editable = isEditing(record);
      return editable ? (
        <span>
          <Button onClick={() => save(record)} className="px-5" type="link">
            Save
          </Button>
          <Button onClick={cancel} className="px-5" type="link">
            Cancel
          </Button>
        </span>
      ) : (
        <>
          <Button
            disabled={editingKey !== ""}
            onClick={() => (editHandler ? editHandler(record) : edit(record))}
            className="px-5"
            type="link"
          >
            Edit
          </Button>
          {addActionNodes(record)}
        </>
      );
    },
  };

  const cols = [...columns, actionColumn];

  const mergedColumns = cols.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        required: !!col.required,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        dataSource={data}
        columns={mergedColumns}
        pagination={{
          onChange: cancel,
        }}
      />
    </Form>
  );
};

export default EditableTable;
