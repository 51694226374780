import axios from "axios";
import { useDispatch } from "react-redux";
import { createContext, useContext, useEffect, useState } from "react";
import Auth from "../utils/services/Auth";
import { SET_USER_DETAILS } from "../store/actions";
import Mixpanel from "../utils/services/Mixpanel";
import Hotjar from "../utils/services/Hotjar";

const AuthContext = createContext();

// export const AuthProvider = AuthContext.Provider;
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const authInstance = await Auth.authenticate();
      const bearerToken = `Bearer ${authInstance.token}`;
      axios.defaults.headers.common["Authorization"] = bearerToken;
      axios.defaults.baseURL = window._env_.BACKEND_URL;
      setAuth(authInstance);
      const userDetails = Auth.getUserDetails(authInstance);
      dispatch({
        type: SET_USER_DETAILS,
        payload: userDetails,
      });
      Mixpanel.identify(`${userDetails.organizationId}-${userDetails.id}`);
      Mixpanel.setUser(userDetails);
      Hotjar.identify(
        `${userDetails.organizationId}-${userDetails.id}`,
        userDetails
      );
    })();
  }, []);
  return (
    <AuthContext.Provider
      value={{ auth, setAuth, userDetails: Auth.getUserDetails(auth) }}
    >
      {auth && children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
