import axios from "axios";
import api from "../../constants/api";

const actions = {
  SET_MANUAL_RULES: "SET_MANUAL_RULES",
  RESET_MANUAL_RULES_STATE: "RESET_MANUAL_RULES_STATE",
  GET_MANUAL_RULES_REQUEST: "GET_MANUAL_RULES_REQUEST",
  GET_SINGLE_MANUAL_RULE_REQUEST: "GET_SINGLE_MANUAL_RULE_REQUEST",
  GET_RULES_CREATORS_REQUEST: "GET_RULES_CREATORS_REQUEST",
  CREATE_MANUAL_RULE_REQUEST: "CREATE_MANUAL_RULE_REQUEST",
  UPDATE_MANUAL_RULE_REQUEST: "UPDATE_MANUAL_RULE_REQUEST",
  DELETE_MANUAL_RULE_REQUEST: "DELETE_MANUAL_RULE_REQUEST",
  DISABLE_MANUAL_RULE_REQUEST: "DISABLE_MANUAL_RULE_REQUEST",
  ENABLE_MANUAL_RULE_REQUEST: "ENABLE_MANUAL_RULE_REQUEST",
  SUBMIT_MANUAL_RULE_FEEDBACK_REQUEST: "SUBMIT_MANUAL_RULE_FEEDBACK_REQUEST",
};

export default actions;

/* Action creators */

export const setRules = (data = { rules: [] }) => ({
  type: actions.SET_MANUAL_RULES,
  payload: data,
});

export const resetRulesState = () => ({
  type: actions.RESET_MANUAL_RULES_STATE,
});

export const getRulesRequest = (query = "") => ({
  type: actions.GET_MANUAL_RULES_REQUEST,
  payload: axios.get(`${api.MANUAL_RULES}?${query}`),
});

export const getSingleRuleRequest = (ruleId) => ({
  type: actions.GET_SINGLE_MANUAL_RULE_REQUEST,
  payload: axios.get(`${api.MANUAL_RULES}/${ruleId}`),
});

export const createRuleRequest = (data) => ({
  type: actions.CREATE_MANUAL_RULE_REQUEST,
  payload: axios.post(api.MANUAL_RULES, data),
});

export const updateRuleRequest = (ruleId, data) => ({
  type: actions.UPDATE_MANUAL_RULE_REQUEST,
  payload: axios.put(`${api.MANUAL_RULES}/${ruleId}`, data),
});

export const disableRuleRequest = (ruleId) => ({
  type: actions.DISABLE_MANUAL_RULE_REQUEST,
  payload: axios.put(`${api.MANUAL_RULES}/${ruleId}/disable`),
});

export const enableRuleRequest = (ruleId) => ({
  type: actions.ENABLE_MANUAL_RULE_REQUEST,
  payload: axios.put(`${api.MANUAL_RULES}/${ruleId}/enable`),
});

export const submitRuleFeedbackRequest = (ruleId, feedback) => ({
  type: actions.SUBMIT_MANUAL_RULE_FEEDBACK_REQUEST,
  payload: axios.put(`${api.MANUAL_RULES}/${ruleId}/feedback`, { feedback }),
});

export const deleteRuleRequest = (ruleId) => ({
  type: actions.DELETE_MANUAL_RULE_REQUEST,
  payload: axios.delete(`${api.MANUAL_RULES}/${ruleId}`),
});

export const getRulesCreatorsRequest = () => ({
  type: actions.GET_RULES_CREATORS_REQUEST,
  payload: axios.get(`${api.MANUAL_RULES}/creators`),
});
