import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, PageHeader } from "antd";
import routes from "../../../../constants/routes";
import { documentStatus } from "../../../../constants";
import HeaderBottom from "./HeaderBottom";
import HeaderTitle from "./HeaderTitle";
import CompleteTagWidget from "./CompleteTagWidget";
import MetadataWidget from "./MetadataWidget";
import TLPWidget from "./TLPWidget";
import RelationshipWidget from "./RelationshipWidget";
import DownloadStixWidget from "./DownloadStixWidget";
import ActionTip from "../../../common/widgets/ActionTip";
import { FileTextOutlined } from "@ant-design/icons";

const { COMPLETED } = documentStatus;

/**
 * Page header view for analysis page
 *
 * @function
 * @returns React JSX
 */
const AnalysisPageHeader = ({ currentLoading, initialLoading }) => {
  const history = useHistory();
  const { analysis } = useSelector((state) => state);
  const { document, entityProps } = analysis;

  const isCompleted = document.status === COMPLETED;

  let sourceUrl = document.source;
  if (sourceUrl?.endsWith(".pdf") || sourceUrl === "text upload") {
    sourceUrl = routes.DOCUMENT_SOURCE.replace(":documentId", document.id);
  }
  return (
    <div id="analysis-header" className="analysis-header">
      <PageHeader
        className="site-page-header"
        onBack={() => history.push(routes.DOCUMENTS)}
        title={<HeaderTitle initialLoading={initialLoading} />}
        tags={
          !!document.id
            ? [
                <CompleteTagWidget
                  key="complete-tag-widget"
                  docId={document.id}
                  isCompleted={isCompleted}
                />,
                <MetadataWidget key="metadata-widget" docId={document.id} />,
                <TLPWidget key="tlp-widget" docId={document.id} />,
              ]
            : []
        }
        extra={[
          <RelationshipWidget
            key="relationship-widget"
            loading={currentLoading}
          />,
          <a
            key="document-source"
            href={sourceUrl}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              if (!document.id) e.preventDefault();
            }}
          >
            <ActionTip title="View source">
              <Button
                shape="round"
                icon={<FileTextOutlined />}
                size="small"
                disabled={!document.id}
              >
                Source
              </Button>
            </ActionTip>
          </a>,
          <DownloadStixWidget
            key="download-stix-widget"
            isCompleted={isCompleted}
            docId={document.id}
            hasStixBundle={analysis.hasStixBundle}
          />,
        ]}
        footer={
          <HeaderBottom
            initialLoading={initialLoading}
            documentId={document.id}
            entityProps={entityProps}
          />
        }
      />
    </div>
  );
};

export default AnalysisPageHeader;
