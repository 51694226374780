import axios from "axios";
import { call, put, select } from "redux-saga/effects";
import api from "../../constants/api";
import { promiseTypeSuffixes, RULE_TYPES } from "../../constants";
import { getAnalysisDetails } from "../actions/apiRequests";

const [LOADING, SUCCESS, FAILED] = promiseTypeSuffixes;

export function* rejectEntityAndCreateRule(action) {
  try {
    const resolvedPayload = yield action.payload;
    const { documentId, entityId } = resolvedPayload;
    yield put({ type: `${action.type}_${LOADING}` });

    const rejectUrl = `${api.DOCUMENT_ENTITY_REJECT.replace(
      ":documentId",
      documentId
    ).replace(":entityId", entityId)}?reqId=${entityId}`;

    const res = yield call(() => axios.put(rejectUrl, { cascade: true }));
    yield put(getAnalysisDetails(documentId));

    let entity = yield select(
      ({ analysis }) => analysis.entitiesByIds[entityId]
    );
    const rule = {
      entity_instance: entity.text,
      entity_type: entity.type,
      rule: RULE_TYPES.do_not_annotate.value,
    };
    const ruleRes = yield call(() =>
      axios.post(`${api.MANUAL_RULES}?reqId=${entityId}`, rule)
    );
    res.data.ruleId = ruleRes.data.id;

    yield put({ type: `${action.type}_${SUCCESS}`, payload: res });
  } catch (e) {
    yield put({ type: `${action.type}_${FAILED}`, payload: e });
  }
}

export function* acceptEntityAndCreateRule(action) {
  try {
    const resolvedPayload = yield action.payload;
    const { documentId, entityId } = resolvedPayload;
    yield put({ type: `${action.type}_${LOADING}` });

    const acceptUrl = `${api.DOCUMENT_ENTITY_ACCEPT.replace(
      ":documentId",
      documentId
    ).replace(":entityId", entityId)}?reqId=${entityId}`;

    const res = yield call(() => axios.put(acceptUrl, { cascade: true }));
    yield put(getAnalysisDetails(documentId));

    let entity = yield select(
      ({ analysis }) => analysis.entitiesByIds[entityId]
    );
    const rule = {
      entity_instance: entity.text,
      entity_type: entity.type,
      rule: RULE_TYPES.annotate.value,
    };
    const ruleRes = yield call(() =>
      axios.post(`${api.MANUAL_RULES}?reqId=${entityId}`, rule)
    );
    res.data.ruleId = ruleRes.data.id;

    yield put({ type: `${action.type}_${SUCCESS}`, payload: res });
  } catch (e) {
    yield put({ type: `${action.type}_${FAILED}`, payload: e });
  }
}
