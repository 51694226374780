import { Radio } from "antd";
import { useSelector } from "react-redux";
import { useState } from "react";
import EntityTypeOptions from "../EntityTypeOptions";
import { entityStatusProps } from "../../../../constants";
import { useEffect } from "react";

/**
 * Change the type of an existing entity
 *
 * @returns React JSX
 */
const ChangeEntityType = ({ selectedEntity }) => {
  const [changeMultiple, setChangeMultiple] = useState(true);

  // App state
  const { analysis } = useSelector((state) => state);
  const { entitiesByIds, entityGroups, entityProps } = analysis;

  const sourceEntity = entitiesByIds[selectedEntity.id];

  const { status, type, text } = sourceEntity;
  const matchedEntityIds = entityGroups[status][type].text[text];
  const entitiesCount = matchedEntityIds.length;

  useEffect(() => {
    if (entitiesCount <= 1) setChangeMultiple(false);
  }, []);

  const [w1] = entitiesCount > 1 ? ["instances"] : ["instance"];

  return (
    <div>
      <div className="section-card">
        <div className="section-card-body">
          <p>
            We identified {entitiesCount} {w1} of <strong>{text}</strong> [
            {entityProps[type].label}] in the{" "}
            <strong>{entityStatusProps[status].label}</strong> group of current
            document.
          </p>
          <div>
            <strong>Do you want to change for multiple instances?</strong>
            <span className="d-block mt-5">
              <Radio.Group
                onChange={(e) => setChangeMultiple(e.target.value)}
                value={changeMultiple}
                disabled={entitiesCount <= 1}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </span>
          </div>
        </div>
      </div>
      <EntityTypeOptions
        entityIds={changeMultiple ? matchedEntityIds : [selectedEntity.id]}
        disableList={!changeMultiple}
        entityText={text}
      />
    </div>
  );
};

export default ChangeEntityType;
