import { useEffect } from "react";
import { batch, useDispatch } from "react-redux";
import {
  RESET_ANALYIS_STATE,
  RESET_RELATIONSHIP_STATE,
  RESET_SELECTION_STATE,
} from "../store/actions";
import documentMetadata from "../store/actions/documentMetadata";
import analysisApiActions from "../store/actions/apiRequests";
import { resetRulesState } from "../store/actions/manualRules";

/**
 * useAnalysisPageState - Resets data used in analysis page
 *
 */
export const useAnalysisPageState = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      // Reset analysis state
      batch(() => {
        dispatch({ type: RESET_SELECTION_STATE });
        dispatch({ type: RESET_RELATIONSHIP_STATE });
        dispatch({ type: analysisApiActions.RESET_ANALYIS_API_STATE });
        dispatch({ type: RESET_ANALYIS_STATE });
        dispatch({ type: documentMetadata.RESET });
        dispatch(resetRulesState());
      });

      try {
        // remove relationships svg lines
        const leaderLineSVGs = document.querySelectorAll(`.leader-line`);
        leaderLineSVGs.forEach((svg) => svg.remove());
        const leaderLineDef = document.getElementById("leader-line-defs");
        if (leaderLineDef) leaderLineDef.remove();
      } catch (error) {}
    };
  }, []);
};
