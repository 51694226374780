import { showModalView, showSlideInView } from "../../../store/actions";
import FeedbackForm from "./FeebackForm";
import RuleForm from "./RuleForm";

export const showRuleForm = (ruleId, dispatch) => {
  const title = !!ruleId ? "Edit rule" : "Add a new rule";
  dispatch(showSlideInView(RuleForm, { ruleId }, { title }));
};

export const showRuleFeedbackForm = (ruleId, dispatch) => {
  dispatch(
    showModalView(FeedbackForm, { ruleId }, { title: "Feedback on suggestion" })
  );
};

export const handlePagination =
  (setParams) => (page_number, results_per_page) =>
    setParams((params) => ({ ...params, page_number, results_per_page }));

export const getFilterProps = (defaultLabel, data = {}) => {
  return {
    showSearch: false,
    allowClear: false,
    style: { width: "150px" },
    className: "mr-15",
    defaultValue: `${defaultLabel}: All`,
    options: [
      { label: `${defaultLabel}: All`, value: "" },
      ...Object.values(data).map(({ value, label }) => ({ value, label })),
    ],
  };
};

export const handleFilter = (param, setParams) => (value) => {
  setParams((params) => ({ ...params, [param]: value, page_number: 1 }));
};
export const handleSearch = (setParams) => (e) => {
  setParams((params) => ({ ...params, q: e.target.value, page_number: 1 }));
};

const inverseOrder = (order) => {
  return (
    {
      asc: "desc",
      desc: "asc",
    }[order] || "asc"
  );
};

const mapOrder = {
  ascend: "asc",
  descend: "desc",
};

export const handleSorting = (setParams) => (_, __, sorter) => {
  const { field, order } = sorter;
  let sort_by = field;
  if (!order) sort_by = undefined;
  setParams((p) => ({
    ...p,
    sort_by,
    sort_order: mapOrder[order],
    page_number: 1,
  }));
};
