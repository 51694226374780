import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import AppErrorBoundary from "../../../errorBoundaries/AppErrorBoundary";
import { useRelationshipProps } from "../../../hooks/useAnalysisData";
import { useAnalysisApiState } from "../../../hooks/useApiState";
import { useAnalysisPageState } from "../../../hooks/useResetState";
import { getAnalysisDetails } from "../../../store/actions/apiRequests";
import stixGraphActions from "../../../store/actions/stixGraph";
import GraphPageHeader from "./GraphPageHeader";
import GraphPane from "./GraphPane";

/**
 * Renders STIX graph page
 *
 * @function
 * @returns React JSX
 */
const STIXGraph = () => {
  const { documentId } = useParams();
  const dispatch = useDispatch();

  useAnalysisPageState();
  useAnalysisApiState();
  useRelationshipProps();

  useEffect(() => {
    dispatch(getAnalysisDetails(documentId));

    return () => dispatch({ type: stixGraphActions.RESET });
  }, []);

  return (
    <AppErrorBoundary>
      <div id="stix-graph-view" className="full-height">
        <GraphPageHeader docId={documentId} />
        <GraphPane docId={documentId} />
      </div>
    </AppErrorBoundary>
  );
};

export default STIXGraph;
