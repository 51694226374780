import { batch } from "react-redux";
import {
  ACCEPT_ACCROSS_DOCUMENT,
  ACCEPT_ALWAYS,
  REJECT_ACCROSS_DOCUMENT,
  REJECT_ALWAYS,
} from "../../../constants/index.js";
import {
  acceptEntityAccrossDocRequest,
  acceptEntityAlwaysRequest,
  rejectEntityAccrossDocRequest,
  rejectEntityAlwaysRequest,
} from "../../../store/actions/apiRequests.js";
import {
  addProcessingEntities,
  removeActiveSelection,
} from "../../../store/actions/index.js";

export const handleAcceptRejectEntityMenu =
  (entityId, documentId, dispatch) =>
  ({ key, domEvent }) => {
    domEvent.stopPropagation();

    let requestAction;
    switch (key) {
      case ACCEPT_ACCROSS_DOCUMENT:
        requestAction = acceptEntityAccrossDocRequest(documentId, entityId);
        break;

      case ACCEPT_ALWAYS:
        requestAction = acceptEntityAlwaysRequest(documentId, entityId);
        break;

      case REJECT_ACCROSS_DOCUMENT:
        requestAction = rejectEntityAccrossDocRequest(documentId, entityId);
        break;

      case REJECT_ALWAYS:
        requestAction = rejectEntityAlwaysRequest(documentId, entityId);
        break;

      default:
        return;
    }

    batch(() => {
      dispatch(requestAction);
      dispatch(addProcessingEntities({ [entityId]: true }));
      dispatch(removeActiveSelection());
    });
  };
