/* STIX V2.0 Specification-Defined Relationships Summary */
/* Source: http://docs.oasis-open.org/cti/stix/v2.0/cs01/part2-stix-objects/stix-v2.0-cs01-part2-stix-objects.html#_Toc496714339 */

export const sources = [
  "attack-pattern",
  "campaign",
  "course-of-action",
  "indicator",
  "intrusion-set",
  "malware",
  "threat-actor",
  "tool",
];
export const targets = [
  "vulnerability",
  "identity",
  "malware",
  "tool",
  "intrusion-set",
  "threat-actor",
  "attack-pattern",
  "campaign",
];
export const relationshipTypes = [
  "targets",
  "uses",
  "attributed-to",
  "mitigates",
  "indicates",
  "variant-of",
  "impersonates",
];
export const sourceTargetRelationTypes = {
  "attack-pattern:-:vulnerability": ["targets"],
  "attack-pattern:-:identity": ["targets"],
  "attack-pattern:-:malware": ["uses"],
  "attack-pattern:-:tool": ["uses"],
  "campaign:-:intrusion-set": ["attributed-to"],
  "campaign:-:threat-actor": ["attributed-to"],
  "campaign:-:identity": ["targets"],
  "campaign:-:vulnerability": ["targets"],
  "campaign:-:attack-pattern": ["uses"],
  "campaign:-:malware": ["uses"],
  "campaign:-:tool": ["uses"],
  "course-of-action:-:attack-pattern": ["mitigates"],
  "course-of-action:-:malware": ["mitigates"],
  "course-of-action:-:tool": ["mitigates"],
  "course-of-action:-:vulnerability": ["mitigates"],
  "indicator:-:attack-pattern": ["indicates"],
  "indicator:-:campaign": ["indicates"],
  "indicator:-:intrusion-set": ["indicates"],
  "indicator:-:malware": ["indicates"],
  "indicator:-:threat-actor": ["indicates"],
  "indicator:-:tool": ["indicates"],
  "intrusion-set:-:threat-actor": ["attributed-to"],
  "intrusion-set:-:identity": ["targets"],
  "intrusion-set:-:vulnerability": ["targets"],
  "intrusion-set:-:attack-pattern": ["uses"],
  "intrusion-set:-:malware": ["uses"],
  "intrusion-set:-:tool": ["uses"],
  "malware:-:identity": ["targets"],
  "malware:-:vulnerability": ["targets"],
  "malware:-:tool": ["uses"],
  "malware:-:malware": ["variant-of"],
  "threat-actor:-:identity": ["attributed-to", "impersonates", "targets"],
  "threat-actor:-:vulnerability": ["targets"],
  "threat-actor:-:attack-pattern": ["uses"],
  "threat-actor:-:malware": ["uses"],
  "threat-actor:-:tool": ["uses"],
  "tool:-:identity": ["targets"],
  "tool:-:vulnerability": ["targets"],
};

const stix2_0 = {
  sources: new Set(sources),
  targets: new Set(targets),
  relationshipTypes,
  sourceTargetRelationTypes,
  version: "2.0",
  url: "http://docs.oasis-open.org/cti/stix/v2.0/cs01/part2-stix-objects/stix-v2.0-cs01-part2-stix-objects.html#_Toc496714339",
};

export default stix2_0;
