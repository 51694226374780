import SpinLoader from "./SpinLoader";

const CenterSpinLoader = ({ loading = false }) => {
  return loading ? (
    <div className="position-center">
      <SpinLoader />
    </div>
  ) : null;
};

export default CenterSpinLoader;
