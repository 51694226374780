import { Component } from "react";
import SomethingWentWrong from "../components/pages/error/SomethingWentWrong";

export default class AppErrorBoundary extends Component {
  state = {
    error: "",
    errorInfo: "",
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // console.log({ error });
    // this.setState({ errorInfo });
  }

  render() {
    const { status, message, action } = this.props;
    if (this.state.hasError)
      return (
        <SomethingWentWrong status={status} message={message} extra={action} />
      );

    return this.props.children;
  }
}
