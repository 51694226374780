import { useLayoutEffect, useRef, useState } from "react";
import { init } from "stixview";
import { Menu, Dropdown, Button, Switch, Radio } from "antd";
import { DownOutlined, DownloadOutlined } from "@ant-design/icons";
import Mixpanel from "../../../utils/services/Mixpanel";
import events from "../../../constants/analyticEvents";
import { exportStixGraphImage, runGraphLayout } from "../stixGraph/helpers";

const layouts = ["cola", "klay", "cose-bilkent", "cise", "grid", "dagre"];

const CollectionGraphPane = ({ bundle, collection = {} }) => {
  const [exportType, setExportType] = useState("jpg");
  const [graphLoading, setGraphLoading] = useState(false);
  const [layout, setLayout] = useState(layouts[0]);
  const [showLabels, setShowLabels] = useState(true);
  const [showTlpAsTags, setShowTlpAsTags] = useState(false);

  const graphHolderRef = useRef(null);
  const graphRef = useRef(null);
  const wrapperRef = useRef();
  const cy = graphRef.current?.cy;
  let graphWidth;
  let graphHeight;
  if (cy) {
    graphWidth = cy.width();
    graphHeight = cy.height();
  }

  const analyticEventProps = {
    collectionId: collection.id,
    collectionTitle: collection.title,
    collectedDocuments: collection.collected_documents,
    graphLayout: layout,
    graphWidth,
    graphHeight,
  };

  useLayoutEffect(() => {
    try {
      let wrapperHeight = wrapperRef.current.offsetHeight - 50;
      if (wrapperHeight < 600) wrapperHeight = 600;
      graphRef.current = init(
        graphHolderRef.current,
        null,
        () => {
          setGraphLoading(false);
          Mixpanel.track(events.STIX_GRAPH_LOADED, analyticEventProps);
        },
        {}, // no additional data properties
        {
          graphHeight: wrapperHeight,
          hideFooter: true,
          showSidebar: true,
          onClickNode: function (node) {},
        }
      );
    } catch (error) {}
  }, []);

  useLayoutEffect(() => {
    try {
      if (graphRef.current && bundle) {
        setGraphLoading(true);
        setTimeout(() => graphRef.current.loadData(bundle), 100);
      }
    } catch (error) {
      setGraphLoading(false);
      console.error(error);
    }
  }, [bundle]);

  const menu = (
    <Menu
      onClick={runGraphLayout(
        graphRef.current,
        layout,
        analyticEventProps,
        setLayout
      )}
    >
      {layouts.map((l) => (
        <Menu.Item key={l}>{l.toUpperCase()}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div ref={wrapperRef} className="b-white full-height position-relative">
      <div className="justify-between">
        <div className="px-10 py-10 align-center">
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button type="text">
              {"Layout >>"} {layout.toUpperCase()} <DownOutlined />
            </Button>
          </Dropdown>
          <div className="py-5 px-10">
            <span> Labels </span>
            <Switch
              className="mb-2"
              size="small"
              checked={showLabels}
              onChange={(checked) => {
                setShowLabels(checked);
                graphRef.current.toggleLabels(checked);
              }}
            />
          </div>
          <div className="py-5 px-10">
            <span> TLP </span>
            <Switch
              className="mb-2"
              size="small"
              checked={showTlpAsTags}
              onChange={(checked) => {
                setShowTlpAsTags(checked);
                setGraphLoading(true);
                graphRef.current.reloadData({ showTlpAsTags: checked });
              }}
            />
          </div>
        </div>
        <div className="px-10 py-10 align-center">
          <Dropdown
            overlay={
              <Menu
                onClick={exportStixGraphImage(cy, exportType, collection.title)}
              >
                <Menu.Item key="full">Full Graph</Menu.Item>
                <Menu.Item key="current">Current View</Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <Button type="text">
              Export <DownloadOutlined />
            </Button>
          </Dropdown>
          <Radio.Group
            value={exportType}
            onChange={(e) => setExportType(e.target.value)}
          >
            <Radio value="png">PNG</Radio>
            <Radio value="jpg">JPG</Radio>
          </Radio.Group>
        </div>
      </div>
      <div
        className="bd-0 mt-0 pt-0 b-white"
        ref={graphHolderRef}
        data-stix-allow-dragdrop="false"
        data-show-idrefs="false"
        data-show-marking-nodes="false"
        data-show-sidebar="true"
        data-graph-layout={layout}
        data-enable-mouse-zoom="true"
        data-show-tlp-as-tags={showTlpAsTags}
        data-show-labels={showLabels}
        data-show-footer="false"
      ></div>
      {graphLoading && (
        <h3 className="position-center" style={{ top: "150px" }}>
          Graph Loading...
        </h3>
      )}
    </div>
  );
};

export default CollectionGraphPane;
