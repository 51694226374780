import {
  generateApiActionTypes,
  initializeState,
} from "../../utils/helpers/reduxStore";
import actions from "../actions/documentMetadata";

const actionTypes = {
  [actions.GET_KEYS]: "getKeys",
  [actions.GET_SINGLE_KEY]: "getSingleKey",
  [actions.ADD_KEYS]: "addKeys",
  [actions.UPDATE_KEYS]: "updateKeys",
  [actions.DELETE_ALL_KEYS]: "deleteAllKeys",
  [actions.DELETE_SINGLE_KEY]: "deleteSingleKey",
  [actions.SET_PROFILE]: "setProfile",
  [actions.DELETE_PROFILE]: "deleteProfile",
};

const apiActionTypes = generateApiActionTypes(actionTypes);
const apiInit = initializeState(actionTypes);
const initialState = {
  ...apiInit,
  keys: [],
};

const documentMetadata = (state = initialState, action = {}) => {
  const typeState = apiActionTypes[action.type];

  if (typeState)
    return {
      ...state,
      [typeState]: action.payload,
    };

  switch (action.type) {
    case actions.METADATA_KEYS:
      return {
        ...state,
        keys: action.payload,
      };

    case actions.RESET_API_STATE:
      return {
        ...state,
        ...apiInit,
      };

    case actions.RESET:
      return initialState;

    default:
      return state;
  }
};

export default documentMetadata;
