import { useState } from "react";
import EntityList from "./EntityList";
import SelectInput from "../../common/forms/SelectInput";
import { entityRole as er } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { completeRelationshipProcess } from "../../../utils/helpers/analysis/relationship";
import { useReload } from "../../../hooks/useReload";

const { SOURCE, TARGET } = er;

/**
 * SecondEntityForRelationship
 *
 * @returns React JSX
 */
const SecondEntityForRelationship = ({ entityIds, handleCancle }) => {
  const [selectedEntityId, setSelectedEntityId] = useState(null);

  const dispatch = useDispatch();
  const { relationship, analysis, analysisApi } = useSelector((state) => state);
  useReload(analysisApi.addRelationship.result, handleCancle);
  const secondEntityRole =
    relationship.newRelation.role === SOURCE ? TARGET : SOURCE;
  const secondEntity = analysis.entitiesByIds[selectedEntityId];

  return (
    <>
      <div className="section-card mb-30">
        <SelectInput
          value={secondEntityRole}
          options={[
            { value: SOURCE, label: "Source" },
            { value: TARGET, label: "Target" },
          ]}
          disabled
        />
      </div>
      <div className="section-card mb-30">
        <div className="section-card-header">
          <em>
            Choose the second entity for the new relationship in progress:{" "}
          </em>
        </div>
        <div className="section-card-body">
          <EntityList
            entityIds={entityIds}
            canSelect={true}
            canSelectMultiple={false}
            selected={selectedEntityId}
            setSelected={setSelectedEntityId}
          />
        </div>
      </div>
      <div className="justify-end">
        {handleCancle && <Button onClick={handleCancle}>Abort</Button>}
        <Button
          type="primary"
          className="ml-10"
          onClick={() =>
            completeRelationshipProcess(
              secondEntity,
              analysis.document.id,
              relationship.newRelation,
              dispatch
            )
          }
          disabled={!selectedEntityId || !secondEntityRole}
          loading={analysisApi.addRelationship.loading}
        >
          Finish
        </Button>
      </div>
    </>
  );
};

export default SecondEntityForRelationship;
