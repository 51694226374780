import { Tabs } from "antd";
import {
  ImportOutlined,
  FileAddOutlined,
  Html5Outlined,
  MessageOutlined,
} from "@ant-design/icons";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import URLImport from "./URLImport";
import FileImport from "./FileImport";
import TextImport from "./TextImport";
import { useDocumentsReloadInterval } from "../../../hooks/useReload";
import { documentStatus } from "../../../constants";

const { COMPLETED, REVIEWING, FOR_REVIEW, QUEUE } = documentStatus;

const { TabPane } = Tabs;

const URL_PANE = "URL";
const TEXT_PANE = "Text";
const FILE_PANE = "Files";

const setTabTitle = (title, Icon) => (
  <>
    <Icon />
    {title}
  </>
);

const ImportTabs = () => {
  useDocumentsReloadInterval([QUEUE, FOR_REVIEW, REVIEWING, COMPLETED]);

  return (
    <OverlayScrollbarsComponent
      options={{ scrollbars: { autoHide: "leave", autoHideDelay: 200 } }}
      className="import-pane"
    >
      <h2>
        <ImportOutlined /> <b className="pane-title"> Import </b>
      </h2>
      <Tabs defaultActiveKey={URL_PANE} className="mr-10">
        <TabPane tab={setTabTitle(URL_PANE, Html5Outlined)} key={URL_PANE}>
          <URLImport />
        </TabPane>
        <TabPane tab={setTabTitle(TEXT_PANE, MessageOutlined)} key={TEXT_PANE}>
          <TextImport />
        </TabPane>
        <TabPane tab={setTabTitle(FILE_PANE, FileAddOutlined)} key={FILE_PANE}>
          <FileImport />
        </TabPane>
      </Tabs>
    </OverlayScrollbarsComponent>
  );
};

export default ImportTabs;
