import { useState } from "react";
import { Divider } from "antd";
import "../../../assets/styles/documents.scss";
import ColumnsPane from "./ColumnsPane";
import ImportPane from "./ImportPane";
import { DocumentProvider } from "../../../contexts/DocumentContext";
import QueuePane from "./QueuePane";
import { documentFilters, documentStatus } from "../../../constants";
import { SearchInput } from "./SearchInput";
import { Filters } from "./Filters";

const defaultDocs = {
  [documentStatus.FOR_REVIEW]: [],
  [documentStatus.REVIEWING]: [],
  [documentStatus.COMPLETED]: [],
  [documentStatus.QUEUE]: [],
  [documentFilters.SEARCH]: "",
  [documentFilters.IMPORTED_BY]: "",
  [documentFilters.COLLECTION]: "",
  [documentFilters.ACCESS]: "",
};

/**
 * Documents page - renders import, queue and columns pane
 *
 * @function
 *
 * @returns React JSX
 */
const Documents = () => {
  const [documentData, setDocumentData] = useState(defaultDocs);

  return (
    <div id="documents-board">
      <DocumentProvider
        value={{
          documentData,
          setDocumentData,
        }}
      >
        <div className="left-panel">
          <ImportPane />
          <Divider />
          <QueuePane />
        </div>
        <Divider type="vertical" />
        <div className="right-panel">
          <div className="search-and-filters">
            <Filters />
            <SearchInput />
          </div>
          <ColumnsPane />
        </div>
      </DocumentProvider>
    </div>
  );
};

export default Documents;
