import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import attackTechniques from "../../../../data/attack_tids_v15_and_disarm.json";
import {
  makeVEPrefix,
  sendNewEntityRequest,
  virtualEntityFromNewPayload,
} from "../../../../utils/helpers/analysis/entities";
import { ATTACK_PATTERN, entityScope } from "../../../../constants";
import SelectTID from "./SelectTID";

/**
 * Sentence annotation menu
 *
 * @returns React JSX
 */
const SentenceAnnotationMenu = () => {
  const dispatch = useDispatch();

  // Local state
  const [selectedTechnique, setSelectedTechnique] = useState("");

  // App state
  const { analysis, selection } = useSelector((state) => state);
  const { activeSelection, virtualEntities } = selection;

  const [, sentenceIndex, blockIndex] = activeSelection.blockId.split("-");
  const vePrefix = makeVEPrefix(entityScope.BLOCK, blockIndex);

  /* Handles sentence level annotation */
  const handleSentenceAnnotation = () => {
    const payload = {
      name: attackTechniques[selectedTechnique].name,
      type: ATTACK_PATTERN,
      sentence_idx: +sentenceIndex,
      sub_properties: {
        id: selectedTechnique,
        tid: selectedTechnique,
        name: attackTechniques[selectedTechnique].name,
      },
    };

    const virtualEntity = virtualEntityFromNewPayload(payload, vePrefix);

    sendNewEntityRequest(
      payload,
      analysis.document.id,
      virtualEntity,
      virtualEntities,
      vePrefix,
      dispatch
    );
  };

  return (
    <SelectTID
      selectedTID={selectedTechnique}
      setSelectedTID={setSelectedTechnique}
      requestHandler={handleSentenceAnnotation}
      showSendButton={true}
    />
  );
};

export default SentenceAnnotationMenu;
