import { Button } from "antd";
import { useDispatch } from "react-redux";
import { showSlideInView } from "../../../../store/actions";
import infoMessages from "../../../../utils/messages/infoMessages";
import DocumentMetadata from "../../metadata/DocumentMetadata";

const MetadataWidget = ({ docId }) => {
  const dispatch = useDispatch();

  return (
    <Button
      key="metadata-keys"
      className="title-btn"
      size="small"
      type="primary"
      onClick={() =>
        dispatch(
          showSlideInView(
            DocumentMetadata,
            { documentId: docId },
            { title: "Document Metadata", width: 500 }
          )
        )
      }
    >
      {infoMessages.metadata}
    </Button>
  );
};

export default MetadataWidget;
