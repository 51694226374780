import {
  GROUP_CONTAINER_LIST,
  GROUP_CONTAINER_P,
  TEXT_PARENT,
  TEXT_SELECTION_MARKER,
} from "../../constants";
import { selectionError } from "../../constants/error";

/**
 * TextSelection class
 *
 * @class
 *
 * Defines instance methods for setting and getting selection properties
 * useful for rendering selected and annotated texts
 */
class TextSelection {
  constructor() {
    this.activeSelection = {};
    this._textContents = [];
    this._blockId = "";
    this._last = null;
  }

  /**
   * getProps - Returns active selection range properties such as
   * selected text, range start and end indices.
   *
   * @method
   *
   * @returns active selection properties
   */
  getProps() {
    const props = {
      selectedText: this._selectedText,
      blockId: this._blockId,
      isClick: this._isClick,

      absoluteStart: this._absoluteStart,
      absoluteEnd: this._absoluteEnd,
    };

    return props;
  }

  /**
   * setProps - Set active selection range properties such as
   * selected text, range start and end indices.
   *
   * @method
   *
   * @param {object} commonParent the DOM parent element for text nodes
   */
  setProps(commonParent) {
    const blockId = commonParent.parentElement.id;

    const isDifferentSentence = this._blockId !== blockId;
    if (isDifferentSentence) {
      this._last = null;
      this._blockId = blockId;
      Array.from(commonParent.children).forEach((child) =>
        this._textContents.push(child.textContent)
      );
    }

    const range = this._getDOMRange();

    // True if it's just a click and no text selected
    this._isClick = range.collapsed;

    if (!this._isClick && (!this._selectedText || !this._selectedText.trim())) {
      throw new Error("No text selected");
    }

    // Parent element of selected text node(s)
    const commonAncestor = range.commonAncestorContainer;
    const element = range.commonAncestorContainer.parentElement;

    // Set index used to specify the element for single node selection
    if (element.dataset.type.startsWith(TEXT_PARENT)) {
      this._nodeIndex = element.dataset.index;
    } else if (element.id === TEXT_SELECTION_MARKER) {
      this._nodeIndex = element.parentElement.dataset.index;
    } else if (commonAncestor.nodeType === Node.ELEMENT_NODE) {
      if (commonAncestor.dataset.type === GROUP_CONTAINER_P) {
        throw new Error(selectionError.MULTI_SENTENCE_SELECTION);
      }
      if (commonAncestor.dataset.type === GROUP_CONTAINER_LIST) {
        throw new Error(selectionError.MULTI_LIST_SELECTION);
      }
    } else {
      throw new Error(selectionError.INVALID_SELECTION);
    }

    // Start index of selection relative to a node text
    this._relativeRangeStart = range.startOffset;

    // End index of selection relative to a node text
    this._relativeRangeEnd = range.endOffset;

    // Start and end range elements
    const startContainer = range.startContainer;
    const startElement = range.startContainer.parentElement;
    const startPrevSibling = startContainer.previousElementSibling;

    const startMeta = JSON.parse(startElement.dataset.meta || "{}");
    const prevStartMeta =
      !!startPrevSibling && startPrevSibling.dataset.meta
        ? JSON.parse(startPrevSibling.dataset.meta)
        : {};

    // Start index of selection relative to a node text
    this._absoluteStart =
      range.startOffset + (prevStartMeta.end || startMeta.start); // USED

    // End index of selection relative to a node text
    this._absoluteEnd = this._absoluteStart + this._selectedText.length;

    // Detect selection over non-sentence entity
    if (!this._isClick && startMeta.type) {
      // Don't allow nested selection within non-sentence entity
      if (this._absoluteEnd < startMeta.end) {
        throw new Error(selectionError.NESTED_NOT_SUPPORTED);
      }

      // Don't allow interset selection accross existing entity
      if (this._absoluteEnd > startMeta.end) {
        throw new Error(selectionError.INTERSET_NOT_SUPPORTED);
      }

      // Don't allow overlay selection on non-sentence entity
      if (
        this._absoluteStart === startMeta.start &&
        this._absoluteEnd === startMeta.end
      ) {
        return;
      }
    }

    this._last = this.getProps();
  }

  /**
   * _getDOMRange - Returns DOM active range object
   *
   * @method
   *
   * @returns DOM range object
   */
  _getDOMRange() {
    const selection = window.getSelection(); // get active selection object
    this._selectedText = selection.toString().trim(); // set selected text
    const range = selection.getRangeAt(0).cloneRange(); // get selection range

    return range;
  }
}

export default TextSelection;
