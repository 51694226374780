import { Divider, Tag } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import UserAvatar from "../../common/widgets/UserAvatar";
import { documentColumns, documentStatus } from "../../../constants";
import useAxios from "../../../hooks/useAxios";
import api from "../../../constants/api";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useMessageBoxError } from "../../../hooks/useMessageBox";

const { COMPLETED, FOR_REVIEW, QUEUE, REVIEWING } = documentStatus;

const SummaryPane = () => {
  const { organizationId = "" } = useSelector(({ user }) => user.details);

  const [{ result, error }, documentsCountsRequest] = useAxios({});

  useEffect(() => documentsCountsRequest(api.DOCUMENTS_COUNTS_ORG), []);
  useMessageBoxError(error);

  const { completed, in_review, for_review, queued } = result.data;

  return (
    <>
      <OverlayScrollbarsComponent
        options={{ scrollbars: { autoHide: "l", autoHideDelay: 200 } }}
        className="summary-pane app-card"
      >
        <div className="app-card-header"></div>
        <div className="app-card-body flex-column align-center">
          <div className="section-card">
            <div className="section-card-body flex-column justify-center align-center py-30 bd-0">
              <div className="organisation-logo">
                <UserAvatar
                  name={organizationId}
                  size={150}
                  shape="square"
                  icon={undefined}
                  style={{ fontSize: "96px" }}
                />
              </div>
              <div className="organisation-details">
                <div className="organisation-details-name">
                  {organizationId.toUpperCase()}
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <div className="section-card">
            <div className="section-card-body justify-center align-center py-30 bd-0">
              <div className="organisation-report-stats">
                <h3>Summary</h3>
                <ul>
                  <li>
                    Completed{" "}
                    <Tag
                      icon={<FileTextOutlined />}
                      color={documentColumns[COMPLETED].color}
                    >
                      {completed}
                    </Tag>
                  </li>
                  <li>
                    In review{" "}
                    <Tag
                      icon={<FileTextOutlined />}
                      color={documentColumns[REVIEWING].color}
                    >
                      {in_review}
                    </Tag>
                  </li>
                  <li>
                    For review{" "}
                    <Tag
                      icon={<FileTextOutlined />}
                      color={documentColumns[FOR_REVIEW].color}
                    >
                      {for_review}
                    </Tag>
                  </li>
                  <li>
                    Queued{" "}
                    <Tag
                      icon={<FileTextOutlined />}
                      color={documentColumns[QUEUE].color}
                    >
                      {queued}
                    </Tag>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </OverlayScrollbarsComponent>
    </>
  );
};

export default SummaryPane;
