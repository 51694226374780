import { takeEvery } from "redux-saga/effects";
import actions from "../actions/apiRequests";
import {
  acceptEntityAndCreateRule,
  rejectEntityAndCreateRule,
} from "./workers";

export function* entityWatcher() {
  yield takeEvery(actions.ACCEPT_ENTITY_ALWAYS, acceptEntityAndCreateRule);
  yield takeEvery(actions.REJECT_ENTITY_ALWAYS, rejectEntityAndCreateRule);
}
