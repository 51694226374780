import axios from "axios";

/**
 * Creates and return axios instance
 * for HTTP API request
 *
 * @returns { instance } an instance of axios
 */
const axiosInstance = (config = {}) => {
  const { token = "", contentType, handleProgress } = config;
  const baseURL = window._env_.BACKEND_URL;

  return axios.create({
    baseURL,
    headers: {
      "Content-Type": contentType || "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
    onUploadProgress: function (progressEvent) {
      if (handleProgress) handleProgress(progressEvent);
    },
    onDownloadProgress: function (progressEvent) {
      if (handleProgress) handleProgress(progressEvent);
    },

    ...config,
  });
};

export default axiosInstance;
