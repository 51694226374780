import { Collapse, Card } from "antd";
import StatusBar from "./StatusBar";
import { entityProperties } from "../../../data/entities";
import { useState } from "react";

const ReportCard = ({ title, reports = [], extraInfo = null, children }) => {
  const [activePanels, setActivePanels] = useState([]);
  const total = reports.reduce((prev, cur) => cur.count + prev, 0);

  return (
    <div className="performance-report-card">
      <Card title={title} extra={extraInfo}>
        <Collapse
          expandIcon={() => null}
          onChange={(v) => setActivePanels(v)}
          ghost
        >
          {children}
          {!children &&
            reports.map((r, i) => {
              return (
                <Collapse.Panel
                  key={i}
                  header={
                    <StatusBar
                      strokeColor="blue"
                      label={r.label}
                      count={r.count}
                      total={total}
                      isOpen={activePanels.includes(`${i}`)}
                    />
                  }
                >
                  {r.items.map(({ type, count }) => (
                    <StatusBar
                      key={type}
                      label={entityProperties[type]?.label || type}
                      count={count}
                      total={r.count}
                      style={{ marginBottom: "10px" }}
                    />
                  ))}
                </Collapse.Panel>
              );
            })}
        </Collapse>
      </Card>
    </div>
  );
};

export default ReportCard;
