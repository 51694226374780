import { Fragment } from "react";
import { List, Collapse, Checkbox, Tag } from "antd";
import {
  CaretRightOutlined,
  CaretDownOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { collapseIcon, getConfidenceScore } from "../../../../utils/helpers";
import { entityStatus, entityClass } from "../../../../constants";
import ConfirmSelection from "./ConfirmSelection";
import { useAnalysisContext } from "../../../../contexts/AnalysisContext";
import GroupOptions from "./GroupOptions";
import { shouldNotDrawLines } from "../../../../store/actions";
import { useDispatch } from "react-redux";
import { handleScrollToEntity } from "../../../../utils/helpers/analysis";
import Annotation from "../../../../utils/services/Annotation";
import DisplayTip from "../../../common/widgets/DisplayTip";

const { WAITING } = entityStatus;
const tagFontStyle = {
  color: "#333",
  fontSize: "11px",
};
const confidenceScoreTagStyle = {
  ...tagFontStyle,
  color: "#6558f5",
};

const ConfidenceScoreTag = ({ score }) => {
  return score.text ? (
    <Tag>
      <span style={confidenceScoreTagStyle}>{score.text}</span>
    </Tag>
  ) : null;
};
/**
 * Renders nested grouped entities
 *
 * @function
 * @param {string} groupStatus - The entity status group 'accepted' | 'rejected' | 'waiting'
 * @returns React JSX
 */
const EntityGroups = ({
  groupStatus,
  selection,
  totalEntities,
  handleSelection,
}) => {
  const {
    entityGroups,
    entitiesByIds,
    entityProps,
    document: doc,
    blocks,
    tables,
  } = useSelector(({ analysis }) => analysis);

  const { initialLoading, scrollToIndex, groupIndexRef } = useAnalysisContext();

  const dispatch = useDispatch();

  const selSet = new Set(selection);

  let dataSource = entityGroups[groupStatus]
    ? Object.values(entityGroups[groupStatus])
    : [];

  return (
    <Fragment>
      {groupStatus === WAITING && (
        <ConfirmSelection
          groupStatus={groupStatus}
          selection={selection}
          totalEntities={totalEntities}
          documentId={doc.id}
          initialLoading={initialLoading}
          handleSelection={handleSelection}
        />
      )}
      <List
        size="small"
        dataSource={dataSource}
        renderItem={(entities, entityIndex) => {
          const entityType = entitiesByIds[entities.ids[0]].type;
          const textEntries = Object.entries(entities.text);
          const selectAllType = entities.ids.every((id) => selSet.has(id));

          const entityProp = entityProps[entityType];
          const tagColor = entityProp ? entityProp.color : "";
          return (
            <List.Item>
              <Collapse
                expandIcon={collapseIcon(CaretRightOutlined, CaretDownOutlined)}
                ghost
              >
                <Collapse.Panel
                  className="entity-group-collapse-item"
                  header={
                    <div className="technique-item">
                      <span className="technique-item-name">
                        {groupStatus === WAITING && (
                          <Checkbox
                            indeterminate={selectAllType}
                            checked={selectAllType}
                            onClick={(e) => e.stopPropagation()}
                            onChange={handleSelection([
                              groupStatus,
                              entityType,
                            ])}
                          />
                        )}
                        <span style={{ marginLeft: "7px" }}>
                          {entityProp ? entityProp.label : entityType}
                        </span>
                      </span>
                      <div>
                        <span>
                          <Tag style={{ opacity: "0.8" }} color={tagColor}>
                            <span style={tagFontStyle}>
                              {entities.ids.length}
                            </span>
                          </Tag>
                          {groupStatus === WAITING && (
                            <GroupOptions
                              entityIds={entities.ids}
                              groupStatus={groupStatus}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  }
                  key={entityIndex + 1}
                >
                  <List
                    size="small"
                    dataSource={textEntries}
                    renderItem={([entityText, ids], idIndex) => {
                      const selectAllText = ids.every((id) => selSet.has(id));
                      return (
                        <List.Item>
                          <Collapse
                            expandIcon={collapseIcon(
                              PlusSquareOutlined,
                              MinusSquareOutlined
                            )}
                            ghost
                          >
                            <Collapse.Panel
                              className="entity-group-collapse-item"
                              header={
                                <div className="technique-item">
                                  <span className="technique-item-name">
                                    {groupStatus === WAITING && (
                                      <Checkbox
                                        indeterminate={selectAllText}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={handleSelection([
                                          groupStatus,
                                          entityType,
                                          entityText,
                                        ])}
                                      />
                                    )}
                                    <span style={{ marginLeft: "7px" }}>
                                      {entityText}
                                    </span>
                                  </span>
                                  <span className="justify-end align-items">
                                    <Tag
                                      style={{ opacity: "0.7" }}
                                      color={tagColor}
                                    >
                                      <span style={tagFontStyle}>
                                        {ids.length}
                                      </span>
                                    </Tag>
                                    {groupStatus === WAITING && (
                                      <GroupOptions
                                        entityIds={ids}
                                        groupStatus={groupStatus}
                                      />
                                    )}
                                  </span>
                                </div>
                              }
                              key={idIndex + 1}
                            >
                              {groupStatus === WAITING && (
                                <span className="justify-end">
                                  Confidence Score
                                </span>
                              )}
                              <List
                                size="small"
                                dataSource={ids}
                                renderItem={(entityId) => {
                                  const entity = entitiesByIds[entityId];
                                  const subtype =
                                    entity.class === entityClass.SENTENCE
                                      ? entity.text_id
                                      : entity?.sub_properties?.sub_type;

                                  const confidenceScore =
                                    entity.confidence_score;
                                  const domId =
                                    entity.class === entityClass.SENTENCE
                                      ? `${entityClass.SENTENCE}-${entity.sentence_idx}`
                                      : entityId;

                                  let blockText = blocks[entity.block_idx].text;
                                  if (entity.class === entityClass.TABLE) {
                                    const { table_idx, column_idx, row_idx } =
                                      entity;
                                    blockText =
                                      tables[table_idx][row_idx][column_idx];
                                  }

                                  const annotatedText = Annotation.singleNode(
                                    blockText,
                                    entity,
                                    { header: "Click to show entity in report" }
                                  );
                                  const trucatedAnnotatedText =
                                    Annotation.singleNode(blockText, entity, {
                                      truncateBeforeEntityText: true,
                                    });
                                  return (
                                    <List.Item
                                      style={{
                                        width: "100%",
                                        justifyContent: "flex-start",
                                        paddingLeft: "10px",
                                      }}
                                    >
                                      {groupStatus === WAITING && (
                                        <Checkbox
                                          onChange={handleSelection([
                                            groupStatus,
                                            entityType,
                                            entityText,
                                            entityId,
                                          ])}
                                          checked={selSet.has(entityId)}
                                        />
                                      )}
                                      <span
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          width: "100%",
                                        }}
                                      >
                                        <DisplayTip title={annotatedText}>
                                          <span
                                            onClick={() => {
                                              dispatch(shouldNotDrawLines());
                                              handleScrollToEntity(
                                                domId,
                                                entity.group_idx,
                                                scrollToIndex,
                                                groupIndexRef,
                                                dispatch
                                              );
                                            }}
                                            className="text-ellipsis"
                                            style={{
                                              marginLeft: "5px",
                                              padding: "10px 3px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {subtype && (
                                              <span className="subtype-dot">
                                                {subtype}
                                              </span>
                                            )}
                                            {trucatedAnnotatedText}
                                          </span>
                                        </DisplayTip>
                                        {groupStatus === WAITING && (
                                          <ConfidenceScoreTag
                                            score={getConfidenceScore(
                                              confidenceScore
                                            )}
                                          />
                                        )}
                                      </span>
                                    </List.Item>
                                  );
                                }}
                              />
                            </Collapse.Panel>
                          </Collapse>
                        </List.Item>
                      );
                    }}
                  />
                </Collapse.Panel>
              </Collapse>
            </List.Item>
          );
        }}
      />
    </Fragment>
  );
};

export default EntityGroups;
