import { analysisModes } from "../../constants";
import { entityProperties } from "../../data/entities";
import {
  RESET_ANALYIS_STATE,
  SET_ANALYSIS,
  SET_ANALYSIS_MODE,
  SET_ANNOTATION_RELATIONS,
} from "../actions";

const initialState = {
  document: {},
  blocks: [],
  tables: [],
  blockGroups: [],
  entitiesByIds: {},
  tableEntitiesGroups: {},
  entityGroups: {},
  entityProps: entityProperties,
  analysisMode: analysisModes.ANNOTATION,
};

const analysisReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_ANALYSIS:
      return {
        ...state,
        ...action.payload,
      };

    case SET_ANALYSIS_MODE:
      return {
        ...state,
        analysisMode: action.payload,
      };

    case SET_ANNOTATION_RELATIONS:
      return {
        ...state,
        relations: [...state.relations, action.payload],
      };

    case RESET_ANALYIS_STATE:
      return initialState;

    default:
      return state;
  }
};

export default analysisReducer;
