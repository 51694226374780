import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Select } from "antd";
import { useReload } from "../../../hooks/useReload";
import {
  updateRuleRequest,
  createRuleRequest,
  getSingleRuleRequest,
} from "../../../store/actions/manualRules";
import { RULE_TYPES } from "../../../constants";
import { entityProperties } from "../../../data/entities";
import messageBox from "../../../utils/services/MessageBox";

const RuleForm = ({ ruleId }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { getSingleRule: g } = useSelector(({ manualRules }) => manualRules);

  useReload(g.result, () => {
    form.setFieldsValue(g.result?.data);
  });

  useEffect(() => {
    form.resetFields();
    if (!ruleId) return;
    dispatch(getSingleRuleRequest(ruleId));
  }, []);

  const handleSubmit = (fieldValues) => {
    if (ruleId) return dispatch(updateRuleRequest(ruleId, fieldValues));
    dispatch(createRuleRequest(fieldValues));
  };

  const handleValidation = () => {
    messageBox.showError("Please fill all required fields");
  };

  return (
    <Form
      form={form}
      name="rule_form"
      onFinish={handleSubmit}
      onFinishFailed={handleValidation}
      scrollToFirstError={true}
      layout="vertical"
    >
      <Form.Item name="entity_instance" label="Value">
        <Input
          id="entity_instance"
          placeholder="Entity instance"
          className="mb-10"
          required
        />
      </Form.Item>
      <Form.Item name="entity_type" label="Entity type">
        <Select
          id="entity_type"
          placeholder="Entity type"
          className="mb-10"
          showArrow
          required
        >
          {Object.entries(entityProperties).map(([entity, props]) => {
            return <Select.Option key={entity}>{props.label}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item name="rule" label="Rule type">
        <Select
          showArrow
          placeholder="Select a rule type"
          className="mb-10"
          required
        >
          {Object.values(RULE_TYPES).map(({ value, label }) => {
            return <Select.Option key={value}>{label}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input.TextArea
          id="description"
          placeholder="Rule description"
          className="mb-10"
          rows={5}
        />
      </Form.Item>
      <Form.Item>
        <div className="justify-end">
          <Button type="primary" htmlType="submit">
            {ruleId ? "Save" : "Add new rule"}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default RuleForm;
