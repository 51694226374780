import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Divider, Select, Table } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  addOrganisationMetadataKeys,
  addOrganisationMetadataProfile,
} from "../../../store/actions/metadata";
import { METADATA_KEYS, METADATA_PROFILES } from "../../../constants";
import { profileKeysColumns } from "./columns";
import messageBox from "../../../utils/services/MessageBox";

const NewMetadataForm = ({ formType }) => {
  const [profile, setProfile] = useState({
    name: "",
    description: "",
    keys: [],
  });
  const [form] = Form.useForm();

  const { metadata } = useSelector((state) => state);

  const dispatch = useDispatch();

  const handleSubmit = ({ newMetadataKeys = [], ...selectedExistingKeys }) => {
    const isNewKeys = !!newMetadataKeys.length;
    if (formType === METADATA_KEYS) {
      if (!isNewKeys)
        return messageBox.showError("One or more new keys is required");
      return dispatch(addOrganisationMetadataKeys(newMetadataKeys));
    }

    const existingKeys = Object.entries(selectedExistingKeys).map(
      ([key, default_value]) => ({ key, default_value })
    );

    if (formType === METADATA_PROFILES) {
      if (!profile.name || !profile.description)
        return messageBox.showError(
          "Profile name and description are required"
        );
      if (!existingKeys.length && !isNewKeys)
        return messageBox.showError(
          "You must include one or more keys to a profile"
        );

      const payload = {
        ...profile,
        keys: [...existingKeys, ...newMetadataKeys],
      };

      dispatch(addOrganisationMetadataProfile(payload));
    }
  };

  const handleKeysValidation = () => {
    messageBox.showError("Please fill all required fields");
  };

  const handleProfileChange = (e) => {
    setProfile((p) => ({
      ...p,
      [e.target.id]: e.target.value,
    }));
  };

  const addSelectedKey = (key) => {
    setProfile((p) => {
      return {
        ...p,
        keys: [...p.keys, { ...metadata.keysMap[key], unsaved: true }],
      };
    });
  };

  const removeUnsaved = (key) => {
    setProfile((p) => {
      return {
        ...p,
        keys: p.keys.filter((x) => x.key !== key),
      };
    });
  };

  const clearUnsaved = () => {
    setProfile((p) => {
      return {
        ...p,
        keys: p.keys.filter((key) => !key.unsaved),
      };
    });
  };

  const profileKeys = profile.keys || [];

  return (
    <div>
      {formType === METADATA_PROFILES && (
        <>
          <div className="mb-20">
            <Input
              id="name"
              value={profile.name}
              placeholder="Profile name"
              className="mb-10"
              onChange={handleProfileChange}
              required
            />
            <Input.TextArea
              id="description"
              value={profile.description}
              placeholder="Profile description"
              className="mb-10"
              onChange={handleProfileChange}
              required
            />
          </div>
          <Divider />
        </>
      )}
      <Form
        form={form}
        name="metadata_form"
        onFinish={handleSubmit}
        onFinishFailed={handleKeysValidation}
        scrollToFirstError={true}
        layout="vertical"
      >
        {formType === METADATA_PROFILES && (
          <Table
            dataSource={profileKeys}
            columns={profileKeysColumns()}
            pagination={false}
            loading={metadata.deleteProfileKey.loading}
            footer={() => (
              <Form.Item>
                <Select
                  mode="multiple"
                  allowClear
                  showArrow
                  placeholder="Add existing keys"
                  onSelect={(key) => addSelectedKey(key)}
                  onDeselect={(key) => removeUnsaved(key)}
                  onClear={clearUnsaved}
                >
                  {metadata.keys.map(({ key }) => {
                    return !profileKeys.find((k) => k.key === key) ? (
                      <Select.Option key={key}>{key}</Select.Option>
                    ) : null;
                  })}
                </Select>
              </Form.Item>
            )}
          />
        )}
        <Form.List name="newMetadataKeys">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => (
                  <Form.Item required={true} key={field.key}>
                    <Form.Item
                      {...field}
                      key={`key-${index}`}
                      name={[field.name, "key"]}
                      fieldKey={[field.fieldKey, "key"]}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "",
                        },
                      ]}
                      noStyle
                      className="mb-5"
                    >
                      <Input placeholder="Key" />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      key={`default_value-${index}`}
                      name={[field.name, "default_value"]}
                      fieldKey={[field.fieldKey, "default_value"]}
                      noStyle
                      className="mb-5"
                    >
                      <Input placeholder="Default value" />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      key={`description-${index}`}
                      name={[field.name, "description"]}
                      fieldKey={[field.fieldKey, "description"]}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "",
                        },
                      ]}
                      noStyle
                    >
                      <Input.TextArea placeholder="Description" />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => remove(field.name)}
                        icon={<MinusCircleOutlined />}
                        block
                      >
                        Remove fields
                      </Button>
                    </Form.Item>
                    <Divider className="my-0" />
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                    block
                  >
                    Add new key
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
        <Form.Item>
          <Button
            size="small"
            type="primary"
            htmlType="submit"
            shape="round"
            loading={metadata.addProfile.loading || metadata.addKeys.loading}
            block
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewMetadataForm;
