import { useEffect, useState } from "react";
import { Row, Col, DatePicker, Switch } from "antd";
import {
  FileTextOutlined,
  AppstoreOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import qs from "qs";
import moment from "moment";
import TotalStatWidget from "./TotalStatWidget";
import "../../../assets/styles/performance-dashboard.scss";
import ReportCard from "./ReportCard";
import OverviewCard from "./OverviewCard";
import {
  formatDate,
  getAnnotationOptions,
  getConfScoreOptions,
  getTimeSavedChartOptions,
} from "./chartOptions";
import { useDispatch, useSelector } from "react-redux";
import {
  getExtractionPerformanceRequest,
  setExtractionPerformance,
  resetDashboardState,
} from "../../../store/actions/dashboard";
import { useReload } from "../../../hooks/useReload";
import SelectInput from "../../common/forms/SelectInput";
import { getSelectInputProps } from "../../../utils/helpers";
import { formatEstimatedManualTime } from "./helper";
import DisplayTip from "../../common/widgets/DisplayTip";

const activeRealm = window._env_.AUTH_REALM;

export const disabledDate =
  (dateString = "") =>
  (current) => {
    let disabled = false;
    if (current) {
      disabled =
        current < moment(dateString).endOf("day") ||
        current > moment().endOf("day");
    }
    return disabled;
  };

const infoStyle = { width: "170px", color: "#fff" };

const PerformanceDashboard = () => {
  const [queryParams, setQueryParams] = useState({});

  const dispatch = useDispatch();

  const { performance, getExtractionPerformance: gef } = useSelector(
    ({ dashboard }) => dashboard
  );

  useReload(gef.result, () =>
    dispatch(setExtractionPerformance(gef.result.data))
  );

  useEffect(() => {
    dispatch(getExtractionPerformanceRequest(qs.stringify(queryParams)));
    return () => dispatch(resetDashboardState());
  }, [queryParams]);

  const onStartDate = (_, dateString) => {
    setQueryParams((p) => ({ ...p, start_date: dateString }));
  };

  const onEndDate = (_, dateString) => {
    setQueryParams((p) => ({ ...p, end_date: dateString }));
  };

  const onCompletedOnly = (checked) => {
    const document_status = checked ? "completed" : "";
    setQueryParams((p) => ({ ...p, document_status }));
  };

  const {
    summary,
    users,
    realms,
    automated_extraction_durations,
    manual_annotation_durations,
    admin_ream,
    begin_date,
    estimated_manual_annotation_time_by_entity_type: estManualTime,
  } = performance;
  const [prev_start_date, prev_end_date] = performance.prev_date_cycle;
  const compareText = `${formatDate(prev_start_date)} - ${formatDate(
    prev_end_date
  )}`;

  const formatCompareText = (count) => {
    if (count) return `${count} (${compareText})`;
  };

  // const estManualList = formatEstimatedManualTime(estManualTime);
  const estInfo = (
    <div style={infoStyle}>
      Estimated manual annotation time by entity type
      <br />
      <br />
      {formatEstimatedManualTime(estManualTime).map((e, i) => (
        <span key={i}>
          {e} <br />
        </span>
      ))}
    </div>
  );

  return (
    <div id="extraction-performance-dashboard">
      <div className="app-card">
        <h2 className="app-card-header justify-between">
          Performance dashboard
        </h2>
        <div className="app-card-header justify-between">
          <div>
            {activeRealm !== admin_ream && (
              <SelectInput
                {...getSelectInputProps("Users")}
                onChange={(value) =>
                  setQueryParams((p) => ({ ...p, user_id: value }))
                }
                options={[
                  { label: `Users: All`, value: "" },
                  ...users.map(({ id, first_name, last_name }) => ({
                    value: id,
                    label: `${first_name} ${last_name}`,
                  })),
                ]}
              />
            )}
            {activeRealm === admin_ream && (
              <SelectInput
                {...getSelectInputProps("Organisations")}
                onChange={(value) =>
                  setQueryParams((p) => ({ ...p, org_id: value }))
                }
                options={[
                  { label: `Organisations: All`, value: "" },
                  ...realms.map(({ id, display_name }) => ({
                    value: id,
                    label: display_name,
                  })),
                ]}
              />
            )}
            Completed only <Switch size="small" onChange={onCompletedOnly} />
          </div>
          <div>
            <DatePicker
              onChange={onStartDate}
              placeholder={moment(begin_date).format("YYYY-MM-DD")}
              disabledDate={disabledDate(begin_date)}
            />
            {" to "}
            <DatePicker
              onChange={onEndDate}
              placeholder={moment().format("YYYY-MM-DD")}
              disabledDate={disabledDate(begin_date)}
            />
          </div>
        </div>
        <div className="app-card-body">
          <div className="section-card">
            <div className="section-card-body b-app">
              <section className="total-stats-section">
                <TotalStatWidget
                  title="TOTAL REPORT IMPORTED"
                  count={summary.total_reports}
                  compare={formatCompareText(summary.total_reports_compare)}
                  icon={<FileTextOutlined />}
                />
                <TotalStatWidget
                  title="TOTAL ENTITIES EXTRACTED"
                  count={summary.total_entities}
                  compare={formatCompareText(summary.total_entities_compare)}
                  icon={<AppstoreOutlined />}
                />
                <TotalStatWidget
                  title="TOTAL RELATIONSHIP IDENTIFIED"
                  count={summary.total_relationships}
                  compare={formatCompareText(
                    summary.total_relationships_compare
                  )}
                  icon={<ShareAltOutlined />}
                />
              </section>

              <section className="report-section">
                <Row gutter={24}>
                  <Col span={12}>
                    <ReportCard
                      title="CONFIDENCE REPORT"
                      reports={performance.confidence_reports}
                    />
                  </Col>
                  <Col span={12}>
                    <ReportCard
                      title="ANNOTATION REPORT"
                      reports={performance.annotation_reports}
                    />
                  </Col>
                </Row>
              </section>
              <section className="overview-section">
                <Row gutter={24}>
                  <Col span={12}>
                    <OverviewCard
                      title="CONFIDENCE OVERVIEW"
                      data={performance.confidence_overview}
                      dateRanges={performance.date_ranges}
                      optionHandler={getConfScoreOptions}
                    />
                  </Col>
                  <Col span={12}>
                    <OverviewCard
                      title="ANNOTATION OVERVIEW"
                      data={performance.annotation_overview}
                      dateRanges={performance.date_ranges}
                      optionHandler={getAnnotationOptions}
                    />
                  </Col>
                </Row>
              </section>
              <section className="time-saved-section">
                <Row gutter={24}>
                  <Col span={12}>
                    <OverviewCard
                      title="TIME SAVED"
                      data={[
                        manual_annotation_durations,
                        automated_extraction_durations,
                      ]}
                      dateRanges={performance.date_ranges}
                      extraInfo={
                        <DisplayTip
                          overlayClassName={undefined}
                          color={undefined}
                          placement="rightTop"
                          title={estInfo}
                        >
                          <span style={{ borderBottom: "1px dashed #afafaf" }}>
                            How is this calculated?
                          </span>
                        </DisplayTip>
                      }
                      optionHandler={getTimeSavedChartOptions}
                    />
                  </Col>
                </Row>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceDashboard;
