import { entityProperties } from "../../../data/entities";
import { HMSToString, secondsToHMS } from "../../../utils/helpers";

export const formatEstimatedManualTime = (est) => {
  const estManualTimeList = Object.entries(est).map(([entityType, seconds]) => {
    const timeString = HMSToString(secondsToHMS(seconds));
    let entityTypeLabel = entityProperties[entityType].label;
    if (entityType === "attack-pattern") entityTypeLabel = "ATT&CK Pattern";
    return `${entityTypeLabel}: ${timeString}`;
  });

  return estManualTimeList.sort();
};
