import {
  ANALYSIS_TABLE,
  GROUP_CONTAINER_LIST,
  GROUP_CONTAINER_P,
  WRAPPER_PARENT,
} from "../../../constants";
import HTMLGroupConstruct from "./HTMLGroupConstruct";
import HTMLTableConstruct from "./HTMLTableConstruct";

/**
 * HTMLBlock - Maps a block of analysis data to a block of html
 *
 * @function
 *
 * @param {{object}} blockGroup - contains tag and indices of block to be displayed together.
 * @returns React JSX
 */
const HTMLBlock = ({ blockGroup, index }) => {
  const groupProps = { id: `group-${index}` };
  return (
    {
      p: (
        <p {...groupProps} data-type={GROUP_CONTAINER_P}>
          <HTMLGroupConstruct blockGroup={blockGroup} />
        </p>
      ),
      ul: (
        <ul {...groupProps} data-type={GROUP_CONTAINER_LIST}>
          <HTMLGroupConstruct blockGroup={blockGroup} />
        </ul>
      ),
      ol: (
        <ol {...groupProps} data-type={GROUP_CONTAINER_LIST}>
          <HTMLGroupConstruct blockGroup={blockGroup} />
        </ol>
      ),
      li: (
        <ul {...groupProps} data-type={GROUP_CONTAINER_LIST}>
          <HTMLGroupConstruct blockGroup={blockGroup} />
        </ul>
      ),
      quote: (
        <div {...groupProps} data-type={GROUP_CONTAINER_P}>
          <HTMLGroupConstruct blockGroup={blockGroup} />
        </div>
      ),
      table: (
        <div
          {...groupProps}
          data-type={WRAPPER_PARENT}
          className={ANALYSIS_TABLE}
        >
          <HTMLTableConstruct blockGroup={blockGroup} />
        </div>
      ),
      img: (
        <div {...groupProps}>
          <HTMLGroupConstruct blockGroup={blockGroup} />
        </div>
      ),
      h1: (
        <h1 {...groupProps}>
          <HTMLGroupConstruct blockGroup={blockGroup} />
        </h1>
      ),
      h2: (
        <h2 {...groupProps}>
          <HTMLGroupConstruct blockGroup={blockGroup} />
        </h2>
      ),
      h3: (
        <h3 {...groupProps}>
          <HTMLGroupConstruct blockGroup={blockGroup} />
        </h3>
      ),
      h4: (
        <h4 {...groupProps}>
          <HTMLGroupConstruct blockGroup={blockGroup} />
        </h4>
      ),
      h5: (
        <h5 {...groupProps}>
          <HTMLGroupConstruct blockGroup={blockGroup} />
        </h5>
      ),
      h6: (
        <h6 {...groupProps}>
          <HTMLGroupConstruct blockGroup={blockGroup} />
        </h6>
      ),
    }[blockGroup.tag] || (
      <p {...groupProps} data-type={GROUP_CONTAINER_P}>
        <HTMLGroupConstruct blockGroup={blockGroup} />
      </p>
    )
  );
};

export default HTMLBlock;
