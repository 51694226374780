import { Link } from "react-router-dom";
import { Dropdown } from "antd";
import {
  EllipsisOutlined,
  SwapOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { BiUserCircle } from "react-icons/bi";
import { useDrag } from "react-dnd";
import {
  dragItems,
  documentStatus,
  documentAccessLevels,
} from "../../../constants";
import useAxios from "../../../hooks/useAxios";
import { useColumnsReload, useReload } from "../../../hooks/useReload";
import DisplayTip from "../../common/widgets/DisplayTip";
import routes from "../../../constants/routes";
import infoMessages from "../../../utils/messages/infoMessages";
import { useDocumentContext } from "../../../contexts/DocumentContext";
import {
  useMessageBoxError,
  useMessageBoxSuccess,
} from "../../../hooks/useMessageBox";
import successMessages from "../../../utils/messages/successMessages";
import { MoreMenu, MoreMenu1 } from "./DocumentCardActions";

const { org_doc_access_tip, private_doc_access_tip } = infoMessages;
const { COMPLETED } = documentStatus;

const DocumentCard = ({ document, index }) => {
  const { setDocumentData } = useDocumentContext();
  const { document_id, status, access_level } = document;
  const [{ result, error }, updateDoc] = useAxios(null, "PUT");

  useReload(result, () => {
    setDocumentData((prevState) => {
      const newState = { ...prevState };
      newState[status] = newState[status].filter(
        (doc) => doc.document_id !== document_id
      );
      return newState;
    });
  });

  useColumnsReload(result, [status]);

  const errMsg = error && error.details ? error.details.status : null;
  useMessageBoxError(error, errMsg);
  useMessageBoxSuccess(result, successMessages.document_removed);

  const isCompleted = status === COMPLETED;

  const [{ isDragging }, drag] = useDrag({
    item: { type: dragItems.CARD, document, index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag: () => !(isCompleted || document.pending),
  });

  let style = {};
  if (isCompleted) style = { cursor: "default" };
  if (isDragging) style = { cursor: "grabbing" };
  if (document.pending) style["backgroundColor"] = "#efefef";

  const analysisUrl = routes.DOCUMENT_ANALYSIS.replace(
    ":documentId",
    document_id
  );

  return (
    <Link to={analysisUrl} style={{ textDecoration: "none" }}>
      <div
        className="document-card"
        style={style}
        ref={drag}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="document-card-header">
          <div className="document-card-header-info">
            <DisplayTip
              title={
                access_level === documentAccessLevels.ORGANISATION
                  ? org_doc_access_tip
                  : private_doc_access_tip
              }
            >
              {access_level === documentAccessLevels.PRIVATE ? (
                <BiUserCircle />
              ) : (
                ""
              )}
            </DisplayTip>
          </div>
          <div className="document-card-title">
            <DisplayTip title={document.title}>{document.title}</DisplayTip>
          </div>

          <div className="justify-end">
            {isCompleted && (
              <Dropdown
                overlay={<MoreMenu document={document} updateDoc={updateDoc} />}
                trigger={["click"]}
              >
                <EllipsisOutlined style={{ fontSize: "18px", color: "grey" }} />
              </Dropdown>
            )}
            {!isCompleted && (
              <Dropdown
                overlay={
                  <MoreMenu1 document={document} updateDoc={updateDoc} />
                }
                trigger={["click"]}
              >
                <EllipsisOutlined style={{ fontSize: "18px", color: "grey" }} />
              </Dropdown>
            )}
          </div>
        </div>

        <div className="document-card-actions">
          <div className="document-entities-extracted">
            <PlusCircleOutlined style={{ color: "grey" }} />
            <span className="entities-count">Entities extracted </span>
            <span className="entities-count-values">
              {document.entity_count}
            </span>
          </div>
          <div className="document-relatioships">
            <SwapOutlined style={{ color: "grey" }} />
            <span className="entities-count">Relationships identified </span>
            <span className="entities-count-values">
              {document.relationship_count}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default DocumentCard;
