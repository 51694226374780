import { useHistory } from "react-router-dom";
import { Button, PageHeader } from "antd";
import routes from "../../../constants/routes";
import { useDispatch } from "react-redux";
import { showSlideInView } from "../../../store/actions";
import CollectionDocuments from "./CollectionDocuments";
import { getCollectionBundleRequest } from "../../../store/actions/collections";

/**
 * Page header view for collection graph page
 *
 * @function
 * @returns React JSX
 */
const CollectionGraphHeader = ({ collection = {} }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const reloadCallback = () =>
    dispatch(getCollectionBundleRequest(collection.id));

  let showCollectionDocuments = () => {
    dispatch(
      showSlideInView(
        CollectionDocuments,
        { collectionId: collection.id, reloadCallback },
        { title: "Documents in Collection", width: 500 }
      )
    );
  };

  return (
    <div className="collection-graph-header">
      <PageHeader
        className="site-page-header"
        onBack={() => history.push(routes.COLLECTIONS)}
        title={
          <span className="analysis-header-title-text">{collection.title}</span>
        }
        tags={[]}
        extra={[
          <Button
            size="small"
            shape="round"
            key="collection-documents"
            onClick={showCollectionDocuments}
          >
            Collection Documents ({collection.collected_documents})
          </Button>,
        ]}
      />
    </div>
  );
};

export default CollectionGraphHeader;
