import { Tooltip } from "antd";
import "../../../assets/styles/widgets.scss";

const ActionTip = ({ children, ...restProps }) => {
  return (
    <Tooltip
      color="#fafafa"
      overlayClassName="action-tooltip"
      destroyTooltipOnHide={true}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};

export default ActionTip;
