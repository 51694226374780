import { Modal } from "antd";

const AppModal = ({ children, ...restProps }) => {
  return (
    <Modal visible={false} wrapClassName="app-modal-wrapper" {...restProps}>
      {children}
    </Modal>
  );
};

export default AppModal;
