import {
  ADD_SELECTION,
  REMOVE_SELECTION,
  SHOW_SLIDE_IN_VIEW,
  HIDE_SLIDE_IN_VIEW,
  REMOVE_SLIDE_IN_VIEW,
  SHOW_MODAL_VIEW,
  REMOVE_MODAL_VIEW,
  RESET_SELECTION_STATE,
  SET_PROCESSING_ENTITIES,
  ADD_PROCESSING_ENTITIES,
  SET_VIRTUAL_ENTITIES,
  ADD_VIRTUAL_ENTITIES,
  ADD_API_REQUEST_ID,
  SET_API_REQUEST_IDS,
} from "../actions";

const slideInDefault = {
  show: false,
  ContentNode: null,
  props: {},
  slideInProps: {},
};

const modalDefault = {
  show: false,
  ContentNode: null,
  props: {},
  modalProps: {},
};

const initialState = {
  activeSelection: null,
  slideIn: slideInDefault,
  modal: modalDefault,
  processingEntities: {}, // entities that are being processed e.g accepting
  virtualEntities: {}, // Unsaved entities e.g entities to be created
  apiRequestIds: {}, // Request ids to be tracked
};

const selectionReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_SELECTION:
      return {
        ...state,
        activeSelection: action.payload,
      };

    case REMOVE_SELECTION:
      return {
        ...state,
        activeSelection: null,
      };

    case SHOW_SLIDE_IN_VIEW:
      return {
        ...state,
        slideIn: action.payload,
      };

    case REMOVE_SLIDE_IN_VIEW:
      return {
        ...state,
        slideIn: slideInDefault,
      };

    case HIDE_SLIDE_IN_VIEW:
      return {
        ...state,
        slideIn: { ...state.slideIn, show: false },
      };

    case SHOW_MODAL_VIEW:
      return {
        ...state,
        modal: action.payload,
      };

    case REMOVE_MODAL_VIEW:
      return {
        ...state,
        modal: slideInDefault,
      };

    case SET_PROCESSING_ENTITIES:
      return {
        ...state,
        processingEntities: action.payload,
        activeSelection: null,
      };

    case ADD_PROCESSING_ENTITIES:
      return {
        ...state,
        processingEntities: { ...state.processingEntities, ...action.payload },
      };

    case SET_VIRTUAL_ENTITIES:
      return {
        ...state,
        virtualEntities: action.payload,
      };

    case ADD_API_REQUEST_ID:
      return {
        ...state,
        apiRequestIds: { ...state.requestIds, ...action.payload },
      };

    case SET_API_REQUEST_IDS:
      return {
        ...state,
        requestIds: action.payload,
      };

    case ADD_VIRTUAL_ENTITIES:
      return {
        ...state,
        virtualEntities: { ...state.virtualEntities, ...action.payload },
      };

    case RESET_SELECTION_STATE:
      return initialState;

    default:
      return state;
  }
};

export default selectionReducer;
