import { Table } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useReload } from "../../../hooks/useReload";
import { removeSlideInView } from "../../../store/actions";
import {
  getAllCollectionsRequest,
  setCollections,
} from "../../../store/actions/collections";
import { collectionColumns } from "./columns";

const CollectionsTable = () => {
  const dispatch = useDispatch();
  const {
    collectionList,
    getSingleCollection: g,
    getAllCollections: gac,
    updateCollection: u,
    createCollection: c,
    deleteCollection: d,
  } = useSelector(({ collections }) => collections);

  const reloadCollections = () => dispatch(getAllCollectionsRequest());

  useReload(g.result, reloadCollections);
  useReload(u.result, reloadCollections);
  useReload(c.result, reloadCollections);
  useReload(d.result, reloadCollections);
  useReload(gac.result, () => dispatch(setCollections(gac.result.data)));
  useReload(u.result, () => dispatch(removeSlideInView()));
  useReload(c.result, () => dispatch(removeSlideInView()));
  useEffect(() => dispatch(getAllCollectionsRequest()), []);

  return (
    <Table
      rowKey={"id"}
      dataSource={collectionList}
      columns={collectionColumns(dispatch, reloadCollections)}
    />
  );
};

export default CollectionsTable;
