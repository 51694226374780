import { useEffect } from "react";
import { useAuthContext } from "../../../contexts/AuthContext";
import Overlay from "../../common/widgets/Overlay";

const Signup = () => {
  const { auth } = useAuthContext();

  useEffect(() => {
    try {
      if (auth) auth.logout();
    } catch (error) {}
  }, []);

  return (
    <Overlay
      centerContent={true}
      content={<h2 style={{ color: "#ffffff" }}>Loading...</h2>}
    />
  );
};

export default Signup;
