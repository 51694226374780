import {
  generateApiActionTypes,
  initializeState,
} from "../../utils/helpers/reduxStore";
import actions from "../actions/metadata.js";

const actionTypes = {
  [actions.GET_KEYS]: "getKeys",
  [actions.ADD_KEYS]: "addKeys",
  [actions.UPDATE_KEYS]: "updateKeys",
  [actions.DELETE_KEY]: "deleteKey",
  [actions.GET_PROFILES]: "getProfiles",
  [actions.GET_PROFILE_DETAILS]: "getProfileDetails",
  [actions.ADD_PROFILE]: "addProfile",
  [actions.UPDATE_PROFILE]: "updateProfile",
  [actions.DELETE_PROFILE]: "deleteProfile",
  [actions.DELETE_PROFILE_KEY]: "deleteProfileKey",
};

const apiActionTypes = generateApiActionTypes(actionTypes);

const initialState = {
  ...initializeState(actionTypes),
  keys: [],
  profiles: [],
  keysMap: {},
};

const metadata = (state = initialState, action = {}) => {
  const typeState = apiActionTypes[action.type];

  if (typeState)
    return {
      ...state,
      [typeState]: action.payload,
    };

  switch (action.type) {
    case actions.METADATA_KEYS:
      return {
        ...state,
        ...action.payload,
      };

    case actions.METADATA_PROFILES:
      return {
        ...state,
        profiles: action.payload,
      };

    case actions.RESET:
      return initialState;

    default:
      return state;
  }
};

export default metadata;
