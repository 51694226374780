import { Result, Button } from "antd";

const SomethingWentWrong = ({ status, message, extra }) => {
  return (
    <Result
      status={status || "warning"}
      title={message || "Sorry, it looks like something has gone wrong."}
      extra={
        extra || (
          <Button
            type="primary"
            key="console"
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload this page
          </Button>
        )
      }
    />
  );
};

export default SomethingWentWrong;
