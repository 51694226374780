import {
  generateApiActionTypes,
  initializeState,
} from "../../utils/helpers/reduxStore";
import actions from "../actions/dashboard";

const actionTypes = {
  [actions.GET_EXTRACTION_PERFORMANCE_REQUEST]: "getExtractionPerformance",
  [actions.GET_DOCUMENT_PERFORMANCE_OVERVIEW_REQUEST]: "getDocumentOverview",
};

const apiActionTypes = generateApiActionTypes(actionTypes);

const initialState = {
  ...initializeState(actionTypes),
  performance: {
    annotation_overview: [],
    annotation_reports: [],
    confidence_overview: [],
    confidence_reports: [],
    date_ranges: [],
    summary: {},
    prev_date_cycle: ["", ""],
    users: [],
    realms: [],
    automated_extraction_durations: [],
    manual_annotation_durations: [],
    estimated_manual_annotation_time_by_entity_type: {},
  },
  document_overview: {
    annotation_reports: [],
    confidence_reports: [],
    summary: {},
  },
};

const dashboard = (state = initialState, action = {}) => {
  const typeState = apiActionTypes[action.type];

  if (typeState)
    return {
      ...state,
      [typeState]: action.payload,
    };

  switch (action.type) {
    case actions.SET_EXTRACTION_PERFORMANCE:
      return {
        ...state,
        performance: action.payload,
      };

    case actions.SET_DOCUMENT_PERFORMANCE_OVERVIEW:
      return {
        ...state,
        document_overview: action.payload,
      };

    case actions.RESET_DASHBOARD_STATE:
      return initialState;

    default:
      return state;
  }
};

export default dashboard;
