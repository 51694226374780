/* STIX V2.1 Specification-Defined Relationships Summary */
/* Source: https://docs.oasis-open.org/cti/stix/v2.1/cs01/stix-v2.1-cs01.html#_ha77jftyxqcx:~:text=This%20following%20relationship%20summary%20table%20is%20provided%20as%20a%20convenience */

export const sources = [
  "attack-pattern",
  "campaign",
  "course-of-action",
  "identity",
  "indicator",
  "infrastructure",
  "intrusion-set",
  "malware",
  "malware-analysis",
  "threat-actor",
  "tool",
];
export const targets = [
  "vulnerability",
  "identity",
  "malware",
  "tool",
  "intrusion-set",
  "threat-actor",
  "attack-pattern",
  "campaign",
  "location",
  "infrastructure",
  "observed-data",
  "ipv4-addr",
  "ipv6-addr",
  "domain-name",
  "url",
];
export const relationshipTypes = [
  "targets",
  "uses",
  "attributed-to",
  "mitigates",
  "indicates",
  "variant-of",
  "impersonates",
  "has",
  "drops",
  "delivers",
  "located-at",
  "hosts",
  "owns",
  "compromises",
  "dynamic-analysis-of",
  "static-analysis-of",
  "analysis-of",
  "characterizes",
  "originates-from",
  "exploits",
  "downloads",
  "drops",
  "controls",
  "communicates-with",
  "exfiltrate-to",
  "beacons-to",
  "authored-by",
  "consists-of",
  "based-on",
  "investigates",
  "delivers",
];

export const sourceTargetRelationTypes = {
  "attack-pattern:-:vulnerability": ["targets"],
  "attack-pattern:-:identity": ["targets"],
  "attack-pattern:-:malware": ["delivers", "uses"],
  "attack-pattern:-:tool": ["uses"],
  "attack-pattern:-:location": ["targets"],

  "campaign:-:intrusion-set": ["attributed-to"],
  "campaign:-:threat-actor": ["attributed-to"],
  "campaign:-:identity": ["targets"],
  "campaign:-:vulnerability": ["targets"],
  "campaign:-:attack-pattern": ["uses"],
  "campaign:-:malware": ["uses"],
  "campaign:-:tool": ["uses"],
  "campaign:-:location": ["originates-from", "targets"],
  "campaign:-:infrastructure": ["compromises", "uses"],

  "course-of-action:-:attack-pattern": ["mitigates"],
  "course-of-action:-:malware": ["mitigates"],
  "course-of-action:-:tool": ["mitigates"],
  "course-of-action:-:vulnerability": ["mitigates"],
  "course-of-action:-:indicator": ["investigates", "mitigates"],

  "identity:-:location": ["located-at"],

  "indicator:-:attack-pattern": ["indicates"],
  "indicator:-:campaign": ["indicates"],
  "indicator:-:intrusion-set": ["indicates"],
  "indicator:-:malware": ["indicates"],
  "indicator:-:threat-actor": ["indicates"],
  "indicator:-:tool": ["indicates"],
  "indicator:-:infrastructure": ["indicates"],
  "indicator:-:observed-data": ["based-on"],

  "infrastructure:-:infrastructure": [
    "communicates-with",
    "consists-of",
    "controls",
    "uses",
  ],
  "infrastructure:-:ipv4-addr": ["communicates-with"],
  "infrastructure:-:ipv6-addr": ["communicates-with"],
  "infrastructure:-:domain-name": ["communicates-with"],
  "infrastructure:-:url": ["communicates-with"],
  "infrastructure:-:observed-data": ["consists-of"],
  "infrastructure:-:malware": ["controls", "delivers", "hosts"],
  "infrastructure:-:vulnerability": ["has"],
  "infrastructure:-:tool": ["hosts"],
  "infrastructure:-:location": ["location-at"],

  "intrusion-set:-:threat-actor": ["attributed-to"],
  "intrusion-set:-:identity": ["targets"],
  "intrusion-set:-:vulnerability": ["targets"],
  "intrusion-set:-:attack-pattern": ["uses"],
  "intrusion-set:-:malware": ["uses"],
  "intrusion-set:-:tool": ["uses"],
  "intrusion-set:-:infrastructure": ["hosts", "owns", "compromises", "uses"],
  "intrusion-set:-:location": ["originates-from", "targets"],

  "malware:-:identity": ["targets"],
  "malware:-:vulnerability": ["exploits", "targets"],
  "malware:-:tool": ["downloads", "drops", "uses"],
  "malware:-:malware": ["controls", "downloads", "drops", "uses", "variant-of"],
  "malware:-:threat-actor": ["authored-by"],
  "malware:-:intrusion-set": ["authored-by"],
  "malware:-:infrastructure": [
    "beacons-to",
    "exfiltrate-to",
    "targets",
    "uses",
  ],
  "malware:-:ipv4-addr": ["communicates-with"],
  "malware:-:ipv6-addr": ["communicates-with"],
  "malware:-:domain-name": ["communicates-with"],
  "malware:-:url": ["communicates-with"],
  "malware:-:file": ["downloads", "drops"],
  "malware:-:location": ["originates-from", "targets"],
  "malware:-:attack-pattern": ["uses"],

  "malware-analysis:-:malware": [
    "characterizes",
    "analysis-of",
    "static-analysis-of",
    "dynamic-analysis-of",
  ],

  "threat-actor:-:identity": ["attributed-to", "impersonates", "targets"],
  "threat-actor:-:vulnerability": ["targets"],
  "threat-actor:-:attack-pattern": ["uses"],
  "threat-actor:-:malware": ["uses"],
  "threat-actor:-:tool": ["uses"],
  "threat-actor:-:infrastructure": ["compromises", "hosts", "owns", "uses"],
  "threat-actor:-:location": ["located-at", "targets"],

  "tool:-:identity": ["targets"],
  "tool:-:vulnerability": ["has", "targets"],
  "tool:-:malware": ["delivers", "drops"],
  "tool:-:infrastructure": ["targets"],
  "tool:-:location": ["targets"],
};

const stix2_1 = {
  sources: new Set(sources),
  targets: new Set(targets),
  relationshipTypes,
  sourceTargetRelationTypes,
  version: "2.1",
  url: "https://docs.oasis-open.org/cti/stix/v2.1/cs01/stix-v2.1-cs01.html#_ha77jftyxqcx:~:text=This%20following%20relationship%20summary%20table%20is%20provided%20as%20a%20convenience",
};

export default stix2_1;
