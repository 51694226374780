import React from "react";
import { Button, Typography, DatePicker } from "antd";
import { DownOutlined } from "@ant-design/icons";
import AppPopover from "../../common/widgets/AppPopover";
import OptionList from "./OptionList";
import { useSearchContext } from "../../../contexts/SearchContext";
import { docSortBy, staticDateRanges } from "../../../constants";
import { dateRangeText } from "./helpers";

const { RangePicker } = DatePicker;

/**
 * FilterBar - Layout filters for refining search
 *
 * @function
 *
 * @returns React JSX
 */
export const FilterBar = () => {
  const {
    setEntityTypes,
    entityTypes,
    typeOptions,
    statusOptions,
    docStatus,
    setDocStatus,
    dateOptions,
    dateRange,
    customDateRange,
    setDateRange,
    setCustomDateRange,
    sortBy,
    setSortBy,
    sortOptions,
  } = useSearchContext();

  return (
    <div className="justify-between gap-10">
      <div className="justify-start gap-5">
        <AppPopover
          trigger="click"
          content={
            <OptionList
              title="Type"
              options={typeOptions}
              noEmptySelection
              onSelection={(values) => setEntityTypes(values)}
            />
          }
        >
          <Button className="mr-5">
            Type .{" "}
            {entityTypes && entityTypes.length < typeOptions.length
              ? entityTypes.length
              : "All"}{" "}
            <DownOutlined />
          </Button>
        </AppPopover>
        <AppPopover
          trigger="click"
          content={
            <OptionList
              title="Status"
              options={statusOptions}
              noEmptySelection
              onSelection={(values) => setDocStatus(values)}
            />
          }
        >
          <Button className="mr-5">
            Status .{" "}
            {docStatus && docStatus.length < statusOptions.length
              ? docStatus.length
              : "All"}{" "}
            <DownOutlined />
          </Button>
        </AppPopover>
        <AppPopover
          trigger="click"
          content={
            <OptionList
              title="Date range"
              options={dateOptions}
              singleSelect={true}
              defaultSingle="all_time"
              onSelection={(value) => setDateRange(value)}
              minWidth="300px"
              footer={
                <div>
                  {dateRange === "custom" && (
                    <RangePicker
                      value={customDateRange}
                      onChange={(dr) => setCustomDateRange(dr)}
                    />
                  )}
                </div>
              }
            />
          }
        >
          <Button className="mr-5">
            {/* {dateRange ? staticDateRanges[dateRange] : "All time"} */}
            {dateRangeText(dateRange, customDateRange)}
            <DownOutlined />
          </Button>
        </AppPopover>
      </div>
      <div className="justify-start gap-5">
        <AppPopover
          trigger="click"
          content={
            <OptionList
              title="Sort by"
              options={sortOptions}
              singleSelect={true}
              defaultSingle="most_relevant"
              onSelection={(value) => setSortBy(value)}
            />
          }
        >
          <Button className="mr-5">
            Sort by . {sortBy ? docSortBy[sortBy] : "Most relevant"}
            <DownOutlined />
          </Button>
        </AppPopover>
      </div>
    </div>
  );
};
