import { Popover } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const AppPopover = ({ children, title, cancel, ...restProps }) => {
  const popTitle = (
    <div className="app-popover-title">
      <h3>{title}</h3>
      <span onClick={cancel}>
        <CloseOutlined />
      </span>
    </div>
  );

  return (
    <Popover title={!!title ? popTitle : null} {...restProps}>
      {children}
    </Popover>
  );
};

export default AppPopover;
