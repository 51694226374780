import { useEffect, useState } from "react";
import {
  FileOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import "../../../assets/styles/upload-list-item.scss";
import { Button, Input } from "antd";
import ActionTip from "../../common/widgets/ActionTip";

const getFileNameWithoutTheExtension = (file) => {
  let lastIndex = file.lastIndexOf(".");
  lastIndex = lastIndex === -1 ? 100 : lastIndex;
  return file.slice(0, lastIndex);
};

/**
 * A form component for dragging and uploading files
 *
 * @function
 * @returns React Virtual DOM
 */
const UploadListItem = ({ file, actions, files, setFiles }) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(file.name);

  // allows file upload editing/renaming before upload
  const renameFileName = (newtTitle) => {
    const _files = [...files];
    const _index = _files.findIndex((elem) => elem.uid === file.uid);
    if (_index >= 0) {
      const _file = _files[_index];
      const extName = file.name.slice(file.name.lastIndexOf("."));
      _files[_index] = new File(
        [_file],
        `${getFileNameWithoutTheExtension(newtTitle)}${extName}`,
        {
          type: _file.type,
        }
      );
      // add uid to the new File object
      _files[_index].uid = _file.uid;

      setFiles(_files);
      setValue(getFileNameWithoutTheExtension(newtTitle) + extName);
    }
    setEditing(false);
  };

  const handleEnter = (e) => {
    e.preventDefault();
    renameFileName(e.target.value);
  };

  const handleCancel = () => {
    const f = files.find((f) => f.uid === file.uid);
    setValue(f.name);
    setEditing(false);
  };

  const inputProps = editing
    ? {
        hidden: false,
        addonAfter: (
          <>
            <ActionTip title="Click or press Enter key to save">
              <Button
                size="small"
                type="default"
                shape="circle"
                className="f-green-d"
                onClick={() => {
                  renameFileName(value);
                }}
              >
                <CheckOutlined />
              </Button>
            </ActionTip>{" "}
            <ActionTip title="Click or press ESC key to cancel">
              <Button
                className="f-red"
                size="small"
                type="default"
                shape="circle"
                onClick={handleCancel}
              >
                <CloseOutlined />
              </Button>
            </ActionTip>
          </>
        ),
      }
    : {};

  if (editing) {
    return (
      <Input
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        className="file-upload-edit"
        autoFocus
        placeholder="Rename the file"
        onKeyDown={(e) => {
          if (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) {
            handleCancel();
          }
        }}
        onPressEnter={handleEnter}
        {...inputProps}
      />
    );
  }

  return (
    <div className="file-item">
      <span>
        {" "}
        <FileOutlined
          style={{ fontSize: "24px", color: "#08c", marginRight: "8px" }}
        />{" "}
        {value}
      </span>
      {"  "}
      <div>
        <span
          onClick={() => {
            const newFiles = files.filter((elem) => elem.uid !== file.uid);
            setFiles(newFiles);
            actions.remove();
          }}
        >
          <ActionTip title="Remove from upload list">
            <DeleteOutlined style={{ fontSize: "20px" }} />
          </ActionTip>
        </span>
      </div>
    </div>
  );
};

export default UploadListItem;
