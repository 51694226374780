import { Progress } from "antd";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { useState } from "react";

const StatusBar = ({ label, count, total, isOpen, ...restProps }) => {
  const percent = (count * 100) / total;
  return (
    <div className="justify-start">
      <div className="full-width">
        <div className="status-info">
          <span>{label}</span>
          <span>
            <span style={{ opacity: "0.5", padding: "8px" }}>{count}</span>{" "}
            {!!percent ? percent.toFixed(1) : 0} %
          </span>
        </div>
        <Progress
          percent={percent}
          size="small"
          showInfo={false}
          strokeColor="orange"
          {...restProps}
        />
      </div>
      <div style={{ width: "20px", marginTop: "20px", color: "#D8DEE3" }}>
        {isOpen !== undefined && (
          <span className="pl-10">
            {isOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
          </span>
        )}
      </div>
    </div>
  );
};

export default StatusBar;
