import { useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox, Tag, List, Collapse } from "antd";
import { CaretRightOutlined, CaretDownOutlined } from "@ant-design/icons";
import { collapseIcon } from "../../../utils/helpers";
import "../../../assets/styles/widgets.scss";
import DisplayTip from "../../common/widgets/DisplayTip";
import { SingleEntityAnnotation } from "../analysis/SharedComponents";

/**
 * GroupedRelationshipList displays list of relationships grouped by type and instances
 *
 * @returns React JSX
 */
const GroupedRelationshipList = ({
  relationshipIds = [],
  selectedRelationshipIds = undefined,
  onSelect = undefined,
}) => {
  const [localSelected, setLocalSelected] = useState([]);

  const { relationship, analysis } = useSelector((state) => state);
  const { entitiesByIds } = analysis;

  let selected;
  let setSelected;

  if (selectedRelationshipIds && onSelect) {
    selected = new Set(selectedRelationshipIds);
    setSelected = onSelect;
  } else {
    selected = new Set(localSelected);
    setSelected = setLocalSelected;
  }

  const groupedRelationships = {};

  relationshipIds.forEach((id) => {
    const prop = relationship.props[id] || {};
    const { source_id, target_id } = prop;
    const sourceEntity = entitiesByIds[source_id] || {};
    const targetEntity = entitiesByIds[target_id] || {};

    const key = `${sourceEntity.text}::${prop.type}::${targetEntity.text}`;

    if (!groupedRelationships[key]) {
      const sourceType = sourceEntity.type;
      const targetType = targetEntity.type;
      groupedRelationships[key] = {
        ids: [],
        sourceType,
        targetType,
        entityIdPairs: [],
      };
    }

    groupedRelationships[key].ids.push(id);
    groupedRelationships[key].entityIdPairs.push([source_id, target_id]);
  });

  const handleSelect = (e, ids) => {
    e.stopPropagation();
    const s = new Set(selected);
    if (e.target.checked) {
      ids.forEach((id) => s.add(id));
    } else {
      ids.forEach((id) => s.delete(id));
    }

    setSelected(Array.from(s));
  };

  return (
    <div id="grouped-relationships" className="related-entity-section">
      <List
        size="small"
        dataSource={Object.entries(groupedRelationships)}
        renderItem={([groupKey, group]) => {
          let [sourceText, rType, targetText] = groupKey.split("::");
          const { sourceType, targetType, ids, entityIdPairs } = group;
          rType = rType.replace("-", " ");
          const isChecked = ids.every((id) => selected.has(id));
          return (
            <List.Item key={groupKey}>
              <Collapse
                expandIcon={collapseIcon(CaretRightOutlined, CaretDownOutlined)}
                ghost
              >
                <Collapse.Panel
                  header={
                    <div className="justify-between full-width">
                      <div className="justify-between text-nowrap">
                        <Checkbox
                          className="mr-5"
                          checked={isChecked}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            handleSelect(e, ids);
                          }}
                        />
                        <span className={`${sourceType} text-ellipsis bdr-2`}>
                          {sourceText}{" "}
                        </span>
                        <strong className="mx-10">
                          <em>{rType}</em>
                        </strong>
                        <span className={`${targetType} text-ellipsis bdr-2`}>
                          {targetText}
                        </span>
                      </div>
                      <div className="ml-5">
                        <Tag className="mr-0">{ids.length}</Tag>
                      </div>
                    </div>
                  }
                >
                  <List
                    size="small"
                    dataSource={ids}
                    renderItem={(id, idx) => {
                      return (
                        <List.Item key={id}>
                          <div className="justify-between full-width retionship-list-item">
                            <div className="justify-between text-nowrap">
                              <Checkbox
                                className="mr-5"
                                checked={selected.has(id)}
                                onChange={(e) => handleSelect(e, [id])}
                              />
                              <DisplayTip
                                title={
                                  <SingleEntityAnnotation
                                    entityId={entityIdPairs[idx][0]}
                                  />
                                }
                              >
                                <span
                                  className={`${sourceType}-outlined text-ellipsis bdr-5`}
                                >
                                  {sourceText}{" "}
                                </span>
                              </DisplayTip>
                              <strong className="mx-10">
                                <em>{rType}</em>
                              </strong>
                              <DisplayTip
                                title={
                                  <SingleEntityAnnotation
                                    entityId={entityIdPairs[idx][1]}
                                  />
                                }
                              >
                                <span
                                  className={`${targetType}-outlined text-ellipsis bdr-5`}
                                >
                                  {targetText}
                                </span>
                              </DisplayTip>
                            </div>
                            <div className="ml-5"></div>
                          </div>
                        </List.Item>
                      );
                    }}
                  />
                </Collapse.Panel>
              </Collapse>
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default GroupedRelationshipList;
