export const formatDate = (dateString) => {
  if (!dateString) return "";
  const [_, m, d, y] = new Date(dateString).toDateString().split(" ");
  return `${d} ${m}'${y.slice(-2)}`;
};

const getDateCategories = (d) =>
  d.map(([start_date, end_date]) => {
    if (start_date === end_date) return formatDate(start_date);
    return `${formatDate(start_date)} to ${formatDate(end_date)}`;
  });

export const barChartOptions = {
  series: [],
  chart: {
    type: "bar",
    height: 450,
    stacked: true,
    stackType: "100%",
    toolbar: {
      show: false,
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: "bottom",
          offsetX: -10,
          offsetY: 0,
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      dataLabels: {
        enabled: false,
        total: {
          enabled: false,
        },
      },
    },
  },
  xaxis: {
    type: "category",
    categories: [],
    labels: {
      show: true,
      rotate: -45,
      style: {
        fontSize: "10px",
      },
    },
  },
  legend: {
    position: "bottom",
  },
  stroke: {
    show: true,
    curve: "smooth",
    lineCap: "butt",
    colors: undefined,
    width: 2,
    dashArray: 0,
  },
  noData: {
    text: "No data",
  },
};

export const getConfScoreOptions = (data = [], dateRanges = []) => {
  const series = data.map((d) => {
    d.items.map((item) => item.count);
    return {
      name: d.label,
      data: d.items.map((item) => item.count),
    };
  });

  return {
    ...barChartOptions,
    series,
    colors: ["#F2D6F6", "#E3CFF3", "#E0DEFD"],
    xaxis: {
      ...barChartOptions.xaxis,
      categories: getDateCategories(dateRanges),
    },
    stroke: {
      ...barChartOptions.stroke,
      colors: ["#BD34D1", "#730FC3", "#6558F5"],
    },
  };
};

export const getAnnotationOptions = (data = [], dateRanges = []) => {
  const series = data.map((d) => {
    d.items.map((item) => item.count);
    return {
      name: d.label,
      data: d.items.map((item) => item.count),
    };
  });

  return {
    ...barChartOptions,
    series,
    colors: ["#D5E7F7", "#FDF3D3", "#D1EFEC"],
    xaxis: {
      ...barChartOptions.xaxis,
      categories: getDateCategories(dateRanges),
    },
    stroke: {
      ...barChartOptions.stroke,
      colors: ["#2C88D9", "#F7C325", "#1AAE9F"],
    },
  };
};

export const getTimeSavedChartOptions = (data = [[], []], dateRanges = []) => {
  const [manual_time, automated_time] = data;
  const series = [
    {
      name: "Manual annotation time",
      data: manual_time.map((m) => (m.duration / 60).toFixed(1)),
    },
    {
      name: "Read annotation time",
      data: automated_time.map((a) => (a.duration / 60).toFixed(1)),
    },
  ];

  return {
    ...barChartOptions,
    colors: ["#F2F5F7", "#E0DEFD"],
    series,
    chart: {
      ...barChartOptions.chart,
      stacked: false,
      stackType: undefined,
    },
    xaxis: {
      categories: getDateCategories(dateRanges),
    },
    yaxis: {
      title: {
        text: "Minutes",
      },
    },
    stroke: {
      ...barChartOptions.stroke,
      colors: ["#C3CFD9", "#6558F5"],
    },
    dataLabels: {
      enabled: false,
    },
  };
};
