import React, { useState } from "react";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import _ from "lodash";
import { useSearchContext } from "../../../contexts/SearchContext";
import { useDispatch } from "react-redux";
import { getSearchRequest } from "../../../store/actions/search";
import { dateRangeValue } from "./helpers";

export const SearchBar = () => {
  const [searchString, setSearchString] = useState("");

  const dispatch = useDispatch();

  const {
    entityTypes = [],
    typeOptions = [],
    docStatus = [],
    dateRange = "all_time",
    customDateRange,
    sortBy = "most_relevant",
  } = useSearchContext();

  const handleSearch = () => {
    if (!searchString) return;

    let queryString = `entity_name=${searchString}`;
    if (entityTypes.length < typeOptions.length) {
      queryString += `&type=${entityTypes.join(",")}`;
    }
    queryString += `&status=${docStatus.join(",")}`;
    queryString += `&date_range=${dateRangeValue(dateRange, customDateRange)}`;
    queryString += `&order_by=${sortBy}`;

    dispatch(getSearchRequest(queryString));
  };

  return (
    <div id="search-bar" className="justify-start align-center">
      <Input
        className="flex-1 app-input"
        placeholder="Search for marked up entities across all your reports..."
        prefix={<SearchOutlined />}
        size="small"
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        onPressEnter={_.debounce(handleSearch, 500)}
      />
      <Button
        type="primary"
        className="ml-10"
        onClick={_.debounce(handleSearch, 500)}
        disabled={!searchString}
      >
        Apply changes
      </Button>
    </div>
  );
};

export default SearchBar;
