import React from "react";
import { useLocation, useHistory, Link, Redirect } from "react-router-dom";
import { Result, Button } from "antd";
import {
  LockOutlined,
  ExclamationOutlined,
  FrownOutlined,
  StopOutlined,
} from "@ant-design/icons";
import routes from "../../../constants/routes";
import { TRY_AGAIN } from "../../../constants";
import { useAuthContext } from "../../../contexts/AuthContext";

const ErrorPage = () => {
  const { auth } = useAuthContext();
  const location = useLocation();
  const history = useHistory();

  const { state = {} } = location;
  const { from, title, subTitle, action, meta = {}, pageName } = state;
  const { redirectPath } = meta;

  if (
    location.pathname === routes.NOT_AUTHENTICATED &&
    auth &&
    auth.authenticated
  ) {
    return (
      <Redirect
        to={{
          pathname: routes.LOGIN,
          state: { redirectPath },
        }}
      />
    );
  }

  const errorType = {
    [routes.NOT_AUTHENTICATED]: {
      title: title || "401 - Unauthenticated",
      subTitle: subTitle || "We are not able to authenticate you",
      icon: (
        <span className="error-icon error-401">
          <LockOutlined />
        </span>
      ),
      extra:
        action === TRY_AGAIN ? (
          <Button
            onClick={() => (window.location.href = redirectPath || routes.HOME)}
            type="primary"
          >
            Try again
          </Button>
        ) : (
          <Link to={{ pathname: routes.LOGIN, state: { redirectPath } }}>
            <Button type="primary">Login</Button>
          </Link>
        ),
    },
    [routes.FORBIDDEN]: {
      title: title || "403 - Unauthorized",
      subTitle:
        subTitle ||
        `You do not have the permission to access the ${
          pageName || "page or resource"
        }.`,
      icon: (
        <span className="error-icon error-403">
          <StopOutlined />
        </span>
      ),
      extra: (
        <Button onClick={() => history.push(routes.HOME)} type="primary">
          Go Home
        </Button>
      ),
    },
    [routes.NOT_FOUND]: {
      title: title || "404 - Not found",
      subTitle:
        subTitle ||
        "Sorry, we can't find the page or resource you were looking for.",
      icon: (
        <span className="error-icon error-404">
          <ExclamationOutlined />
        </span>
      ),
      extra: (
        <Button onClick={() => history.push(routes.HOME)} type="primary">
          Go Home
        </Button>
      ),
    },
    [routes.INTERNAL_ERROR]: {
      title: title || "500 - Internal error",
      subTitle: subTitle || "Sorry, it looks like something has gone wrong.",
      icon: (
        <span className="error-icon error-500">
          <FrownOutlined />
        </span>
      ),
      extra: [
        <Button onClick={() => history.push(routes.HOME)}>Go Home</Button>,
        <Button
          onClick={() => (window.location.href = from || routes.HOME)}
          type="primary"
        >
          Try again
        </Button>,
      ],
    },
  };

  const errorMeta = errorType[location.pathname] || errorType[routes.NOT_FOUND];

  return <Result {...errorMeta} />;
};

export default ErrorPage;
