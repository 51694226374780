import { Select } from "antd";

const { Option } = Select;

/**
 * Custom select input
 *
 * @function
 * @param {{array}} options- List of objects with value and label keys/values
 * @param {{object}} props - Optional value for customizing select input
 * @returns React Virtual DOM
 */
const SelectInput = ({ options, ...props }) => {
  return (
    <Select
      showSearch
      allowClear
      placeholder="Search to Select"
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...props}
    >
      {options.map(({ value, label }, idx) => (
        <Option key={idx} value={value}>
          {label}
        </Option>
      ))}
    </Select>
  );
};

export default SelectInput;
