import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Table, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "../../../assets/styles/metadata.scss";
import WarningDialog from "../../common/widgets/WarningDialog";
import {
  getDocumentMetadataKeys,
  resetDocumentMetadataApi,
  updateDocumentMetadataKeys,
  deleteSingleDocumentMetadataKey,
  deleteAllDocumentMetadataKeys,
} from "../../../store/actions/documentMetadata";
import { documentMetadataColumns } from "./columns";
import {
  getOrganisationMetadataProfileDetails,
  getOrganisationMetadataProfiles,
} from "../../../store/actions/metadata";
import { appRoles, CUSTOM_ADHOC, CUSTOM_KEY_SUFFIX } from "../../../constants";
import { useAuthContext } from "../../../contexts/AuthContext";
import DisplayTip from "../../common/widgets/DisplayTip";
import messageBox from "../../../utils/services/MessageBox";

const { Option } = Select;

const DocumentMetadata = ({ documentId }) => {
  const [keyValues, setKeyValues] = useState([]);
  const [increment, setIncrement] = useState(0);
  const [profileId, setProfileId] = useState();
  const [profileKeys, setProfileKeys] = useState({});
  const dispatch = useDispatch();

  const { auth } = useAuthContext();
  const asOrgMetaDataAccess = auth?.realmAccess?.roles?.includes(
    appRoles.ORGANISATION_METADATA
  );

  const { documentMetadata, metadata } = useSelector((state) => state);

  const [form] = Form.useForm();

  const mergeKeys = (keys = []) => {
    const fields = form.getFieldsValue();
    const exists = {};
    const mergedKeys = [];
    keyValues.forEach((kv) => {
      const { key, value } = kv;
      if (value || fields[key]) {
        exists[key] = true;
        mergedKeys.push({ ...kv, value: fields[key] || value });
      }
    });

    keys.forEach((k) => {
      if (!exists[k.key]) mergedKeys.push({ ...k, unsaved: true });
    });
    setKeyValues(mergedKeys);
  };

  useEffect(() => {
    const data = metadata.getProfileDetails.result.data;

    if (data && data.keys) {
      mergeKeys(data.keys);
      setProfileKeys({ ...profileKeys, [data.id]: data.keys });
    }

    return () => dispatch(resetDocumentMetadataApi());
  }, [metadata.getProfileDetails]);

  useEffect(() => {
    dispatch(getOrganisationMetadataProfiles());
    dispatch(getDocumentMetadataKeys(documentId));
  }, [documentId]);

  useEffect(() => {
    const keyList = [...documentMetadata.keys];

    // merge with existing
    keyValues.forEach((e) => {
      const item = keyList.find((f) => f.key === e.key);
      if (!e.unsaved && !item) return;
      if (!item) {
        keyList.push(e);
      }
    });

    setKeyValues(keyList);
  }, [documentMetadata.keys]);

  const handleSave = (fieldsValue) => {
    const keys = [];

    for (let [key, value] of Object.entries(fieldsValue)) {
      if (key.endsWith(CUSTOM_KEY_SUFFIX) || !value) continue;

      if (key.startsWith(CUSTOM_ADHOC)) {
        const adhocKey = fieldsValue[key + CUSTOM_KEY_SUFFIX];
        if (!adhocKey)
          return messageBox.showError(
            `No adhoc key was provided for the value '${value}'.`
          );
        keys.push({ key: adhocKey, value });
      } else {
        keys.push({ key, value });
      }
    }

    dispatch(
      updateDocumentMetadataKeys(documentId, { profile_id: profileId, keys })
    );
  };

  const handleProfileChange = (profileId) => {
    if (!profileId) return;

    if (profileKeys[profileId]) {
      mergeKeys(profileKeys[profileId]);
    } else {
      dispatch(getOrganisationMetadataProfileDetails(profileId));
    }

    setProfileId(profileId);
  };

  const handleKeysValidation = () => {
    messageBox.showError("Please fill all required fields");
  };

  const addKey = () => {
    setKeyValues((kv) => {
      return [
        ...kv,
        { key: CUSTOM_ADHOC + "-" + increment, value: "", unsaved: true },
      ];
    });
    setIncrement(increment + 1);
  };

  const removeAdhoc = (key) => {
    setKeyValues((kv) => kv.filter((m) => m.key !== key));
  };

  const deleteKey = (key, unsaved) => {
    if (unsaved) return removeAdhoc(key);

    dispatch(deleteSingleDocumentMetadataKey(documentId, key));
  };

  const deleteAllKeys = (key) => {
    dispatch(deleteAllDocumentMetadataKeys(documentId));
  };

  const clearEmptyProfileFields = () => {
    const fields = form.getFieldsValue();
    setKeyValues((kvs) => {
      return kvs.filter(
        ({ key, value }) => value || fields[key] || key.startsWith(CUSTOM_ADHOC)
      );
    });
  };

  const { getKeys, updateKeys, deleteSingleKey } = documentMetadata;

  const loadingKeys = getKeys.loading || metadata.getProfileDetails.loading;
  const savingKeys = updateKeys.loading || deleteSingleKey.loading;
  const loading = loadingKeys || savingKeys;

  const deleteAllWarning = (
    <>
      <em>Do you really want to delete all</em> <br />
      <em>keys and values in this document?</em>
    </>
  );

  return (
    <div id="document-metadata-pane">
      <div className="flex-column mb-15">
        <strong className="mb-10">
          Do you want to load keys from a profile?{" "}
        </strong>
        <DisplayTip
          title={
            !asOrgMetaDataAccess
              ? "You do not have access to metadata profiles. Contact your organisation admin."
              : ""
          }
          placement="bottom"
        >
          <Select
            className="full-width"
            placeholder="Select a profile"
            loading={loading || metadata.getProfiles.loading}
            disabled={
              loading || metadata.getProfiles.loading || !asOrgMetaDataAccess
            }
            onChange={handleProfileChange}
            onClear={clearEmptyProfileFields}
            allowClear
          >
            {metadata.profiles.map((profile, idx) => (
              <Option key={idx} value={profile.id}>
                {profile.name}
              </Option>
            ))}
          </Select>
        </DisplayTip>
      </div>
      <div className="justify-end mb-10">
        <WarningDialog title={deleteAllWarning} onConfirm={deleteAllKeys}>
          <Button
            shape="round"
            size="small"
            disabled={loading || !documentMetadata.keys.length}
            danger
          >
            Delete all
          </Button>
        </WarningDialog>
      </div>
      <Form
        form={form}
        onFinish={handleSave}
        onFinishFailed={handleKeysValidation}
      >
        <Table
          dataSource={keyValues}
          columns={documentMetadataColumns(removeAdhoc, deleteKey)}
          loading={loading}
          pagination={false}
          footer={() => (
            <Button onClick={addKey} type="link">
              <PlusOutlined /> Add metadata key
            </Button>
          )}
        />
        <Form.Item className="mt-10">
          <Button
            type="primary"
            htmlType="submit"
            className="mr-10"
            loading={savingKeys}
            disabled={loadingKeys}
          >
            Save
          </Button>
          <Button
            disabled={loading}
            onClick={() => {
              form.resetFields();
            }}
          >
            Reset
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DocumentMetadata;
