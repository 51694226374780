import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import routes from "../../../constants/routes";
import { useAuthContext } from "../../../contexts/AuthContext";
import PageLoader from "../../common/loaders/PageLoader";
import messageBox from "../../../utils/services/MessageBox";

const DocumentSource = () => {
  const [loading, setLoading] = useState();
  const { documentId } = useParams();
  const { auth } = useAuthContext();
  const history = useHistory();

  useEffect(() => {
    const baseURL = window._env_.UNIFIED_SERVER_URL;
    const url = `${baseURL}/documents/${documentId}/source_file`;
    setLoading(true);
    axios
      .get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = url;
        document.body.appendChild(link);
        link.click();
        setLoading(false);
        URL.revokeObjectURL(url);
      })
      .catch(() => {
        setLoading(false);
        messageBox.showError("Sorry, an error occured");
        history.push(routes.NOT_FOUND);
      });
  }, []);

  return loading ? <PageLoader /> : null;
};

export default DocumentSource;
