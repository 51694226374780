import { Button } from "antd";
import SelectInput from "../../../common/forms/SelectInput";
import attackTechniques from "../../../../data/attack_tids_v15_and_disarm.json";

/**
 * TID search dropdown
 *
 * @returns React JSX
 */
const SelectTID = ({
  selectedTID,
  setSelectedTID,
  requestHandler = undefined,
  onClick = undefined,
  showSendButton = false,
  autoFocus = true,
  defaultOpen = true,
}) => {
  return (
    <>
      <span style={{ display: "inline-block", width: "200px", padding: "5px" }}>
        {showSendButton && (
          <span
            style={{
              with: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={requestHandler}
              type="primary"
              size="small"
              disabled={!selectedTID}
            >
              Send
            </Button>
          </span>
        )}
        <span style={{ with: "100%", padding: "3px 0" }}>
          <SelectInput
            style={{ width: "100%" }}
            autoFocus={autoFocus}
            defaultOpen={defaultOpen}
            placeholder="Search attack patterns"
            notFoundContent="Not Found"
            onClick={onClick}
            onChange={(techniqueId) => {
              setSelectedTID("");
              setSelectedTID(techniqueId);
            }}
            options={Object.values(attackTechniques).map(({ tid, name }) => ({
              value: tid,
              label: `[${tid}] ${name}`,
            }))}
          />
        </span>
      </span>
    </>
  );
};

export default SelectTID;
