import axios from "axios";
import api from "../../constants/api";

const actions = {
  GET_STIX_JSON: "GET_DOCUMENT_STIX_JSON",
  GET_BASIC_DOCUMENT: "GET_BASIC_DOCUMENT_DETAILS",
  SET_DOCUMENT_DETAILS: "SET_BASIC_DOCUMENT_DETAILS",
  SET_SELECTED_RELATIONSHIP_IDS: "SET_SELECTED_RELATIONSHIP_IDS",
  RESET: "RESET_STIX_GRAPH_STATE",
};

export default actions;

/* Action creators */

export const getDocumentExtendedStixBundle = (docId) => ({
  type: actions.GET_STIX_JSON,
  payload: axios.get(api.DOCUMENT_EXTENDED_STIX.replace(":documentId", docId)),
});

export const getDocumentStixBundle = (docId) => ({
  type: actions.GET_STIX_JSON,
  payload: axios.get(api.DOCUMENT_STIX_BUNDLE.replace(":documentId", docId)),
});

export const getBasicDocumentDetails = (docId) => ({
  type: actions.GET_BASIC_DOCUMENT,
  payload: axios.get(`${api.DOCUMENTS}/${docId}?basics=true`),
});

export const setBasicDocumentDetails = (document) => ({
  type: actions.SET_DOCUMENT_DETAILS,
  payload: document,
});

export const setSelectedRelationshipIds = (relationshipIds = []) => ({
  type: actions.SET_SELECTED_RELATIONSHIP_IDS,
  payload: relationshipIds,
});
