import { Button, Divider, Dropdown, Menu, Table } from "antd";
import { DownOutlined } from "@ant-design/icons";
import DisplayTip from "../../common/widgets/DisplayTip";
import { documentCollectionsColumns } from "./columns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDocumentToCollectionRequest,
  getDocumentCollectionsRequest,
  removeDocumentFromCollectionRequest,
} from "../../../store/actions/collections";
import { useReload } from "../../../hooks/useReload";
import { useMessageBoxError } from "../../../hooks/useMessageBox";

const initColls = { unassigned: [], assigned: [] };

const DocumentCollections = ({ documentId, documentTitle }) => {
  const [collections, setCollections] = useState(initColls);

  const dispatch = useDispatch();
  const {
    getAllCollections: gac,
    removeCollectionDocument: rcd,
    getDocumentCollections: gdc,
    addCollectionDocument: acd,
  } = useSelector(({ collections }) => collections);

  const updateCollections = () => {
    const collections = gac.result.data?.collections || [];
    const assigned = gdc.result.data?.collections || [];
    if (!collections.length) return;
    const collIds = new Set(assigned.map((coll) => coll.id));
    const unassigned = collections.filter((coll) => !collIds.has(coll.id));
    setCollections({ unassigned, assigned });
  };

  const requestDocumentCollections = () => {
    dispatch(getDocumentCollectionsRequest(documentId));
  };

  useReload(gdc.result, updateCollections);
  useReload(gac.result, updateCollections);
  useReload(rcd.result, requestDocumentCollections);
  useReload(acd.result, requestDocumentCollections);
  useMessageBoxError(acd.error);
  useMessageBoxError(rcd.error);

  useEffect(() => {
    requestDocumentCollections();
  }, []);

  const removeDocument = (collectionId) => {
    dispatch(removeDocumentFromCollectionRequest(documentId, collectionId));
  };

  const menu = (
    <Menu>
      {collections.unassigned.map((coll) => (
        <Menu.Item
          onClick={() =>
            dispatch(addDocumentToCollectionRequest(documentId, coll.id))
          }
          key={coll.id}
        >
          {coll.title}
        </Menu.Item>
      ))}
    </Menu>
  );

  const hasAvailableCollections = !!collections.unassigned.length;

  return (
    <div>
      <h2 className="text-ellipsis">
        <DisplayTip title={documentTitle}>{documentTitle}</DisplayTip>
      </h2>
      <Divider />
      <div className="mb-15">
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          disabled={!hasAvailableCollections}
        >
          <Button>
            {hasAvailableCollections && (
              <>
                Add to collection <DownOutlined />
              </>
            )}
            {!hasAvailableCollections && "No collection to add"}
          </Button>
        </Dropdown>
      </div>
      <div>
        <Table
          columns={documentCollectionsColumns(removeDocument)}
          dataSource={collections.assigned}
          showHeader={false}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default DocumentCollections;
