import { useSelector } from "react-redux";
import { Divider, Radio } from "antd";
import { SendOutlined } from "@ant-design/icons";
import Checkbox from "antd/lib/checkbox/Checkbox";
import DisplayTip from "../../common/widgets/DisplayTip";
import { useEffect } from "react";
import infoMessages from "../../../utils/messages/infoMessages";
import errorMessages from "../../../utils/messages/errorMessages";
import { SingleEntityAnnotation } from "./SharedComponents";

/**
 * EntityList displays list of entities by ids
 *
 * @returns React JSX
 */
const EntityList = ({
  entityIds,
  selected,
  setSelected,
  handleSelection,
  disableList = false,
  loading = false,
  canSelect = true,
  canSelectMultiple = true,
}) => {
  // App state
  const { analysis } = useSelector((state) => state);
  const { entitiesByIds } = analysis;

  const totalCount = entityIds.length;
  let checkedCount = 0;
  let selectedSet = new Set();
  if (canSelectMultiple && Array.isArray(selected)) {
    checkedCount = selected.length;
    selectedSet = new Set(selected);
  } else if (selected) {
    checkedCount = 1;
    selectedSet.add(selected);
  }

  useEffect(() => {
    if (!canSelect) return;
    if (!handleSelection && !setSelected) {
      throw new Error(errorMessages.can_select_unhandle);
    }
  }, []);

  const handleCheckbox = (entityId) => (e) => {
    if (handleSelection) {
      handleSelection(e, entityId);
    } else if (Array.isArray(selected)) {
      e.target.checked
        ? selectedSet.add(entityId)
        : selectedSet.delete(entityId);
      const newSelected = Array.from(selectedSet);
      setSelected(newSelected);
    } else {
      setSelected(entityId);
    }
  };

  const handleCheckAll = (e) =>
    e.target.checked ? setSelected(entityIds) : setSelected([]);

  return (
    <div>
      {canSelect && canSelectMultiple && (
        <Divider>
          <Checkbox
            onChange={handleCheckAll}
            checked={checkedCount === totalCount}
            indeterminate={checkedCount === totalCount}
            disabled={disableList || loading}
          >
            {infoMessages.count_selected.replace(":count:", checkedCount)}
          </Checkbox>
        </Divider>
      )}
      <ul className="mx-0 px-0">
        {entityIds.map((entityId) => {
          const annotatedText = <SingleEntityAnnotation entityId={entityId} />;

          return (
            <li key={entityId} className="entity-list-item text-ellipsis py-10">
              {canSelect && canSelectMultiple && (
                <Checkbox
                  onChange={handleCheckbox(entityId)}
                  checked={selectedSet.has(entityId)}
                  disabled={disableList || loading}
                  className="mr-10"
                />
              )}
              {canSelect && !canSelectMultiple && (
                <Radio
                  onChange={handleCheckbox(entityId)}
                  checked={selectedSet.has(entityId)}
                  disabled={disableList || loading}
                  className="mr-10"
                />
              )}
              {!canSelect && (
                <SendOutlined className="mr-5" style={{ fontSize: 10 }} />
              )}
              <DisplayTip
                title={!(disableList || loading) ? annotatedText : ""}
                visible={!(disableList || loading) ? undefined : false}
              >
                <span className="text-ellipsis" style={{ fontSize: "0.75rem" }}>
                  {annotatedText}
                </span>
              </DisplayTip>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default EntityList;
