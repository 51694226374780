import axios from "axios";
import api from "../../constants/api";

const actions = {
  SET_EXTRACTION_PERFORMANCE: "SET_EXTRACTION_PERFORMANCE",
  SET_DOCUMENT_PERFORMANCE_OVERVIEW: "SET_DOCUMENT_PERFORMANCE_OVERVIEW",
  RESET_DASHBOARD_STATE: "RESET_DASHBOARD_STATE",
  GET_EXTRACTION_PERFORMANCE_REQUEST: "GET_EXTRACTION_PERFORMANCE_REQUEST",
  GET_DOCUMENT_PERFORMANCE_OVERVIEW_REQUEST:
    "GET_DOCUMENT_PERFORMANCE_OVERVIEW_REQUEST",
};

export default actions;

/* Action creators */

export const setExtractionPerformance = (data = {}) => ({
  type: actions.SET_EXTRACTION_PERFORMANCE,
  payload: data.performance,
});

export const setDocumentPerformanceOverview = (data = {}) => ({
  type: actions.SET_DOCUMENT_PERFORMANCE_OVERVIEW,
  payload: data.overview,
});

export const resetDashboardState = () => ({
  type: actions.RESET_DASHBOARD_STATE,
});

export const getExtractionPerformanceRequest = (query = "") => ({
  type: actions.GET_EXTRACTION_PERFORMANCE_REQUEST,
  payload: axios.get(`${api.EXTRACTION_PERFORMANCE}?${query}`),
});

export const getDocumentPerformanceOverviewRequest = (documentId) => ({
  type: actions.GET_DOCUMENT_PERFORMANCE_OVERVIEW_REQUEST,
  payload: axios.get(
    `${api.DOCUMENT_PERFORMANCE_OVERVIEW.replace(":documentId", documentId)}`
  ),
});
