import { Form, Input, Button, Collapse } from "antd";
import { useState } from "react";
import { documentStatus, documentAccessLevels } from "../../../constants";
import api from "../../../constants/api";
import { knownEntityTypes } from "../../../constants/entityTypes";
import useAxios from "../../../hooks/useAxios";
import { useAddDocument } from "../../../hooks/useDocumentsUpdate";
import errorMessages from "../../../utils/messages/errorMessages";
import infoMessages from "../../../utils/messages/infoMessages";
import { AccessLevelButton } from "./AccessLevelButton";
import { DocumentEntityTypes } from "./DocumentEntityTypes";
import STIXDropDownHearder from "./STIXDropDownHearder";
import {
  useMessageBoxError,
  useMessageBoxSuccess,
} from "../../../hooks/useMessageBox";
import messageBox from "../../../utils/services/MessageBox";

const { Panel } = Collapse;
const defaultCheckedList = Object.values(knownEntityTypes);

/**
 * Form for importing plain CTI text
 *
 * @function
 * @returns React Virtual DOM
 */
const TextImport = () => {
  const [{ loading, result, error }, uploadText] = useAxios(null, "POST");
  const [newDocument, setNewDocument] = useState();
  const [form] = Form.useForm();
  const [accessLevelType, setAccessLevelType] = useState(
    documentAccessLevels.ORGANISATION
  );
  const [entityList, setEntityList] = useState([...defaultCheckedList]);

  useAddDocument(result, newDocument, documentStatus.QUEUE);
  useMessageBoxSuccess(result);
  useMessageBoxError(error);

  const handleSubmit = (values) => {
    const title = values["title"];
    const text = values["text"];

    if (!title || !text) return messageBox.showError("All fields are required");
    setNewDocument({ title, text });
    uploadText(api.UPLOAD_TEXT, {
      title,
      text,
      access_level: accessLevelType,
      entity_list: entityList,
      all_entity_list: defaultCheckedList,
    });
    form.resetFields();
  };
  const getDocumentAccessType = (docType) => {
    setAccessLevelType(docType);
  };
  const getCheckedEntityList = (list) => {
    const actualEntityTypes = list.map(
      (entityType) => `${knownEntityTypes[entityType]}`
    );
    setEntityList(actualEntityTypes);
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        validateMessages={{ required: "${label} " + errorMessages.is_required }}
      >
        <Form.Item>
          <Button
            size="small"
            type="primary"
            htmlType="submit"
            shape="round"
            loading={loading}
            block
          >
            Add to queue
          </Button>
        </Form.Item>
        <Form.Item
          name="title"
          label={infoMessages.title}
          rules={[{ required: true }]}
        >
          <Input placeholder="Title of the CTI document" />
        </Form.Item>
        <Form.Item
          name="text"
          label={infoMessages.content}
          rules={[{ required: true }]}
        >
          <Input.TextArea rows={6} placeholder="Paste or enter CTI text" />
        </Form.Item>
        <AccessLevelButton getDocumentAccessType={getDocumentAccessType} />
        <Collapse>
          <Panel
            header={<STIXDropDownHearder entityCount={entityList.length} />}
          >
            <DocumentEntityTypes checkedEntityList={getCheckedEntityList} />
          </Panel>
        </Collapse>
      </Form>
    </>
  );
};

export default TextImport;
