import FileSaver from "file-saver";
import errorMessages from "../../../utils/messages/errorMessages";
import Mixpanel from "../../../utils/services/Mixpanel";
import events from "../../../constants/analyticEvents";
import { showSlideInView } from "../../../store/actions";
import NodeGroup from "./NodeGroup";
import { getGroupEntityIds } from "../../../utils/helpers/analysis/entities";
import messageBox from "../../../utils/services/MessageBox";
import { setSelectedRelationshipIds } from "../../../store/actions/stixGraph";
import { batch } from "react-redux";

/**
 * Export STIX Graph to image
 *
 * @function
 * @param {object} cy - Cytoscape graph object
 * @param {string} type - Image type. png | jpg
 * @param {string} title - Title to be included in file name
 * @param {striing} key- AntD menu item key representing selection image type
 */
export const exportStixGraphImage =
  (cy, type, title) =>
  ({ key }) => {
    try {
      const options = { full: key === "full", output: "blob" };
      const blob = type === "png" ? cy.png(options) : cy.jpg(options);
      const filename = `${title.slice(
        0,
        50
      )}-${new Date().toISOString()}.${type}`;
      FileSaver.saveAs(blob, filename);
    } catch (error) {
      messageBox.showError(errorMessages.oops_reload_retry);
      console.error(error);
    }
  };

/**
 * Run a graph layout
 *
 * @function
 * @param {object} node - Graph node
 * @param {string} fromLayout - Old layout
 * @param {object} eventProps - analytic event properties
 * @param {func} setLayout - Layout state setter
 * @param {striing} key- AntD menu item key representing selected new layout
 */
export const runGraphLayout =
  (node, fromLayout, eventProps, setLayout) =>
  ({ key }) => {
    node.runLayout(key);
    Mixpanel.track(events.STIX_GRAPH_LAYOUT, {
      from: fromLayout,
      to: key,
      ...eventProps,
    });
    setLayout(key);
  };

export const handleClickGraphNode =
  (entityGroupsRef, newRelationRef, setEntityIds, showModal, dispatch) =>
  (node) => {
    let { label: text, type } = node;

    if (type === "report") return;

    if (newRelationRef.current.started) {
      const entityIds = getGroupEntityIds(entityGroupsRef.current, type, text);
      setEntityIds(entityIds);
      showModal(true);
    } else {
      batch(() => {
        dispatch(setSelectedRelationshipIds());
        dispatch(
          showSlideInView(NodeGroup, { text, type }, { title: "Node Group" })
        );
      });
    }
  };

export const toggleReportNode = (isHide, setter, ref, cy) => {
  if (isHide) {
    ref.current = cy.remove(".report");
  } else {
    cy.add(ref.current);
  }
  setter(!isHide);
};
