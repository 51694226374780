import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button } from "antd";
import { useReload } from "../../../hooks/useReload";
import {
  submitRuleFeedbackRequest,
  getSingleRuleRequest,
} from "../../../store/actions/manualRules";
import { removeModalView } from "../../../store/actions";
import infoMessages from "../../../utils/messages/infoMessages";

const FeedbackForm = ({ ruleId }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { getSingleRule: g } = useSelector(({ manualRules }) => manualRules);

  useReload(g.result, () => {
    form.setFieldsValue(g.result?.data);
  });

  useEffect(() => {
    form.resetFields();
    dispatch(getSingleRuleRequest(ruleId));
  }, []);

  const handleSubmit = (fieldValues) => {
    dispatch(submitRuleFeedbackRequest(ruleId, fieldValues.feedback));
    dispatch(removeModalView());
  };

  return (
    <>
      <p>{infoMessages.rule_feedback_info}</p>
      <Form
        form={form}
        name="rule_feedback_form"
        onFinish={handleSubmit}
        scrollToFirstError={true}
        layout="vertical"
      >
        <Form.Item name="feedback" label="Description">
          <Input.TextArea
            id="feedback"
            placeholder="Enter feedback"
            className="mb-10"
            rows={5}
          />
        </Form.Item>
        <Form.Item>
          <div className="justify-end">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default FeedbackForm;
