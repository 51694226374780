import TextNode from "../../components/common/nodes/TextNode";
import TextAnnotation from "../../components/pages/analysis/TextAnnotation";
import { entityClass, TEXT_PARENT } from "../../constants";

const Node = ({ text, index, type = "", meta }) => (
  <TextNode index={index} meta={meta} className={type} type={TEXT_PARENT}>
    {text}
  </TextNode>
);

/**
 * Annotation class
 *
 * @class
 *
 */
class Annotation {
  static constructNodes(entitiesTree, block, processingEntities) {
    const text = block.text || "";
    let nextStartIndex = 0;
    let position = 0;
    let key = 0;

    let nodes = [];

    entitiesTree.getTree().forEach((node) => {
      const { start, end, iStart, iEnd } = node;
      const begin = nextStartIndex;
      nextStartIndex = iEnd || end;
      const meta = JSON.stringify({ start: begin, end: start });

      nodes.push(
        <Node
          key={key++}
          index={position++}
          text={text.slice(begin, iStart || start)}
          meta={meta}
        />
      );

      nodes.push(
        <TextAnnotation
          key={key++}
          position={position++}
          text={text}
          entity={node}
          textData={{}}
          block={block}
          processingEntities={processingEntities}
        />
      );
    });

    const endMeta = JSON.stringify({ start: nextStartIndex, end: text.length });

    nodes.push(
      <Node
        key={key}
        index={position}
        text={text.slice(nextStartIndex)}
        meta={endMeta}
      />
    );

    return nodes;
  }

  static singleNode(
    text,
    entity,
    { truncateBeforeEntityText = false, header } = {}
  ) {
    const { start_point, end_point, type } = entity;

    if (entity.class === entityClass.SENTENCE) {
      return (
        <>
          {header && <h5 className="header-text">{header}</h5>}
          <span className={type}>{text}</span>
        </>
      );
    }

    const beforeEntityText = text.slice(0, start_point);
    const afterEntityText = text.slice(end_point + 1);
    const entityText = text.slice(start_point, end_point + 1);

    return (
      <>
        {header && <h5 className="header-text">{header}</h5>}
        {!truncateBeforeEntityText && beforeEntityText}
        <strong>
          <span className={type}>{entityText}</span>
        </strong>
        {afterEntityText}
      </>
    );
  }
}

export default Annotation;
