import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Input, Button } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import ActionTip from "../../../common/widgets/ActionTip";
import { handleEnableEdit, handleKeyPress, handleSaveTitle } from "./handlers";
import useAxios from "../../../../hooks/useAxios";
import { useReloadAnalysis } from "../../../../hooks/useReload";
import SkeletonLoader from "../../../common/loaders/SkeletonLoader";
import api from "../../../../constants/api";
import { useMessageBoxError } from "../../../../hooks/useMessageBox";

const HeaderTitle = ({ initialLoading }) => {
  const [titleText, setTitleText] = useState(null);
  const {
    analysis,
    loader: { loading },
  } = useSelector((state) => state);
  const { title = "", id } = analysis.document;

  const titleInputRef = useRef(null);

  const [{ result, error }, updateDocRequest] = useAxios(null, api.methods.PUT);
  useReloadAnalysis(result);
  useMessageBoxError(error);

  useEffect(() => {
    setTitleText(null);
  }, [title]);

  const isEditTitle = titleText !== null;
  const inputProps = isEditTitle
    ? {
        hidden: false,
        addonAfter: (
          <>
            <ActionTip title="Click or press Enter key to save">
              <Button
                size="small"
                type="default"
                shape="circle"
                className="f-green-d"
                onClick={handleSaveTitle(
                  id,
                  titleText,
                  title,
                  setTitleText,
                  updateDocRequest
                )}
                disabled={loading}
              >
                <CheckOutlined />
              </Button>
            </ActionTip>{" "}
            <ActionTip title="Click or press ESC key to cancel">
              <Button
                className="f-red"
                size="small"
                type="default"
                shape="circle"
                onClick={() => {
                  setTitleText(null);
                }}
              >
                <CloseOutlined />
              </Button>
            </ActionTip>
          </>
        ),
      }
    : {};

  return (
    <>
      {initialLoading && (
        <SkeletonLoader width="400px" height="14px" borderRadius="5px" />
      )}
      <span
        onClick={
          !isEditTitle
            ? handleEnableEdit(title, titleInputRef, setTitleText)
            : null
        }
        className="analysis-header-title-text"
        style={{ display: `${isEditTitle ? "block" : "inline"}` }}
      >
        <ActionTip title="Click to rename">
          <span
            style={{
              visibility: `${isEditTitle ? "hidden" : "visible"}`,
            }}
            className="text-label"
          >
            {title}
          </span>
        </ActionTip>
        <Input
          ref={titleInputRef}
          className="analysis-header-title-input"
          value={titleText}
          onChange={(e) => setTitleText(e.target.value)}
          onKeyDown={handleKeyPress(setTitleText)}
          onPressEnter={handleSaveTitle(
            id,
            titleText,
            title,
            setTitleText,
            updateDocRequest
          )}
          hidden
          {...inputProps}
        />
      </span>
    </>
  );
};

export default HeaderTitle;
