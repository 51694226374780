import "../../../assets/styles/organisation.scss";
import DetailsPane from "./DetailsPane";
import SummaryPane from "./SummaryPane";

const Organisation = () => {
  return (
    <>
      <div id="organisation">
        <SummaryPane />
        <DetailsPane />
      </div>
    </>
  );
};

export default Organisation;
