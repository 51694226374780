import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useDocumentContext } from "../../../contexts/DocumentContext";
import { documentFilters } from "../../../constants";
import {
  getAllCollectionsRequest,
  setCollections,
} from "../../../store/actions/collections";
import { useReload } from "../../../hooks/useReload";

const { Option } = Select;
const collectionData = {
  "Imported by": ["Me", "All"],
  globalAccess: ["All", "Private", "Organisation"],
};

/**
 * Filters - Collections, Imported by, and Access
 *
 * @function
 *
 * @returns React JSX
 */
export const Filters = () => {
  const { setDocumentData } = useDocumentContext();
  const userDetails = useSelector(({ user }) => user.details);
  const [importedBy, setImportedBy] = useState("All");

  const dispatch = useDispatch();
  const { collectionList, getAllCollections: gac } = useSelector(
    ({ collections }) => collections
  );
  useReload(gac.result, () => dispatch(setCollections(gac.result.data)));
  useEffect(() => dispatch(getAllCollectionsRequest()), []);

  const handleCollectionChange = (value) => {
    setDocumentData((documentData) => ({
      ...documentData,
      [documentFilters.COLLECTION]: value,
    }));
  };

  const onImportdByChange = (value) => {
    setImportedBy(value);
    setDocumentData((documentData) => ({
      ...documentData,
      [documentFilters.IMPORTED_BY]: value === "Me" ? userDetails?.id : "",
    }));
  };

  const handleAccessChange = (value) => {
    if (value === "Private") {
      setDocumentData((prevState) => ({
        ...prevState,
        [documentFilters.ACCESS]: "private",
      }));
    } else if (value === "Organisation") {
      setDocumentData((prevState) => ({
        ...prevState,
        [documentFilters.ACCESS]: "organisation",
      }));
    } else if (value === "All") {
      setDocumentData((prevState) => ({
        ...prevState,
        [documentFilters.ACCESS]: "",
      }));
    }
  };

  return (
    <div className="filter">
      <div>
        <div className="filter-title">Collection</div>
        <Select
          defaultValue={"All"}
          onChange={handleCollectionChange}
          className="filter-selector"
        >
          {" "}
          <Option key={0} value={""}>
            {"All"}
          </Option>
          {collectionList.map(({ title, id }) => (
            <Option key={id} value={id}>
              {title}
            </Option>
          ))}
        </Select>
      </div>
      <div>
        <div className="filter-title">Imported by</div>
        <Select
          value={importedBy}
          onChange={onImportdByChange}
          className="filter-selector"
        >
          {collectionData["Imported by"].map((importedBy) => (
            <Option key={importedBy}>{importedBy}</Option>
          ))}
        </Select>
      </div>
      <div>
        <div className="filter-title">Access</div>
        <Select
          defaultValue={"All"}
          onChange={handleAccessChange}
          className="filter-selector"
        >
          {collectionData.globalAccess.map((access) => (
            <Option key={access}>{access}</Option>
          ))}
        </Select>
      </div>
    </div>
  );
};
