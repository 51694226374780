import { Upload, Button, Form, message, Collapse } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import useAxios from "../../../hooks/useAxios";
import api from "../../../constants/api";
import { useState } from "react";
import { useAddMultipleDocs } from "../../../hooks/useDocumentsUpdate";
import { documentStatus, documentAccessLevels } from "../../../constants";
import errorMessages from "../../../utils/messages/errorMessages";
import infoMessages from "../../../utils/messages/infoMessages";
import { AccessLevelButton } from "./AccessLevelButton";
import { DocumentEntityTypes } from "./DocumentEntityTypes";
import { knownEntityTypes } from "../../../constants/entityTypes";
import STIXDropDownHearder from "./STIXDropDownHearder";
import UploadListItem from "./UploadListItem";
import {
  useMessageBoxError,
  useMessageBoxSuccess,
} from "../../../hooks/useMessageBox";
import messageBox from "../../../utils/services/MessageBox";

const FILE_FIELD = "documents";
const FILE_TYPES = [".pdf", ".html", ".htm"];
const MAX_COUNT = 5;
const { Panel } = Collapse;
const defaultCheckedList = Object.values(knownEntityTypes);

const { supported_documents } = infoMessages;

const flashMaxFilesMessage = debounce(
  () =>
    message.warn(`Only ${MAX_COUNT} of the selected document will be uploaded`),
  1000
);

/**
 * A form component for dragging and uploading files
 *
 * @function
 * @returns React Virtual DOM
 */
const FileImport = () => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [{ result, error }, uploadFile] = useAxios(null, "POST", {
    contentType: api.contentType.FORM_DATA,
  });
  const [form] = Form.useForm();
  const [accessLevelType, setAccessLevelType] = useState(
    documentAccessLevels.ORGANISATION
  );
  const [entityList, setEntityList] = useState([...defaultCheckedList]);
  const addMultipleDocs = useAddMultipleDocs();
  useMessageBoxSuccess(result);
  useMessageBoxError(error);

  const handleSubmit = async () => {
    if (!files.length) return messageBox.showError(errorMessages.select_files);
    setLoading(true);
    await Promise.allSettled(
      files.map(async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("access_level", accessLevelType);
        formData.append("entity_list", entityList);
        formData.append("all_entity_list", defaultCheckedList);

        const res = await uploadFile(api.UPDLOAD_FILE, formData);
        if (res?.result) {
          addMultipleDocs(
            res.result,
            { title: file.name },
            documentStatus.QUEUE
          );
        }
      })
    );

    setLoading(false);
    form.resetFields();
    setFiles([]);
  };

  const getDocumentAccessType = (docType) => {
    setAccessLevelType(docType);
  };

  const getCheckedEntityList = (list) => {
    const actualEntityTypes = list.map(
      (entityType) => `${knownEntityTypes[entityType]}`
    );
    setEntityList(actualEntityTypes);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const beforeUpload = (formFile) => {
    setFiles((prevState) => {
      if (prevState.length >= MAX_COUNT) flashMaxFilesMessage();

      return prevState.length >= MAX_COUNT
        ? prevState.slice(0, MAX_COUNT)
        : [...prevState, formFile];
    });
    return false;
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Form.Item>
        <Button
          size="small"
          type="primary"
          shape="round"
          htmlType="submit"
          loading={loading}
          block
        >
          Add to queue
        </Button>
      </Form.Item>
      <Form.Item>
        <Form.Item
          name={FILE_FIELD}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true, message: errorMessages.select_files }]}
          // noStyle
        >
          <Upload.Dragger
            name="files"
            beforeUpload={beforeUpload}
            listType="picture"
            maxCount={MAX_COUNT}
            accept={FILE_TYPES.join(" ,")}
            multiple
            style={{ marginBottom: "16px" }}
            itemRender={(a, file, fileList, actions) => {
              return (
                <UploadListItem
                  files={files}
                  setFiles={setFiles}
                  file={file}
                  fileList={fileList}
                  actions={actions}
                />
              );
            }}
          >
            <div className="upload-view">
              <span>
                <PlusCircleOutlined className="plus-circle-outlined" />
              </span>
              <div className="upload-view-wrapper">
                <h2 className="upload-view-title">Choose your files</h2>
                <span>or drag them into workspace</span>
                <p className="ant-upload-hint">{supported_documents}</p>
                <p className="ant-upload-hint">
                  (Maximum of{" "}
                  <strong>
                    <em>{MAX_COUNT} files</em>
                  </strong>{" "}
                  in a single upload)
                </p>
              </div>
            </div>
          </Upload.Dragger>
        </Form.Item>
      </Form.Item>
      <AccessLevelButton getDocumentAccessType={getDocumentAccessType} />
      <Collapse>
        <Panel header={<STIXDropDownHearder entityCount={entityList.length} />}>
          <DocumentEntityTypes checkedEntityList={getCheckedEntityList} />
        </Panel>
      </Collapse>
    </Form>
  );
};

export default FileImport;
