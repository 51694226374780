import { useDispatch, useSelector } from "react-redux";
import { removeModalView } from "../../../store/actions";
import AppModal from "../modals/AppModal";

const ModalView = (componentProps) => {
  const dispatch = useDispatch();

  const { modal } = useSelector(({ selection }) => selection);
  const { ContentNode, props = {}, modalProps = {} } = modal;

  return (
    <AppModal
      title=""
      onCancel={() => dispatch(removeModalView())}
      visible={modal.show}
      footer={null}
      {...componentProps}
      {...modalProps}
    >
      {!!ContentNode ? <ContentNode {...props} /> : null}
    </AppModal>
  );
};

export default ModalView;
