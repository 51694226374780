import { useEffect } from "react";
import { batch, useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import AppLayout from "../components/common/layouts/AppLayout";
import AuthLoader from "../components/common/loaders/AuthLoader";
import routes from "../constants/routes";
import { useAuthContext } from "../contexts/AuthContext";
import AppErrorBoundary from "../errorBoundaries/AppErrorBoundary";
import { useVisitedPageTracking } from "../hooks/useTracking";
import { REMOVE_SLIDE_IN_VIEW, STOP_LOADING } from "../store/actions";
import { permitRouteAccess } from "../utils/helpers/analysis";

const WithLayout = ({ children }) => (
  <AppLayout>
    <AppErrorBoundary>{children}</AppErrorBoundary>
  </AppLayout>
);

/**
 * AppRoutesHOC
 * an HOC for injecting route components in layout
 */
const AppRoutesHOC = ({
  Component,
  pageName,
  trackPage,
  permissions,
  ...otherProps
}) => {
  const { auth } = useAuthContext();
  useVisitedPageTracking({ pageName, trackPage });
  const dispatch = useDispatch();

  // Clear loader and slidein before moving to another page
  useEffect(() => {
    return () =>
      batch(() => {
        dispatch({ type: REMOVE_SLIDE_IN_VIEW });
        dispatch({ type: STOP_LOADING });
      });
  }, []);

  const userRoles = auth && auth.realmAccess ? auth.realmAccess.roles : [];
  const userHasPermission = permitRouteAccess(permissions, userRoles);

  return (
    <Route
      {...otherProps}
      render={(props) => {
        const { location } = props;
        const { pathname } = location;
        if (!auth) {
          return (
            <WithLayout>
              <AuthLoader redirectPath={pathname} />
            </WithLayout>
          );
        } else if (pathname.startsWith(routes.ERROR)) {
          return (
            <WithLayout>
              <Component {...props} />
            </WithLayout>
          );
        } else if (!auth.authenticated) {
          const { title, subTitle, action, meta } = auth;
          return (
            <Redirect
              to={{
                pathname: routes.NOT_AUTHENTICATED,
                state: { title, subTitle, action, meta },
              }}
            />
          );
        } else if (!userHasPermission) {
          return (
            <Redirect
              to={{
                pathname: routes.FORBIDDEN,
                state: { pageName },
              }}
            />
          );
        } else {
          return (
            <WithLayout>
              <Component {...props} />
            </WithLayout>
          );
        }
      }}
    />
  );
};

export default AppRoutesHOC;
