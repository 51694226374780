import Overlay from "../../common/widgets/Overlay";
import SpinnerLoader from "../../common/widgets/SpinnerLoader";

/**
 * PageLoader - full page loader
 *
 * @function
 * @param {string} transparency - Transparency of loader. Accepts light, dark, lighter or darker. Defaults to lighter.
 * @returns React JSX
 */
const PageLoader = ({ transparency = "light" }) => {
  const transparent = {
    dark: "0.4",
    lighter: "0.1",
    light: "0.2",
    darker: "0.6",
  }[transparency];

  return (
    <Overlay
      centerContent={true}
      content={
        <div style={{ marginLeft: "-100px", marginTop: "-80px" }}>
          <SpinnerLoader />
        </div>
      }
      backgroundColor={`rgba(0, 0, 0, ${transparent})`}
    />
  );
};

export default PageLoader;
