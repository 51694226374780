import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { ADD_LOADER, REMOVE_LOADER, STOP_LOADING } from "../store/actions";
import { getQueryParams } from "../utils/helpers";
import {
  normalizeAPIError,
  normalizeAPIResult,
} from "../utils/helpers/reduxStore";

/**
 * A hook for making api request
 *
 * @function
 * @param {*} defaultValue - A default value for result.
 * @param {func} requestFunc - An async function for http request.
 * @returns {array}
 */
const useRequest = (defaultValue, requestFunc, options) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({ data: defaultValue });
  const [error, setError] = useState(null);
  const [statusCode, setStatusCode] = useState(0);
  const [reqId, setReqId] = useState("");

  const dispatch = useDispatch();

  const { pageLoader = true } = options;

  const sendRequest = useCallback(
    async (...args) => {
      setLoading(true);
      if (pageLoader) dispatch({ type: ADD_LOADER });

      try {
        const response = await requestFunc(...args);
        const normalizedResult = normalizeAPIResult(response.data);
        setResult(normalizedResult);
        setReqId(() => getQueryParams(response.config.url)["reqId"]);
        setStatusCode(response.status);
        setLoading(false);
        if (pageLoader) dispatch({ type: REMOVE_LOADER });
        return { result: normalizedResult };
      } catch (err) {
        const { reqId, ...errorPayload } = normalizeAPIError(err);
        setError(errorPayload);
        setReqId(reqId);
        setLoading(false);
        if (pageLoader) dispatch({ type: REMOVE_LOADER });
      }
    },
    [requestFunc]
  );

  const reset = useCallback(() => {
    setLoading(false);
    dispatch({ type: STOP_LOADING });
    setResult({ data: defaultValue });
    setError(null);
  }, [defaultValue]);

  return [{ loading, result, error, reqId, statusCode }, sendRequest, reset];
};

export default useRequest;
