import { createContext, useContext } from "react";

const AnalysisContext = createContext();

export const AnalysisProvider = AnalysisContext.Provider;

export function useAnalysisContext() {
  const context = useContext(AnalysisContext);
  if (!context) {
    throw new Error(
      `Analysis compound components cannot be rendered outside the Analysis context`
    );
  }
  return context;
}
