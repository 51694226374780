import { Modal, notification } from "antd";
import LeaderLine from "leader-line-new";
import { batch } from "react-redux";
import stixConfig from "../../../configs/stix";
import {
  entityRole,
  RELATED_NODE,
  SCROLL_THROTTLE_MS,
  storageIds,
} from "../../../constants";
import {
  addProcessingEntities,
  NEW_RELATIONSHIP_RESET,
  removeActiveSelection,
  removeSlideInView,
  setRelationshipProps,
  startNewRelationship,
} from "../../../store/actions";
import { addRelationshipRequest } from "../../../store/actions/apiRequests";
import infoMessages from "../../messages/infoMessages";
import { relationshipTypesModalContent } from "../../messages/popup/relationship";
import { storage } from "../../services/StaticStorage";
import {
  validateEntitiesInNewRelationship,
  validateFirstEntityInRelationship,
} from "../../validators.js/stixValidators";
const { SOURCE, TARGET } = entityRole;

export const startRelationshipProcess = (
  firstEntity,
  firstEntityRole,
  dispatch
) => {
  if (validateFirstEntityInRelationship(firstEntity, firstEntityRole)) {
    batch(() => {
      dispatch(startNewRelationship({ firstEntity }));
      dispatch(removeActiveSelection());
      dispatch(removeSlideInView());
    });

    const secondEntityRole = firstEntityRole === SOURCE ? TARGET : SOURCE;
    notification.open({
      message: <h4>{infoMessages.new_relationship}</h4>,
      description: (
        <span>
          Click on the{" "}
          <strong>
            <em>{secondEntityRole} entity </em>
          </strong>{" "}
          on the panel below
        </span>
      ),
      placement: "topLeft",
      duration: 6,
    });
  } else {
    dispatch({ type: NEW_RELATIONSHIP_RESET });
  }
};

// Send API request to add a new relationship between two entities
const newRelationsipRequest = (document_id, dispatch) => (source, target) => {
  const sendRequest = () => {
    const type = storage.get(storageIds.RELATIONSHIP_TYPE);
    const payload = { source_id: source.id, target_id: target.id, type };
    batch(() => {
      dispatch(addRelationshipRequest(document_id, payload));
      dispatch(addProcessingEntities({ [source.id]: true, [target.id]: true }));
      dispatch({ type: NEW_RELATIONSHIP_RESET });
    });

    storage.delete(storageIds.RELATIONSHIP_TYPE);
  };

  const { sourceTargetRelationTypes } = stixConfig["stix2_1"];
  const types = sourceTargetRelationTypes[
    `${source.type}:-:${target.type}`
  ] || ["related-to"];

  if (types.length > 1) {
    return Modal.confirm(
      relationshipTypesModalContent(types, dispatch, sendRequest)
    );
  }

  storage.save(storageIds.RELATIONSHIP_TYPE, types[0]);

  sendRequest();
};

/* Add a new relationship between two entities */
export const completeRelationshipProcess = (
  secondEntity,
  document_id,
  newRelation,
  dispatch
) => {
  const { role, firstEntity } = newRelation;
  firstEntity.role = role;
  firstEntity.first = true;
  secondEntity.role =
    role === entityRole.SOURCE ? entityRole.TARGET : entityRole.SOURCE;
  secondEntity.second = true;

  const [source, target] =
    role === entityRole.SOURCE
      ? [firstEntity, secondEntity]
      : [secondEntity, firstEntity];

  const newRequest = newRelationsipRequest(document_id, dispatch);
  const isValid = validateEntitiesInNewRelationship(
    source,
    target,
    newRequest,
    dispatch
  );

  if (isValid) newRequest(source, target);
};

/* Map node id to node data for easy access */
export const mapEntityRelations = (relationships) => {
  const entityRelations = {};
  relationships.forEach(({ source_id, target_id, id }) => {
    // Map source entity id to list of relationship ids
    if (!entityRelations[source_id]) {
      entityRelations[source_id] = [id];
    } else {
      entityRelations[source_id].push(id);
    }

    // Map target entity id to list of relationship ids
    if (!entityRelations[target_id]) {
      entityRelations[target_id] = [id];
    } else {
      entityRelations[target_id].push(id);
    }
  });

  return entityRelations;
};

/* Check if an entity is below the other entity in a relationship */
const checkIfEntityBelow = (entityId, otherEntityId, entitiesByIds) => {
  return (
    entitiesByIds[entityId].block_idx > entitiesByIds[otherEntityId].block_idx
  );
};

const HIDE_LINE_STYLE = "hide-svg-lines";

/* Hide relationship lines with style sheet */
export const appendHiddenLineStyle = () => {
  let sheet = document.getElementById(HIDE_LINE_STYLE);
  if (sheet) return;
  sheet = document.createElement("style");
  sheet.id = HIDE_LINE_STYLE;
  sheet.innerHTML = `svg.leader-line {
    visibility: hidden;
  }`;
  document.body.appendChild(sheet);
};

/* Make relationship lines visible by removing the hidden visibility style sheet */
export const removeHiddenLineStyle = () => {
  const sheet = document.getElementById(HIDE_LINE_STYLE);
  if (sheet) document.body.removeChild(sheet);
};

/* Reposition relationship lines and make them visible */
export const positionLines = (props) => () => {
  Object.values(props).forEach(({ lineNode }) => {
    if (lineNode && lineNode._id && lineNode.start && lineNode.end)
      lineNode.position();
  });
  removeHiddenLineStyle();
};

/* Creates a scroll handler hiding, showing and repositioning relationships lines when scrolling starts or stops */
export const scrollingIntervals = (beginCB, endCB) => {
  let scrolling;
  return () => {
    if (!scrolling) beginCB();
    clearTimeout(scrolling);

    scrolling = setTimeout(() => {
      endCB();
      scrolling = undefined;
    }, SCROLL_THROTTLE_MS);
  };
};

export function handleScrollLines(relationLines) {
  relationLines.forEach((line) => line.position());
}

/* Maps entity id to it's DOM element for easy access */
export const mapEntityIdToElements = (relatedNodes) => {
  const map = {};
  relatedNodes.forEach((element) => {
    const { id, ids } = JSON.parse(element.dataset.meta);
    if (ids && ids.length) {
      // Account for the multiple entities (especially attack pattern)
      // displayed on a single node
      ids.forEach((i) => (map[i] = element));
    } else {
      map[id] = element;
    }
  });
  return map;
};

/* Maps each relationship to their respective properties and create relationship lines */
export const createRelationshipProps = (
  relationsRef,
  entitiesRef,
  dispatch
) => {
  const { relations = [], props = {}, noDraw } = relationsRef.current || {};
  if (noDraw) return;
  removeHiddenLineStyle();
  if (!relations || !relations.length) return;

  const entitiesByIds = entitiesRef.current;

  const newProps = {};
  const lineOptions = {
    color: "rgba(169, 239, 30, 0.7)",
    size: 2,
    startPlug: "behind",
    endPlug: "arrow1",
    // path: "arc",
    startSocket: "top",
    endSocket: "bottom",
    startPlugColor: "rgba(169, 239, 30, 0.7)",
    endPlugColor: "rgba(255, 127, 80, 0.7)",
    gradient: true,
  };

  try {
    Object.values(props).forEach(({ lineNode }) => {
      if (lineNode) lineNode.remove();
    });

    const topAnchor = document.getElementById("top-relationship-anchor");
    const bottomAnchor = document.getElementById("bottom-relationship-anchor");
    const entityElements = document.querySelectorAll(`.${RELATED_NODE}`);
    const entityIdToElements = mapEntityIdToElements(entityElements);

    LeaderLine.positionByWindowResize = false;

    for (let i = 0; i < relations.length; i++) {
      const { id, source_id, target_id, type } = relations[i];

      newProps[id] = { id, source_id, target_id, index: i, type };

      let sourceElement = entityIdToElements[source_id];
      let targetElement = entityIdToElements[target_id];

      if (!sourceElement && !targetElement) continue;

      let isBelow;
      if (!sourceElement) {
        isBelow = checkIfEntityBelow(source_id, target_id, entitiesByIds);
        sourceElement = isBelow ? bottomAnchor : topAnchor;
      }

      if (!targetElement) {
        isBelow = checkIfEntityBelow(target_id, source_id, entitiesByIds);
        targetElement = isBelow ? bottomAnchor : topAnchor;
      }

      if (sourceElement && targetElement) {
        newProps[id]["lineNode"] = new LeaderLine(
          sourceElement,
          targetElement,
          lineOptions
        );
      }
    }
    dispatch(setRelationshipProps(newProps));
    removeHiddenLineStyle();
  } catch (error) {
    // console.log("error", error);
  }
};

export const mapRelationshipsByIds = (relationships) => {
  const relationshipsByIds = {};
  relationships.forEach(
    (r, index) => (relationshipsByIds[r.id] = { ...r, index })
  );
  return relationshipsByIds;
};

export const getRelationshipIdsFromEntityIds = (entityIds, entityRelations) => {
  let relationshipIds = [];
  entityIds.forEach((entityId) => {
    if (entityRelations[entityId])
      relationshipIds = relationshipIds.concat(entityRelations[entityId]);
  });

  return relationshipIds;
};
