import React from "react";
import { Menu } from "antd";
import { useHistory } from "react-router-dom";
import WarningDialog from "../../common/widgets/WarningDialog";
import ActionTip from "../../common/widgets/ActionTip";
import { handleDeleteDocument } from "../../../utils/helpers";
import { useDispatch } from "react-redux";
import DocumentCollections from "../collections/DocumentCollections";
import { showSlideInView } from "../../../store/actions";
import routes from "../../../constants/routes";

export const MoreMenu = ({ document, updateDoc }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { document_id } = document;

  const analysisUrl = routes.DOCUMENT_ANALYSIS.replace(
    ":documentId",
    document_id
  );
  const graphUrl = routes.STIX_GRAPH.replace(":documentId", document_id);

  let sourceUrl = document.source;
  if (!sourceUrl || `${document.source_type}`.toLowerCase() !== "url") {
    sourceUrl = routes.DOCUMENT_SOURCE.replace(":documentId", document_id);
  }
  return (
    <Menu className="menu-dropdown-head">
      <Menu.Item
        className="menu-item-dropdown"
        onClick={() => {
          history.push(analysisUrl);
        }}
        key="annotation-view"
      >
        Annotation view
      </Menu.Item>
      <Menu.Item
        className="menu-item-dropdown"
        onClick={() => {
          history.push(graphUrl);
        }}
        key="graph-view"
      >
        Graph view
      </Menu.Item>
      <Menu.Item key="view-source" className="menu-item-dropdown">
        <a rel="noopener noreferrer" target="_blank" href={sourceUrl}>
          View source
        </a>
      </Menu.Item>
      <Menu.Item
        className="menu-item-dropdown"
        key="manage-collections"
        onClick={() => {
          dispatch(
            showSlideInView(
              DocumentCollections,
              { documentId: document_id, documentTitle: document.title },
              { title: "Manage Collections" }
            )
          );
        }}
      >
        Manage Collections
      </Menu.Item>

      <Menu.Item key="delete">
        <WarningDialog
          title="Are you sure you want to remove this document?"
          onConfirm={() => handleDeleteDocument(document_id, updateDoc)}
          okText="Yes"
          cancelText="No"
        >
          <ActionTip title="Remove document">
            <span className="menu-item-dropdown-delete">Delete</span>
          </ActionTip>
        </WarningDialog>
      </Menu.Item>
    </Menu>
  );
};

export const MoreMenu1 = ({ document, updateDoc }) => {
  const history = useHistory();
  const { document_id } = document;

  const analysisUrl = routes.DOCUMENT_ANALYSIS.replace(
    ":documentId",
    document_id
  );
  const graphUrl = routes.STIX_GRAPH.replace(":documentId", document_id);
  let sourceUrl = document.source;
  if (!sourceUrl || `${document.source_type}`.toLowerCase() !== "url") {
    sourceUrl = routes.DOCUMENT_SOURCE.replace(":documentId", document_id);
  }
  return (
    <Menu className="menu">
      <Menu.Item
        className="menu-item-dropdown"
        onClick={() => {
          history.push(analysisUrl);
        }}
        key="annotation-view"
      >
        Annotation view
      </Menu.Item>
      <Menu.Item
        className="menu-item-dropdown"
        onClick={() => {
          history.push(graphUrl);
        }}
        key="graph-view"
      >
        Graph view
      </Menu.Item>
      <Menu.Item key="view-source" className="menu-item-dropdown">
        <a rel="noopener noreferrer" target="_blank" href={sourceUrl}>
          View source
        </a>
      </Menu.Item>
      <Menu.Item key="delete">
        <WarningDialog
          title="Are you sure you want to remove this document?"
          onConfirm={() => handleDeleteDocument(document_id, updateDoc)}
          okText="Yes"
          cancelText="No"
        >
          <ActionTip title="Remove document">
            <span className="menu-item-dropdown-delete">Delete</span>
          </ActionTip>
        </WarningDialog>
      </Menu.Item>
    </Menu>
  );
};

export default { MoreMenu, MoreMenu1 };
