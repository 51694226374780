import { useDispatch } from "react-redux";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import RulesTable from "./RulesTable";
import { showRuleForm } from "./helpers";
import infoMessages from "../../../utils/messages/infoMessages";

const RulesManager = () => {
  const dispatch = useDispatch();

  return (
    <div>
      <div className="app-card">
        <div className="app-card-header">
          <div className="justify-between">
            <h2>Rules manager </h2>
            <div>
              <Button
                type="primary"
                onClick={() => showRuleForm(null, dispatch)}
              >
                <PlusOutlined /> Create new rule
              </Button>
            </div>
          </div>
          <div>
            <p style={{ maxWidth: "600px" }}>
              {infoMessages.rules_manager_info}
            </p>
          </div>
        </div>
        <div className="app-card-body">
          <div className="section-card py-15">
            <h3 className="section-card-header justify-end py-10"></h3>
            <div className="section-card-body">
              <RulesTable data={[]} columns={[]} activeTab={[]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RulesManager;
