import React, { useEffect, useMemo, useState } from "react";
import "antd/dist/antd.css";
import { FilterOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getAnalysisDetails } from "../../../store/actions/apiRequests";
import { confidenceScoreModes, storageIds } from "../../../constants";
import { storage } from "../../../utils/services/StaticStorage";

const { ALMOST_CERTAIN, VERY_LIKELY, PROBABLE } = confidenceScoreModes;
const initialFilters = Object.values(confidenceScoreModes);

const ConfidenceScoreFilter = () => {
  const dispatch = useDispatch();
  const {
    document: { id },
  } = useSelector(({ analysis }) => analysis);
  const [visible, setVisible] = useState(false);

  const [confidenceFilters, setConfidenceFilters] = useState(initialFilters);

  const onChange = (e) => {
    setConfidenceFilters((cf) => {
      if (e.target.checked) return [...cf, e.target.name];
      return cf.filter((f) => f !== e.target.name);
    });
  };

  const debounceDispatchFilter = useMemo(() => {
    return _.debounce(() => {
      if (id) dispatch(getAnalysisDetails(id));
    }, 1000);
  }, [dispatch, confidenceFilters]);

  useEffect(() => {
    storage.save(storageIds.CONFIDENCE_FILTER, confidenceFilters);
    if (id) debounceDispatchFilter();
    return () => storage.delete(storageIds.CONFIDENCE_FILTER);
  }, [confidenceFilters, dispatch, id]);

  const menu = (
    <Menu className="confidence-score-list">
      <Menu.Item key={ALMOST_CERTAIN}>
        <span>
          {" "}
          <Checkbox
            className="confidence-score-checkbox"
            checked={confidenceFilters.includes(ALMOST_CERTAIN)}
            onChange={onChange}
            name={ALMOST_CERTAIN}
          >
            Almost certain{" "}
          </Checkbox>
        </span>
      </Menu.Item>
      <Menu.Item key={VERY_LIKELY}>
        <span>
          {" "}
          <Checkbox
            className="confidence-score-checkbox"
            checked={confidenceFilters.includes(VERY_LIKELY)}
            onChange={onChange}
            name={VERY_LIKELY}
          >
            Very likely{" "}
          </Checkbox>
        </span>
      </Menu.Item>
      <Menu.Item key={PROBABLE}>
        <span>
          {" "}
          <Checkbox
            className="confidence-score-checkbox"
            checked={confidenceFilters.includes(PROBABLE)}
            onChange={onChange}
            name={PROBABLE}
          >
            Probable{" "}
          </Checkbox>
        </span>
      </Menu.Item>
    </Menu>
  );

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };
  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      onVisibleChange={handleVisibleChange}
      visible={visible}
    >
      <button
        className="confidence-btn-trigger"
        onClick={(e) => e.preventDefault()}
      >
        <Space className="confidence-trigger">
          <FilterOutlined />
          Confidence level
        </Space>
      </button>
    </Dropdown>
  );
};

export default ConfidenceScoreFilter;
