import "../../../../assets/styles/user.scss";
import EditPane from "./EditPane";
import SummaryPane from "./SummaryPane";

const Profile = () => {
  return (
    <>
      <div className="user-profile">
        <SummaryPane />
        <EditPane />
      </div>
    </>
  );
};

export default Profile;
