// import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import Hotjar from "../utils/services/Hotjar";
import Mixpanel from "../utils/services/Mixpanel";

/**
 * useVisitedPageTracking - Tracks visited page
 *
 */
export const useVisitedPageTracking = (props) => {
  return useEffect(() => {
    const { pageName, trackPage } = props;
    if (trackPage) {
      Mixpanel.track(pageName);
      Hotjar.track(pageName.split(" ").join("_"));
    }
  }, []);
};
