const HeaderBottom = ({ initialLoading, documentId, entityProps }) => {
  return (
    <div id="indicators-bar">
      {documentId && (
        <ul className="entity-indicators">
          {Object.values(entityProps).map((prop, index) => (
            <li key={index}>
              <span className={`color-box ${prop.value}`}></span> {prop.label}
              {!initialLoading && `(${prop.count})`}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default HeaderBottom;
