import { useEffect } from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CollectionsTable from "./CollectionsTable";
import { showSlideInView } from "../../../store/actions";
import CollectionForm from "./CollectionForm";
import { useDispatch } from "react-redux";
import { resetCollectionsState } from "../../../store/actions/collections";

const Collections = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => dispatch(resetCollectionsState());
  }, []);

  return (
    <div style={{ marginTop: "80px" }}>
      <div className="app-card">
        <div className="app-card-header">
          <h2>Collections </h2>
        </div>
        <div className="app-card-body">
          <div className="section-card py-15">
            <h3 className="section-card-header justify-end py-10">
              <Button
                type="primary"
                shape="round"
                onClick={() => {
                  dispatch(
                    showSlideInView(
                      CollectionForm,
                      { collectionId: null },
                      { title: "New Collection", width: 500 }
                    )
                  );
                }}
              >
                <PlusOutlined /> Add Collection
              </Button>
            </h3>
            <div className="section-card-body">
              <CollectionsTable data={[]} columns={[]} activeTab={[]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collections;
