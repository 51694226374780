import { useEffect, useState } from "react";
import { Form, Input, Button, Spin, Radio } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import _ from "lodash";
import useAxios from "../../../hooks/useAxios";
import api from "../../../constants/api";
import CopyButton from "../../common/widgets/CopyButton";
import MaskButton from "../../common/widgets/MaskButton";
import { maskText } from "../../../utils/helpers";
import { useReload } from "../../../hooks/useReload";
import { useMessageBoxError } from "../../../hooks/useMessageBox";

const inputIds = {
  API_KEY: "api_key",
  MISP_API_KEY: "client_misp_api_key",
  MISP_IP: "client_misp_ip_address",
  MISP_ALLOWED_IP: "misp_allow_list_ip_address",
};

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const AccessDetailsForm = ({ editable }) => {
  const [organisationDetails, setOrganisationDetails] = useState(null);
  const [mispAPIKeyMask, setMispAPIKeyMask] = useState(true);

  const [{ loading, result }, getOrganisationData] = useAxios();
  const [
    { loading: updating, result: updateRes, error: updateErr },
    updateOrgRequest,
  ] = useAxios(null, api.methods.PUT);

  useEffect(() => getOrganisationData(api.ORGANISATIONS_ENDPOINTS), []);
  useMessageBoxError(updateErr);
  useReload(result, () => setOrganisationDetails(result.data));
  useReload(updateRes, () => getOrganisationData(api.ORGANISATIONS_ENDPOINTS));

  const { client_misp_api_key, client_misp_ip_address, misp_enabled } =
    organisationDetails || {};

  const maskOptions = { showPrefix: 2, showSuffix: 2 };
  const maskedMispKey = maskText(client_misp_api_key, maskOptions);

  useEffect(() => {
    if (editable) setMispAPIKeyMask(false);
  }, [editable]);

  const handleInputChange = (e) => {
    setOrganisationDetails((data) => ({
      ...data,
      [e.target.id]: e.target.value,
    }));
  };

  const handleSave = () => {
    const payload = {
      client_misp_api_key,
      client_misp_ip_address,
      misp_enabled,
    };
    updateOrgRequest(api.ORGANISATIONS_MISP, payload);
  };

  const isClean = _.isEqual(organisationDetails, result.data);

  return (
    <>
      <div className="section-card">
        <h3 className="section-card-header-bg text-center py-10">MISP</h3>
        <div className="section-card-body justify-center py-30 px-30">
          <div className="form-holder">
            <Form layout="vertical">
              <Form.Item>
                <label className="ant-form label">Push STIX Bundle </label>
                <Radio.Group
                  value={misp_enabled}
                  onChange={({ target }) => {
                    setOrganisationDetails({
                      ...organisationDetails,
                      misp_enabled: target.value,
                    });
                  }}
                >
                  <Radio value={true}>Enable</Radio>
                  <Radio value={false}>Disable</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="Client MISP API Key">
                <Input
                  id={inputIds.MISP_API_KEY}
                  value={mispAPIKeyMask ? maskedMispKey : client_misp_api_key}
                  placeholder="Client MISP API Key"
                  onChange={handleInputChange}
                  disabled={!editable}
                  addonBefore={
                    <MaskButton
                      mask={mispAPIKeyMask}
                      clickHandler={setMispAPIKeyMask}
                    />
                  }
                  addonAfter={<CopyButton text={client_misp_api_key} />}
                />
              </Form.Item>
              <Form.Item label="Client MISP IP Address">
                <Input
                  id={inputIds.MISP_IP}
                  value={client_misp_ip_address}
                  placeholder="Client MISP IP Address"
                  onChange={handleInputChange}
                  disabled={!editable}
                  addonBefore={<Button />}
                  addonAfter={<CopyButton text={client_misp_ip_address} />}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  disabled={isClean || loading}
                  onClick={handleSave}
                >
                  {updating && <Spin indicator={antIcon} />} Save
                </Button>{" "}
                <Button
                  disabled={isClean || loading}
                  onClick={() => setOrganisationDetails(result.data)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessDetailsForm;
