import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Table, Select, Dropdown, Menu, Divider } from "antd";
import {
  PlusOutlined,
  MinusCircleOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import _ from "lodash";
import "../../../../assets/styles/metadata.scss";
import { documentTLPcoloumns } from "./tableColumns";
import { TLP } from "../../../../constants";
import {
  addDocumentTLPRequest,
  deleteEntityGroupTLP,
} from "../../../../store/actions/apiRequests";
import { useReload } from "../../../../hooks/useReload";
import { tlpStyle } from "./handlers";
import messageBox from "../../../../utils/services/MessageBox";

const { Option } = Select;
const initData = { document_tlp: "", entity_groups: [] };

const DocumentTLP = ({ documentId }) => {
  const [tlpData, setTlpData] = useState(initData);
  const dispatch = useDispatch();
  const defaultLevelRef = useRef(null);

  const { analysis, analysisApi } = useSelector((state) => state);
  const { documentTlp, addDocumentTlp, deleteDocumentTlp } = analysisApi;

  useReload(documentTlp.result, () =>
    setTlpData(documentTlp.result.data || initData)
  );

  const [form] = Form.useForm();

  const handleSave = () => {
    const entity_groups = tlpData.entity_groups.filter((e) => !!e.tlp);
    dispatch(addDocumentTLPRequest(documentId, { ...tlpData, entity_groups }));
  };

  const handleKeysValidation = () => {
    messageBox.showError("Please fill all required fields");
  };

  const setGroupLevel = (rowIndex) => (tlp) => {
    const entity_groups = _.cloneDeep(tlpData.entity_groups);
    entity_groups[rowIndex].tlp = tlp;

    setTlpData({ ...tlpData, entity_groups });
  };

  const removeGroup = (record) => {
    if (record.unsaved) {
      return setTlpData((data) => {
        return {
          ...data,
          entity_groups: data.entity_groups.filter(
            (e) => e.group !== record.group
          ),
        };
      });
    }

    dispatch(deleteEntityGroupTLP(documentId, record.group));
  };

  const loading =
    documentTlp.loading || addDocumentTlp.loading || deleteDocumentTlp.loading;

  let entityGroups = Object.values(tlpData.entity_groups).map((e) => e.group);

  const groupMenu = (
    <Menu
      onClick={({ key }) => {
        setTlpData({
          ...tlpData,
          entity_groups: [
            ...tlpData.entity_groups,
            { group: key, tlp: "", unsaved: true },
          ],
        });
      }}
    >
      {Object.values(analysis.entityProps).map((group) => {
        if (entityGroups.includes(group.label)) return null;
        return <Menu.Item key={group.label}>{group.label}</Menu.Item>;
      })}
    </Menu>
  );

  return (
    <div id="document-metadata-pane">
      <div className="flex-column mb-15">
        <strong className="mb-10">Default level </strong>
        <Select
          ref={defaultLevelRef}
          value={tlpData.document_tlp || undefined}
          className="full-width"
          placeholder="Select default TLP level"
          loading={loading}
          disabled={loading}
          onChange={(l) => setTlpData({ ...tlpData, document_tlp: l })}
          dropdownRender={(menu) => (
            <div>
              {menu}
              {!!tlpData.document_tlp && (
                <>
                  <Divider className="py-0 my-0" />
                  <Menu
                    onClick={() => {
                      setTlpData({ ...tlpData, document_tlp: "" });
                      defaultLevelRef.current.blur();
                    }}
                  >
                    <Menu.Item key="" icon={<MinusCircleOutlined />}>
                      Unset Default TLP
                    </Menu.Item>
                  </Menu>
                </>
              )}
            </div>
          )}
        >
          {Object.values(TLP).map((tlpLevel) => (
            <Option key={tlpLevel} value={tlpLevel}>
              TLP:
              <span style={tlpStyle(tlpLevel)}>
                {tlpLevel}{" "}
                {tlpLevel === tlpData.document_tlp && (
                  <CheckCircleFilled size="small" />
                )}
              </span>
            </Option>
          ))}
        </Select>
      </div>
      <Form
        form={form}
        onFinish={handleSave}
        onFinishFailed={handleKeysValidation}
      >
        <Table
          dataSource={tlpData.entity_groups.map((g, key) => ({ ...g, key }))}
          columns={documentTLPcoloumns(
            tlpData.document_tlp,
            setGroupLevel,
            removeGroup
          )}
          loading={loading}
          pagination={false}
          footer={() => (
            <Dropdown overlay={groupMenu} trigger={["click"]}>
              <Button type="link">
                <PlusOutlined /> Add entity group overrides
              </Button>
            </Dropdown>
          )}
        />
        <Form.Item>
          <div className="full-width justify-end">
            <Button
              type="primary"
              htmlType="submit"
              className="mr-10"
              loading={loading}
              disabled={loading}
            >
              Save
            </Button>
            <Button
              disabled={loading}
              onClick={() => {
                setTlpData(documentTlp.result.data || initData);
              }}
            >
              Reset
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DocumentTLP;
