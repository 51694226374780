export const entityProperties = {
  campaign: {
    value: "campaign",
    label: "Campaign",
    color: "#56BC8B",
    subcategories: ["campaign"],
    count: 0,
  },
  location: {
    value: "location",
    label: "Location",
    color: "#03518B",
    subcategories: ["country"],
    count: 0,
  },
  identity: {
    value: "identity",
    label: "Identity",
    color: "#929697",
    subcategories: [
      "individual",
      "group",
      "organization",
      "system",
      "class",
      "unknown",
    ],
    count: 0,
  },
  indicator: {
    value: "indicator",
    label: "Indicator",
    color: "#EF8C35",
    subcategories: [
      "file",
      "email",
      "uri",
      "ipv4",
      "ipv6",
      "hash-sha256",
      "hash-md5",
      "domain",
      "user-agent",
      "mac-address",
      "cryptocurrency-address",
      "credit-card",
    ],
    count: 0,
  },
  malware: {
    value: "malware",
    label: "Malware",
    color: "#D89CC6",
    subcategories: [
      "adware",
      "backdoor",
      "bot",
      "ddos",
      "dropper",
      "exploit-kit",
      "keylogger",
      "ransomware",
      "remote-access-trojan",
      "resource-exploitation",
      "rogue-security-software",
      "rootkit",
      "screen-capture",
      "spyware",
      "trojan",
      "virus",
      "worm",
    ],
    count: 0,
  },
  "threat-actor": {
    value: "threat-actor",
    label: "Threat Actor",
    color: "#EA3353",
    subcategories: [
      "activist",
      "competitor",
      "crime-syndicate",
      "criminal",
      "hacker",
      "insider-accidental",
      "insider-disgruntled",
      "nation-state",
      "sensationalist",
      "spy",
      "terrorist",
    ],
    count: 0,
  },
  tool: {
    value: "tool",
    label: "Tool",
    color: "#5358A6",
    subcategories: [
      "denial-of-service",
      "exploitation",
      "information-gathering",
      "network-capture",
      "credential-exploitation",
      "remote-access",
      "vulnerability-scanning",
    ],
    count: 0,
  },
  vulnerability: {
    value: "vulnerability",
    label: "Vulnerability",
    color: "#F7CA44",
    subcategories: ["vulnerability"],
    count: 0,
  },
  "attack-pattern": {
    value: "attack-pattern",
    label: "Mitre ATT&CK Pattern",
    label2: "ATT&CK Pattern",
    color: "#377CB7",
    subcategories: ["attack-pattern"],
    count: 0,
  },
  "course-of-action": {
    value: "course-of-action",
    label: "Course Of Action",
    label2: "COA",
    color: "#8CC63F",
    subcategories: ["course-of-action"],
    count: 0,
  },
  infrastructure: {
    value: "infrastructure",
    label: "Infrastructure",
    color: "#A1D7BE",
    subcategories: [
      "amplification",
      "anonymization",
      "botnet",
      "command-and-control",
      "exfiltration",
      "hosting-malware",
      "hosting-target-lists",
      "phishing",
      "reconnaissance",
      "staging",
      "unknown",
    ],
    count: 0,
  },
  "intrusion-set": {
    value: "intrusion-set",
    label: "Intrusion Set",
    color: "#52B5C1",
    subcategories: [
      "individual",
      "club",
      "contest",
      "team",
      "organization",
      "government",
    ],
    count: 0,
  },
};
