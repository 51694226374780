import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { removeSlideInView } from "../../../store/actions";

const SlideInView = (componentProps) => {
  const dispatch = useDispatch();

  const { slideIn } = useSelector(({ selection }) => selection);
  const { ContentNode, props = {}, slideInProps = {} } = slideIn;

  return (
    <Drawer
      title=""
      placement="right"
      width={450}
      onClose={() => dispatch(removeSlideInView())}
      visible={slideIn.show}
      mask={false}
      {...componentProps}
      {...slideInProps}
    >
      {!!ContentNode ? <ContentNode {...props} /> : null}
    </Drawer>
  );
};

export default SlideInView;
