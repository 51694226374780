import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { documentFilters, documentStatus } from "../constants";
import api from "../constants/api";
import { useDocumentContext } from "../contexts/DocumentContext";
import { removeSlideInView } from "../store/actions";
import { getAnalysisDetails } from "../store/actions/apiRequests";
import useAxios from "./useAxios";
import { useDocumentsUpdate } from "./useDocumentsUpdate";

const { COMPLETED, FOR_REVIEW, QUEUE, REVIEWING } = documentStatus;
const { GET } = api.methods;
const requestOptions = { pageLoader: false };

/**
 * useReload - Executes callBack base on changes in result
 *
 */
export const useReload = (result, callBack) => {
  useEffect(() => {
    if (result && result.success) {
      callBack();
    }
  }, [result]);
};

/**
 * useRemoveSlideInOnSuccess - Removes SlideIn view on success
 *
 */
export const useRemoveSlideInOnSuccess = (result) => {
  const dispatch = useDispatch();
  useReload(result, () => dispatch(removeSlideInView()));
};

/**
 * useReloadAnalysis - Reloads data from api when supplied result changes and is success
 *
 */
export const useReloadAnalysis = (result, requestId) => {
  const dispatch = useDispatch();

  const documentId = useSelector((state) => state.analysis.document.id);

  useEffect(() => {
    if (!result || result.loading || !result.success) return;

    dispatch(getAnalysisDetails(documentId, requestId));
  }, [result]);
};

/**
 * useColumnReload - reloads documents on columns after changes
 *
 */
export const useDocumentsReloadInterval = (columns = []) => {
  const { documentData } = useDocumentContext();
  const { search_string, creator_user_id, collection_id, access_level } =
    documentData;
  const [{ result: queueRes }, fetchQueue] = useAxios({}, GET, requestOptions);
  const [{ result: forReviewRes }, fetchForReview] = useAxios(
    {},
    GET,
    requestOptions
  );
  const [{ result: reviewingRes }, fetchReviewing] = useAxios(
    {},
    GET,
    requestOptions
  );
  const [{ result: completedRes }, fetchCompleted] = useAxios(
    {},
    GET,
    requestOptions
  );

  useDocumentsUpdate(queueRes, QUEUE);
  useDocumentsUpdate(forReviewRes, FOR_REVIEW, true);
  useDocumentsUpdate(reviewingRes, REVIEWING, true);
  useDocumentsUpdate(completedRes, COMPLETED, true);

  useEffect(() => {
    let timer = setInterval(() => {
      for (let column of columns) {
        if (column === QUEUE) {
          fetchQueue(
            `${
              api.DOCUMENT_API
            }?status=${QUEUE}&page_number=${1}&results_per_page=${50}`
          );
        } else if (column === FOR_REVIEW) {
          fetchForReview(
            `${
              api.DOCUMENT_API
            }?status=${FOR_REVIEW}&page_number=${1}&results_per_page=${50}&${
              documentFilters.SEARCH
            }=${search_string}&${
              documentFilters.IMPORTED_BY
            }=${creator_user_id}&${
              documentFilters.COLLECTION
            }=${collection_id}&${documentFilters.ACCESS}=${access_level}
            `
          );
        } else if (column === REVIEWING) {
          fetchReviewing(
            `${
              api.DOCUMENT_API
            }?status=${REVIEWING}&page_number=${1}&results_per_page=${50}&${
              documentFilters.SEARCH
            }=${search_string}&${
              documentFilters.IMPORTED_BY
            }=${creator_user_id}&${
              documentFilters.COLLECTION
            }=${collection_id}&${documentFilters.ACCESS}=${access_level}`
          );
        } else if (column === COMPLETED) {
          fetchCompleted(
            `${
              api.DOCUMENT_API
            }?status=${COMPLETED}&page_number=${1}&results_per_page=${50}&${
              documentFilters.SEARCH
            }=${search_string}&${
              documentFilters.IMPORTED_BY
            }=${creator_user_id}&${
              documentFilters.COLLECTION
            }=${collection_id}&${documentFilters.ACCESS}=${access_level}`
          );
        }
      }
    }, 20000);

    return () => clearInterval(timer);
  }, [search_string, creator_user_id, collection_id, access_level]);
};

/**
 * useColumnReload - reloads documents on columns after changes
 *
 */
export const useColumnsReload = (result, columns = []) => {
  const [{ result: queueRes }, fetchQueue] = useAxios({});
  const [{ result: forReviewRes }, fetchForReview] = useAxios({});
  const [{ result: reviewingRes }, fetchReviewing] = useAxios({});
  const [{ result: completedRes }, fetchCompleted] = useAxios({});

  useDocumentsUpdate(queueRes, QUEUE);
  useDocumentsUpdate(forReviewRes, FOR_REVIEW);
  useDocumentsUpdate(reviewingRes, REVIEWING);
  useDocumentsUpdate(completedRes, COMPLETED);
  const { documentData } = useDocumentContext();

  const { search_string, creator_user_id, collection_id, access_level } =
    documentData;
  useEffect(() => {
    if (!result || result.loading || !result.success) return;

    for (let column of columns) {
      if (column === QUEUE) {
        fetchQueue(
          `${
            api.DOCUMENT_API
          }?status=${QUEUE}&page_number=${1}&results_per_page=${50}`
        );
      } else if (column === FOR_REVIEW) {
        fetchForReview(
          `${
            api.DOCUMENT_API
          }?status=${FOR_REVIEW}&page_number=${1}&results_per_page=${50}&${
            documentFilters.SEARCH
          }=${search_string}&${
            documentFilters.IMPORTED_BY
          }=${creator_user_id}&${documentFilters.ACCESS}=${access_level}
          `
        );
      } else if (column === REVIEWING) {
        fetchReviewing(
          `${
            api.DOCUMENT_API
          }?status=${REVIEWING}&page_number=${1}&results_per_page=${50}&${
            documentFilters.SEARCH
          }=${search_string}&${
            documentFilters.IMPORTED_BY
          }=${creator_user_id}&${documentFilters.ACCESS}=${access_level}`
        );
      } else if (column === COMPLETED) {
        fetchCompleted(
          `${
            api.DOCUMENT_API
          }?status=${COMPLETED}&page_number=${1}&results_per_page=${50}&${
            documentFilters.SEARCH
          }=${search_string}&${
            documentFilters.IMPORTED_BY
          }=${creator_user_id}&${documentFilters.COLLECTION}=${collection_id}&${
            documentFilters.ACCESS
          }=${access_level}`
        );
      }
    }
  }, [result, search_string, creator_user_id, collection_id, access_level]);
};
