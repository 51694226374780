import { Layout } from "antd";

const Footer = () => {
  return (
    <Layout.Footer className="app-footer">
      {" "}
      <p className="app-footer-text">
        {" "}
        ©{" "}
        <a
          href="https://elemendar.com/"
          target="_blank"
          className="app-footer-link"
          rel="noreferrer"
        >
          Elemendar
        </a>{" "}
        2017-2021. All rights reserved. | Elemendar Ltd: Company Number 10736268
        registered in England and Wales |{" "}
        <a
          href="https://elemendar.com/privacy-policy/"
          target="_blank"
          className="app-footer-link"
          rel="noreferrer"
        >
          {" "}
          Privacy Policy{" "}
        </a>{" "}
      </p>{" "}
      <div id="bottom-relationship-anchor"></div>
    </Layout.Footer>
  );
};

export default Footer;
