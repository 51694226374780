const errorMessages = {
  went_wrong: "Something went wrong!",
  entity_processing: "This entity is being processed",
  oops: "Oops! There was an error.",
  oops_reload_retry: "Oops! Something went wrong. Reload and try again.",
  insert_url: "Insert a url",
  insert_url_or_remove: "Insert a url or remove extra field",
  is_required: "is required",
  select_files: "Select one or more files to be uploaded",
  can_select_unhandle:
    "If canSelect prop is set to true, handleSelection or setSelected prop must be provided to handle selection.",
};

export default errorMessages;
