import React from "react";
import { Checkbox } from "antd";
import { useEffect } from "react";
import { knownEntityTypes } from "../../../constants/entityTypes";

const CheckboxGroup = Checkbox.Group;

const plainOptions = Object.keys(knownEntityTypes);
const defaultCheckedList = Object.keys(knownEntityTypes);

export const DocumentEntityTypes = ({ checkedEntityList }) => {
  const [checkedList, setCheckedList] = React.useState(defaultCheckedList);
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(true);

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
    checkedEntityList(list);
  };
  useEffect(() => {
    checkedEntityList(checkedList);
  }, []);

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    checkedEntityList(e.target.checked ? defaultCheckedList : []);
  };

  return (
    <>
      <div className="document-entity-types-header">
        <h4 className="header-text">Entity types to extract</h4>
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          Check all
        </Checkbox>
      </div>
      <CheckboxGroup
        options={plainOptions}
        value={checkedList}
        onChange={onChange}
        className="document-entity-types-body"
      />
    </>
  );
};
