import { Menu, Radio, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { entityRole } from "../../../../constants";
import { setNewRelationshipRole } from "../../../../store/actions";
import { startRelationshipProcess } from "../../../../utils/helpers/analysis/relationship";
import infoMessages from "../../../../utils/messages/infoMessages";

const rOptionsStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const { SOURCE, TARGET } = entityRole;

const AddRelationshipMenu = ({ firstEntity }) => {
  const { newRelation } = useSelector(({ relationship }) => relationship);
  const dispatch = useDispatch();
  const firstEntityRole = newRelation.role;

  return (
    <Menu>
      <Menu.ItemGroup title="Options">
        <Menu.Item className="menu-item-sm" disabled>
          <div style={rOptionsStyle}>
            <Radio.Group
              value={firstEntityRole}
              onChange={({ target }) =>
                dispatch(setNewRelationshipRole(target.value))
              }
            >
              <Radio value={SOURCE}>{infoMessages.source}</Radio>
              <Radio value={TARGET}>{infoMessages.target}</Radio>
            </Radio.Group>
          </div>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.Item disabled>
        <Button
          onClick={() =>
            startRelationshipProcess(firstEntity, firstEntityRole, dispatch)
          }
          type="primary"
          block
        >
          {infoMessages.new_relationship}
        </Button>
      </Menu.Item>
    </Menu>
  );
};

export default AddRelationshipMenu;
