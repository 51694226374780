export const selectionError = {
  MULTI_SENTENCE_SELECTION:
    "Selection is not allowed between two or more sentences.",
  MULTI_LIST_SELECTION:
    "Selection is not allowed between two or more list items.",
  INTERSET_SELECTION: "Highlighting over interset node(s) is not allowed",
  INTERSET_NOT_SUPPORTED:
    "Interset selection accross existing entity is not supported.",
  NESTED_NOT_SUPPORTED:
    "Nested selection within non-sentence entity is not supported.",
  OVERLAY_NOT_SUPPORTED:
    "Overlay selection on non-sentence entity is not supported.",
  UNSUPPORTED_SELECTION: "Unsupported selection",
  INVALID_SELECTION: "Selection is not valid",
};
