const events = {
  STIX_GRAPH_LOADED: "STIX Graph Loaded",
  STIX_GRAPH_LAYOUT: "STIX Graph Layout Changed",
  TOGGLE_ON_RELATIONSHIPS: "Toggle On Relationships",
  TOGGLE_OFF_RELATIONSHIPS: "Toggle Off Relationships",
  DELETE_ALL_RELATIONSHIPS: "Delete All Relationships",
  ACCEPT_ALL_ENTITIES: "Accept All Entities",
  REJECT_ALL_ENTITIES: "Reject All Entities",
  MARK_DOCUMENT_COMPLETED: "Mark Document As Completed",
};

export default events;
