import { Card } from "antd";
import ApexCharts from "apexcharts";
import "../../../assets/styles/performance-dashboard.scss";
import { useEffect, useRef } from "react";
import { barChartOptions } from "./chartOptions";

const OverviewCard = ({
  title,
  data,
  dateRanges,
  extraInfo = null,
  optionHandler,
}) => {
  const chartRef = useRef();

  useEffect(() => {
    let options = barChartOptions;
    if (data && optionHandler) options = optionHandler(data, dateRanges);
    const chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => chart.destroy();
  }, [data]);

  return (
    <div>
      <Card title={title} extra={extraInfo}>
        <div ref={chartRef}></div>
      </Card>
    </div>
  );
};

export default OverviewCard;
