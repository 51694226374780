import { Empty } from "antd";

const EmptyContent = ({
  description = "Nothing Here",
  image = Empty.PRESENTED_IMAGE_SIMPLE,
}) => {
  return <Empty image={image} description={description} />;
};

export default EmptyContent;
