import { Button } from "antd";

const STIXDropDownHearder = ({ entityCount }) => {
  return (
    <div className="justify-between">
      <span>STIX objects</span>
      <Button shape="round" type="primary" size="small">
        {entityCount} Selected
      </Button>
    </div>
  );
};

export default STIXDropDownHearder;
