import { Tag } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import "../../../assets/styles/widgets.scss";

const TotalStatWidget = ({ title, count, compare, icon, altText }) => {
  return (
    <div className="total-stat-widget b-white">
      <div className="title">{title}</div>
      <div className="icon">{icon}</div>
      <div className="count">
        <span>{count}</span>
      </div>
      <div className="compare">
        {!!compare && (
          <Tag color="success" icon={<ArrowUpOutlined />} className="custom">
            <span className="compare-text">{compare}</span>
          </Tag>
        )}

        {!!altText && !compare && altText}
      </div>
    </div>
  );
};

export default TotalStatWidget;
