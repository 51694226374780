const infoMessages = {
  count_selected: ":count: selected",
  remove_selected: "Remove selected",
  hide_selected: "Hide selected",
  show_selected: "Show selected",
  new_relationship: "New Relationship",
  complete_new_relationship: "Complete New Relationship",
  remove_all_relationships: "Remove all relationships",
  remove_selected_relationships: "Remove selected relationships",
  select_relationship_type: "Select a relationship type",
  _relationship: "relationship(s)",
  relationship: "Relationship",
  source: "Source",
  target: "Target",
  remove: "Remove",
  add: "Add",
  hide: "Hide",
  show: "Show",
  show_all: "Show all",
  hide_all: "Hide all",
  delete_all: "Delete all",
  accept_group: "Accept group",
  reject_group: "Reject group",
  mark_complete: "Mark as complete",
  _completed: "completed",
  add_relationship: "Add a relationship",
  existing_relationship: "Existing relationship",
  existing_relationships: "Existing relationships",
  entity_relationship: "Entity relationship",
  change_entity: "Change entity type",
  accept_or_reject: "Accept or Reject",
  annotate_text: "Annotate text",
  annotate_sentence: "Annotate sentence",
  generate_stix_btn: "Generate STIX Bundle",
  download_stix_btn: "Download STIX",
  generate_stix_info:
    "Generate a new STIX bundle for approved entities, excluding relationships.",
  download_stix_info: "Download existing STIX",
  delete_all_relationships_warning:
    "You are about to delete all relationships in this document.",
  is_this_what_you_want: "Is this what you want?",
  title: "Title",
  content: "Content",
  supported_documents: "Supports PDF and HTML documents",
  drag_and_drop_files: "Drag and drop files here",
  click_to_browse: "Click to browse",
  select_undertermined_subtype:
    "Select 'undetermined' if you are not sure of the entity sub type.",
  metadata: "Metadata",
  org_doc_access_tip: "Anyone in your organisation can view and edit",
  private_doc_access_tip: "Only you can view and edit",
  instances: "Instances",
  relationships: "Relationships",
  not_supported: "Not supported",
  abort: "Abort",
  entity_relationship_in_progress: "A new entity relationship is in progress",
  continue: "Continue",
  confirm: "Confirm",
  cancel: "Cancel",
  accept_all_entities_in_group: "Accept all entities in this group?",
  reject_all_entities_in_group: "Reject all entities in this group?",
  request_successful: "Request successful",
  rules_manager_info:
    "READ automatically extracts certain indicators it detects insided reports. You can also define custom extractions on this page if automatic extractions do not meet your requirements.",
  rule_feedback_info:
    "Sorry we got that one wrong. By leaving a short description of why you changed this entity, we can use this information to train our machine learning models.",
};

export default infoMessages;
