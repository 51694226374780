import { tracking } from "../../constants/envVars";

const env = window._env_;
const allToolsEnabled = `${env[tracking.ALL_TOOLS]}`.toUpperCase() === "TRUE";

/**
 * checkToolEnabled - Returns true if a tracker env var is set to "TRUE"
 *
 * @function
 * @param {string} envVar - The environment variable
 * @returns {boolean}
 *
 */
export const checkToolEnabled = (envVar) => {
  const value = (env[envVar] || "").toUpperCase();

  let enabled = null;
  if (value === "TRUE") enabled = true;
  if (value === "FALSE") enabled = false;

  // Fallback to the value set on `ENABLE_TRACKING_TOOLS` env var
  if (enabled === null) enabled = allToolsEnabled;

  return enabled;
};
