import mixpanel from "mixpanel-browser";
import { tracking } from "../../constants/envVars";
import { checkToolEnabled } from "../helpers/tracker";

/* Mixpanel initialization */
const isDev = process.env.NODE_ENV === "development";
const isEnabled = checkToolEnabled(tracking.MIXPANEL);
const mixpanelToken = window._env_[tracking.MIXPANEL_TOKEN];
const options = { debug: false };
if (isDev) options.debug = true;
if (isEnabled) mixpanel.init(mixpanelToken, options);

/**
 * Mixpanel class
 *
 * @class
 *
 */
class Mixpanel {
  static identify(id) {
    try {
      mixpanel.identify(id);
    } catch (error) {
      // logToConsole(error)
    }
  }

  static alias(id) {
    try {
      mixpanel.alias(id);
    } catch (error) {
      // logToConsole(error)
    }
  }

  static track(name, props) {
    try {
      mixpanel.track(name, props);
    } catch (error) {
      // logToConsole(error)
    }
  }

  static setUser(userDetails) {
    try {
      const { firstName, lastName, email, ...details } = userDetails;
      const profile = {
        $first_name: firstName,
        $last_name: lastName,
        $email: email,
        $referring_domain: window.location.host,
        ...details,
      };
      mixpanel.people.set(profile);
    } catch (error) {
      // logToConsole(error)
    }
  }
}

export default Mixpanel;

// Temporary used for debugging Mixpanel locally
function logToConsole(error) {
  if (isDev) {
    console.log(error);
  }
}
