import { useState } from "react";
import EntityList from "./EntityList";
import SelectInput from "../../common/forms/SelectInput";
import { entityRole as er } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { setNewRelationshipRole } from "../../../store/actions";
import { Button } from "antd";
import { startRelationshipProcess } from "../../../utils/helpers/analysis/relationship";

const { SOURCE, TARGET } = er;

/**
 * FirstEntityForRelationship
 *
 * @returns React JSX
 */
const FirstEntityForRelationship = ({ entityIds, handleCancel }) => {
  const [selectedEntityId, setSelectedEntityId] = useState(null);

  const dispatch = useDispatch();
  const { relationship, analysis } = useSelector((state) => state);
  const firstEntityRole = relationship.newRelation.role;
  const firstEntity = analysis.entitiesByIds[selectedEntityId];

  return (
    <>
      <div className="section-card mb-30">
        <div className="section-card-header">
          <em>Is this entity the source or target? </em>
        </div>
        <SelectInput
          value={firstEntityRole}
          options={[
            { value: SOURCE, label: "Source" },
            { value: TARGET, label: "Target" },
          ]}
          onChange={(role) => dispatch(setNewRelationshipRole(role))}
        />
      </div>
      <div className="section-card mb-30">
        <div className="section-card-header">
          <em>Choose the first entity for a new relationship: </em>
        </div>
        <div className="section-card-body">
          <EntityList
            entityIds={entityIds}
            canSelect={true}
            canSelectMultiple={false}
            selected={selectedEntityId}
            setSelected={setSelectedEntityId}
          />
        </div>
      </div>
      <div className="justify-end">
        {handleCancel && <Button onClick={handleCancel}>Cancel</Button>}
        <Button
          type="primary"
          className="ml-10"
          onClick={() =>
            startRelationshipProcess(firstEntity, firstEntityRole, dispatch)
          }
          disabled={!selectedEntityId || !firstEntityRole}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default FirstEntityForRelationship;
