import {
  generateApiActionTypes,
  initializeState,
} from "../../utils/helpers/reduxStore";
import actions from "../actions/collections";

const actionTypes = {
  [actions.GET_ALL_COLLECTIONS_REQUEST]: "getAllCollections",
  [actions.GET_SINGLE_COLLECTION_REQUEST]: "getSingleCollection",
  [actions.CREATE_COLLECTION_REQUEST]: "createCollection",
  [actions.UPDATE_COLLECTION_REQUEST]: "updateCollection",
  [actions.DELETE_COLLECTION_REQUEST]: "deleteCollection",
  [actions.GET_COLLECTION_DOCUMENTS_REQUEST]: "getCollectionDocuments",
  [actions.GET_DOCUMENT_COLLECTIONS_REQUEST]: "getDocumentCollections",
  [actions.ADD_DOCUMENT_TO_COLLECTION_REQUEST]: "addCollectionDocument",
  [actions.REMOVE_DOCUMENT_FROM_COLLECTION_REQUEST]: "removeCollectionDocument",
  [actions.GET_COLLECTION_BUNDLE_REQUEST]: "getCollectionBundle",
};

const apiActionTypes = generateApiActionTypes(actionTypes);

const initialState = {
  ...initializeState(actionTypes),
  collectionList: [],
};

const collections = (state = initialState, action = {}) => {
  const typeState = apiActionTypes[action.type];

  if (typeState)
    return {
      ...state,
      [typeState]: action.payload,
    };

  switch (action.type) {
    case actions.SET_COLLECTIONS:
      return {
        ...state,
        collectionList: action.payload,
      };

    case actions.RESET_COLLECTION_STATE:
      return initialState;

    default:
      return state;
  }
};

export default collections;
