import {
  START_LOADING,
  STOP_LOADING,
  ADD_LOADER,
  REMOVE_LOADER,
  RESET_LOADER_STATE,
} from "../actions";

const initialState = {
  loading: false,
  count: 0,
};

const loaderReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        loading: true,
        count: 1,
      };

    case STOP_LOADING:
      return {
        ...state,
        loading: false,
        count: 0,
      };

    case ADD_LOADER:
      return {
        ...state,
        loading: true,
        count: state.count + 1,
      };

    case REMOVE_LOADER:
      const c = state.count - 1;
      return {
        ...state,
        loading: c > 0 ? true : false,
        count: c > 0 ? c : 0,
      };

    case RESET_LOADER_STATE:
      return initialState;

    default:
      return state;
  }
};

export default loaderReducer;
