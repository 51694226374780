import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import qs from "qs";
import _ from "lodash";
import { useReload } from "../../../hooks/useReload";
import { setRules, getRulesRequest } from "../../../store/actions/manualRules";
import { manualRuleColumns } from "./columns";
import { removeSlideInView, removeModalView } from "../../../store/actions";
import messageBox from "../../../utils/services/MessageBox";
import { useMessageBoxError } from "../../../hooks/useMessageBox";
import { resetRulesState } from "../../../store/actions/manualRules";
import { RULE_TYPES } from "../../../constants";
import { entityProperties } from "../../../data/entities";
import SelectInput from "../../common/forms/SelectInput";
import {
  getFilterProps,
  handlePagination,
  handleFilter,
  handleSearch,
} from "./helpers";
import { getRulesCreatorsRequest } from "../../../store/actions/manualRules";
import { handleSorting } from "./helpers";

const entityTypesProps = getFilterProps("Entity type", entityProperties);
const ruleTypesProps = getFilterProps("Rule type", RULE_TYPES);
const membersProps = getFilterProps("Creators");

const RulesTable = () => {
  const [queryParams, setQueryParams] = useState({
    page_number: 1,
    results_per_page: 20,
  });
  const [creators, setCreators] = useState([]);
  const dispatch = useDispatch();

  const {
    data: { rules, pagination },
    getRules: gr,
    updateRule: u,
    createRule: c,
    deleteRule: d,
    disableRule: dr,
    enableRule: er,
    submitRuleFeedback: sf,
    getRulesCreators: grc,
  } = useSelector(({ manualRules }) => manualRules);

  const loadRulesData = () => {
    dispatch(getRulesRequest(qs.stringify(queryParams)));
  };

  const successActions = (result) => {
    messageBox.show(result.message);
    loadRulesData();
    dispatch(removeSlideInView());
    dispatch(removeModalView());
  };

  useReload(gr.result, () => dispatch(setRules(gr.result.data)));
  useReload(grc.result, () => setCreators(grc.result.data.creators));
  useReload(u.result, () => successActions(u.result));
  useReload(c.result, () => successActions(c.result));
  useReload(c.result, () => dispatch(getRulesCreatorsRequest()));
  useReload(d.result, () => successActions(d.result));
  useReload(dr.result, () => successActions(dr.result));
  useReload(er.result, () => successActions(er.result));
  useReload(sf.result, () => successActions(sf.result));

  useMessageBoxError(u.error);
  useMessageBoxError(c.error);
  useMessageBoxError(d.error);
  useMessageBoxError(dr.error);
  useMessageBoxError(er.error);
  useMessageBoxError(sf.error);

  useEffect(() => {
    loadRulesData();
    dispatch(getRulesCreatorsRequest());
    return () => dispatch(resetRulesState());
  }, [queryParams]);

  return (
    <div>
      <div className="justify-between">
        <div>
          <SelectInput
            {...entityTypesProps}
            onChange={handleFilter("entity_type", setQueryParams)}
          />
          <SelectInput
            {...ruleTypesProps}
            onChange={handleFilter("rule", setQueryParams)}
          />
          <SelectInput
            {...membersProps}
            onChange={handleFilter("creator_id", setQueryParams)}
            options={creators.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
          />
        </div>
        <div>
          <Input
            placeholder={`Search ${pagination.total} results`}
            prefix={<SearchOutlined style={{ color: "#aaa" }} />}
            size="small"
            onChange={_.debounce(handleSearch(setQueryParams), 1000)}
          />
        </div>
      </div>
      <Table
        rowKey="id"
        columns={manualRuleColumns(setQueryParams)}
        dataSource={rules}
        onChange={handleSorting(setQueryParams)}
        showSorterTooltip={false}
        pagination={{
          size: "small",
          current: pagination.page,
          pageSize: pagination.per_page,
          total: pagination.total,
          position: ["bottomRight", "topRight"],
          onChange: handlePagination(setQueryParams),
        }}
      />
    </div>
  );
};

export default RulesTable;
