import { Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import {
  ATTACK_PATTERN,
  entityClass,
  entityStatus,
  storageIds,
} from "../../../constants";
import api from "../../../constants/api";
import { batch } from "react-redux";
import { addEntityRequest } from "../../../store/actions/apiRequests";
import {
  addProcessingEntities,
  removeActiveSelection,
  setVirtualEntities,
} from "../../../store/actions";
import { storage } from "../../services/StaticStorage";

const { TABLE, TEXT } = entityClass;
const { WAITING, ACCEPTED } = entityStatus;

export const mapEntitiesByIds = (blocks) => {
  const entitiesByIds = {};
  blocks.forEach((block, idx) => {
    const { entities = [] } = block;

    for (let entity of entities) {
      let fill_text = "";
      if (entity.type === ATTACK_PATTERN) {
        fill_text = block.text;
      } else {
        fill_text = entity.text;
      }
      entitiesByIds[entity.id] = { blockIndex: idx, fill_text, ...entity };
    }
  });

  return entitiesByIds;
};

export const rejectEntityHandler = (
  documentId,
  entityId,
  relationsIndices,
  rejectEntityRequest
) => {
  const count = relationsIndices.length;
  const appendMsg = !!count
    ? ` and ${count} associated relationship${count > 1 ? "s" : ""}`
    : "";

  const confirmMsgOptions = {
    icon: <DeleteOutlined />,
    title: `You are about to reject the selected entity${appendMsg}`,
    content: (
      <em>
        By rejecting you confirm that this entity and all associated
        relationships is invalid and will be removed. Do you want to continue?
      </em>
    ),
    okText: "Yes",
    cancelText: "No",
    onOk: () =>
      rejectEntityRequest(`${api.DOCUMENTS}/${documentId}/entity/${entityId}`),
    onCancel: () => {},
  };

  Modal.confirm(confirmMsgOptions);
};

const _mapEntities = (entityList) => {
  const map = {};
  entityList.forEach((entity) => {
    entity.count = 0;
    map[entity.value] = entity;
  });
  return map;
};

export const getEntityCategoriesMeta = (entityList, blocks) => {
  const entities = _mapEntities(entityList);
  blocks.forEach((block) => {
    (block.entities || []).forEach((entity) => {
      if (entities[entity.type]) {
        entities[entity.type].count++;
      }
    });
  });

  return entities;
};

export const mergeEntityTypeIds = (entityGroup) => {
  let ids = [];
  if (entityGroup) {
    for (const entityType of Object.values(entityGroup)) {
      ids = ids.concat(entityType.ids);
    }
  }
  return ids;
};

export const handleEntitiesSelection =
  (entityGroups, setSelections) =>
  (path = []) =>
  (e) => {
    const level = path.length;

    if (level === 1) {
      const [status] = path;

      setSelections((state) => {
        let ids = [];
        if (e.target.checked) ids = mergeEntityTypeIds(entityGroups[status]);
        return {
          ...state,
          [status]: ids,
        };
      });
    } else if (level === 2) {
      const [status, entityType] = path;
      const ids = entityGroups[status][entityType].ids;

      setSelections((state) => {
        const selSet = new Set(state[status]);
        if (e.target.checked) ids.forEach((id) => selSet.add(id));
        else ids.forEach((id) => selSet.delete(id));
        return {
          ...state,
          [status]: Array.from(selSet),
        };
      });
    } else if (level === 3) {
      const [status, entityType, entityText] = path;
      const ids = entityGroups[status][entityType].text[entityText];

      setSelections((state) => {
        const selSet = new Set(state[status]);
        if (e.target.checked) ids.forEach((id) => selSet.add(id));
        else ids.forEach((id) => selSet.delete(id));
        return {
          ...state,
          [status]: Array.from(selSet),
        };
      });
    } else if (level === 4) {
      const status = path[0];
      const entityId = path[3];
      setSelections((state) => {
        const selSet = new Set(state[status]);
        selSet.has(entityId) ? selSet.delete(entityId) : selSet.add(entityId);
        return {
          ...state,
          [status]: Array.from(selSet),
        };
      });
    }
    return;
  };

export const virtualEntityFromNewPayload = (payload, vePrefix, _class) => {
  const { text, type, sub_properties, sentence_idx } = payload;

  let entity = { class: _class, type, text, sub_properties, vePrefix };

  if (_class === TABLE) {
    const { table_idx, start_position, end_position } = payload;
    let id = `${table_idx}.${start_position}.${end_position}`;
    entity = {
      ...entity,
      id: `${vePrefix}-${id}`,
      start_point: start_position,
      end_point: end_position,
      status: entityStatus.WAITING,
      column_idx: payload.column,
      row_idx: payload.row,
      table_idx,
    };
  } else if (_class === TEXT) {
    const {
      start_position_in_sentence: start_point,
      end_position_in_sentence: end_point,
    } = payload;
    entity = {
      ...entity,
      id: `${vePrefix}-${sentence_idx}.${start_point}.${end_point}`,
      start_point,
      end_point,
      sentence_idx,
    };
  } else {
    entity["id"] = `${vePrefix}-${sentence_idx}`;
    entity["sentence_idx"] = sentence_idx;
  }

  return entity;
};

export const sendNewEntityRequest = (
  payload,
  documentId,
  virtualEntity,
  virtualEntities,
  vePrefix,
  dispatch
) => {
  const reqId = virtualEntity.id;
  const entityObjects = { ...(virtualEntities[vePrefix] || {}) };
  entityObjects[reqId] = virtualEntity;

  storage.save(storageIds.ADD_ENTITY, payload);

  batch(() => {
    dispatch(addEntityRequest(documentId, payload, reqId));
    dispatch(addProcessingEntities({ [reqId]: true }));
    dispatch(
      setVirtualEntities({ ...virtualEntities, [vePrefix]: entityObjects })
    );
    dispatch(removeActiveSelection());
  });
};

export const makeVEPrefix = (scope, blockIndex) => `ve.${scope}.${blockIndex}`;

export const getGroupEntityIds = (entityGroups, type, text) => {
  let unConfirmedEntityIds = [];
  let acceptedEntityIds = [];

  if (type === ATTACK_PATTERN) {
    const tid = text.split(":")[0];
    if (entityGroups[WAITING][type]) {
      unConfirmedEntityIds = entityGroups[WAITING][type].tid[tid] || [];
    }
    if (entityGroups[ACCEPTED][type]) {
      acceptedEntityIds = entityGroups[ACCEPTED][type].tid[tid] || [];
    }
  } else {
    if (entityGroups[WAITING][type]) {
      unConfirmedEntityIds = entityGroups[WAITING][type].text[text] || [];
    }
    if (entityGroups[ACCEPTED][type]) {
      acceptedEntityIds = entityGroups[ACCEPTED][type].text[text] || [];
    }
  }

  return [...unConfirmedEntityIds, ...acceptedEntityIds];
};
