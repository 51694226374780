import { staticDateRanges } from "../../../constants";
import routes from "../../../constants/routes";

export const optionsFromMapperObj = (mapperObj) => {
  return Object.entries(mapperObj).map(([value, label]) => ({ value, label }));
};

export const dateRangeValue = (dateRange, customDateRange) => {
  let dr = dateRange;
  if (dr === "custom" && !customDateRange) dr = "all_time";
  if (dr === "custom" && customDateRange) {
    dr = `${customDateRange[0].format(
      "DD/MM/YYYY"
    )},${customDateRange[1].format("DD/MM/YYYY")}`;
  }
  return dr;
};

export const dateRangeText = (dateRange, customDateRange) => {
  let dr = dateRange;
  if (dr === "custom" && !customDateRange) dr = "all_time";
  if (dr === "custom" && customDateRange) {
    dr = `${customDateRange[0].format(
      "DD/MM/YYYY"
    )} - ${customDateRange[1].format("DD/MM/YYYY")}`;
  }

  return staticDateRanges[dr] || dr;
};

export const handleOpenSource = (doc) => () => {
  let sourceUrl = doc.source;
  if (!sourceUrl || `${doc.source_type}`.toLowerCase() !== "url") {
    sourceUrl = routes.DOCUMENT_SOURCE.replace(":documentId", doc.document_id);
  }

  window.open(sourceUrl, "_blank", "noopener,noreferrer");
};

export const handleOpenAnalysis = (doc) => () => {
  const analysisUrl = routes.DOCUMENT_ANALYSIS.replace(
    ":documentId",
    doc.document_id
  );

  window.open(analysisUrl, "_blank", "noopener,noreferrer");
};
