import { FileSyncOutlined } from "@ant-design/icons";
import QueuedDocument from "./QueuedDocument";
import EmptyContent from "../../common/widgets/EmptyContent";
import { useDocumentContext } from "../../../contexts/DocumentContext";
import { useEffect, useState } from "react";
import api from "../../../constants/api";
import { documentStatus, documentColumns } from "../../../constants";
import useAxios from "../../../hooks/useAxios";
import { useDocumentsUpdate } from "../../../hooks/useDocumentsUpdate";
import SkeletonLoader from "../../common/loaders/SkeletonLoader";

const { QUEUE } = documentStatus;

const QueuePane = () => {
  const [{ loading, result }, fetchData] = useAxios({});

  useDocumentsUpdate(result, QUEUE);
  const { documentData } = useDocumentContext();
  const documents = documentData[QUEUE];
  const { loaderRepeat } = documentColumns[QUEUE];

  const [query] = useState({ page: 1, size: 50 });

  // const documents = result.data.documents;
  const isEmpty = !documents || !documents.length;
  const { page, size } = query;

  useEffect(() => {
    fetchData(
      `${api.DOCUMENT_API}?status=${QUEUE}&page_number=${page}&results_per_page=${size}`
    );
  }, []);

  return (
    <div className="documents-queue">
      <h2>
        <FileSyncOutlined /> <b className="pane-title"> Queue </b>
      </h2>
      <div className="documents-queue-body">
        <SkeletonLoader.QueuedDocument
          loading={loading}
          repeat={loaderRepeat}
        />
        {!loading && isEmpty && <EmptyContent description="Empty Queue" />}
        {!loading &&
          !isEmpty &&
          documents.map((doc, idx) => (
            <QueuedDocument key={idx} document={doc} index={idx} />
          ))}
      </div>
    </div>
  );
};

export default QueuePane;
