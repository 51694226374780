import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import routes, { errorCodeToPage } from "../constants/routes";

/**
 * useErrorPage - Show error page if specified error is found
 *
 */
export const useErrorPage = (error, statusCodes = []) => {
  const history = useHistory();

  useEffect(() => {
    if (!error) return;
    const { message, status_code } = error;
    if (statusCodes.length && !statusCodes.includes(status_code)) return;
    const page = errorCodeToPage[status_code] || routes.NOT_FOUND;

    history.push(page, { subTitle: message });
  }, [error]);
};
