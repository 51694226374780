import axios from "axios";
import api from "../../constants/api";

const actions = {
  SET_COLLECTIONS: "SET_COLLECTIONS",
  GET_ALL_COLLECTIONS_REQUEST: "GET_ALL_COLLECTIONS_REQUEST",
  GET_SINGLE_COLLECTION_REQUEST: "GET_SINGLE_COLLECTION_REQUEST",
  CREATE_COLLECTION_REQUEST: "CREATE_COLLECTION_REQUEST",
  UPDATE_COLLECTION_REQUEST: "UPDATE_COLLECTION_REQUEST",
  DELETE_COLLECTION_REQUEST: "DELETE_COLLECTION_REQUEST",
  RESET_COLLECTION_STATE: "RESET_COLLECTION_STATE",
  GET_COLLECTION_DOCUMENTS_REQUEST: "GET_COLLECTION_DOCUMENTS_REQUEST",
  GET_DOCUMENT_COLLECTIONS_REQUEST: "GET_DOCUMENT_COLLECTIONS_REQUEST",
  ADD_DOCUMENT_TO_COLLECTION_REQUEST: "ADD_DOCUMENT_TO_COLLECTION_REQUEST",
  REMOVE_DOCUMENT_FROM_COLLECTION_REQUEST:
    "REMOVE_DOCUMENT_FROM_COLLECTION_REQUEST",
  GET_COLLECTION_BUNDLE_REQUEST: "GET_COLLECTION_BUNDLE_REQUEST",
};

export default actions;

/* Action creators */

export const setCollections = (data = {}) => ({
  type: actions.SET_COLLECTIONS,
  payload: data.collections || [],
});

export const resetCollectionsState = () => ({
  type: actions.RESET_COLLECTION_STATE,
});

export const getAllCollectionsRequest = () => ({
  type: actions.GET_ALL_COLLECTIONS_REQUEST,
  payload: axios.get(api.COLLECTIONS),
});

export const getSingleCollectionRequest = (collectionId) => ({
  type: actions.GET_SINGLE_COLLECTION_REQUEST,
  payload: axios.get(`${api.COLLECTIONS}/${collectionId}`),
});

export const createCollectionRequest = (data) => ({
  type: actions.CREATE_COLLECTION_REQUEST,
  payload: axios.post(api.COLLECTIONS, data),
});

export const updateCollectionRequest = (collectionId, data) => ({
  type: actions.UPDATE_COLLECTION_REQUEST,
  payload: axios.put(`${api.COLLECTIONS}/${collectionId}`, data),
});

export const deleteCollectionRequest = (collectionId) => ({
  type: actions.DELETE_COLLECTION_REQUEST,
  payload: axios.delete(`${api.COLLECTIONS}/${collectionId}`),
});

export const getCollectionDocumentsRequest = (collectionId) => ({
  type: actions.GET_COLLECTION_DOCUMENTS_REQUEST,
  payload: axios.get(api.COLLECTION_DOCUMENTS.replace(":collId", collectionId)),
});

export const getDocumentCollectionsRequest = (documentId) => ({
  type: actions.GET_DOCUMENT_COLLECTIONS_REQUEST,
  payload: axios.get(
    api.DOCUMENT_COLLECTIONS.replace(":documentId", documentId)
  ),
});

export const addDocumentToCollectionRequest = (documentId, collectionId) => ({
  type: actions.ADD_DOCUMENT_TO_COLLECTION_REQUEST,
  payload: axios.post(
    `${api.DOCUMENT_COLLECTIONS.replace(
      ":documentId",
      documentId
    )}${collectionId}`
  ),
});

export const removeDocumentFromCollectionRequest = (
  documentId,
  collectionId
) => ({
  type: actions.REMOVE_DOCUMENT_FROM_COLLECTION_REQUEST,
  payload: axios.delete(
    `${api.DOCUMENT_COLLECTIONS.replace(
      ":documentId",
      documentId
    )}${collectionId}`
  ),
});

export const getCollectionBundleRequest = (collectionId) => ({
  type: actions.GET_COLLECTION_BUNDLE_REQUEST,
  payload: axios.get(api.COLLECTION_BUNDLE.replace(":collId", collectionId)),
});
